import { assertNever } from 'server/utils/typings/assertNever';
import { SettingKey } from './SettingVariables';

export type ISettings = {
  [key in SettingKey]?: string | null;
};

export interface ISettingVariable {
  label: string;
  key: SettingKey;
  module: SettingModuleKey;
  default?: string | null;
  placeholder?: string | null;
  /** Se `true`, la configurazione sarà mostrata anche FE */
  expose?: boolean;
  help?: string;
  /** Default: string */
  type?: SettingVariableType;
  /** Ulteriori opzioni passabili alla configurazione */
  options?: any;
  order?: number;
}

export enum SettingVariableType {
  String = 'string',
  Password = 'password',
  Boolean = 'boolean',
  Number = 'number'
}

/**
 * Moduli di configurazione.
 * L'ordine di questi enum è importante, perché viene usato per l'ordine dei tab nella pagina di configurazione.
 */
export enum SettingModuleKey {
  General = 'general',
  Folium = 'folium',
  FoliumRoles = 'foliumRoles',
  Ldap = 'ldap',
  DigitalSignature = 'digitalSignature',
  Mail = 'mail'
}

export function getSettingModuleLabel(module: SettingModuleKey) {
  switch (module) {
    case SettingModuleKey.General:
      return 'Generale';
    case SettingModuleKey.Folium:
      return 'Folium';
    case SettingModuleKey.FoliumRoles:
      return 'Allineamento ruoli';
    case SettingModuleKey.DigitalSignature:
      return 'Firma digitale';
    case SettingModuleKey.Ldap:
      return 'Configurazione LDAP';
    case SettingModuleKey.Mail:
      return 'Configurazione eMail';
    default:
      assertNever(module);
  }
}

export function getSettingModuleMessage(module: SettingModuleKey) {
  switch (module) {
    case SettingModuleKey.Ldap:
      return "È necessario il riavvio dei nodi per l'applicazione delle modifiche a questa sezione";
    default:
      return null;
  }
}

// Permette di costruire la variabile e di controllare il tipo, ma anche di recuperare la chiave come literal.
export function settings<T extends string>(
  key: T,
  options: Omit<ISettingVariable, 'key'>
) {
  return { key, ...options };
}
