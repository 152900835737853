import {
  ISettingVariable,
  SettingModuleKey,
  SettingVariableType
} from './Settings';
import { SettingVariables } from './SettingVariables';

export class SettingLogic {
  /**
   * Data una chiave, ritorna i dati della variabile (label, help, type, ...)
   */
  static getVariable(key: string): ISettingVariable {
    const variable = SettingVariables.find(v => v.key === key);

    if (!variable) {
      throw new Error(`Chiave configurazione "${key}" non valida`);
    }

    return variable;
  }

  static getModule(module: SettingModuleKey) {
    const variables = SettingVariables.filter(v => v.module === module);

    return variables;
  }

  /**
   * Data una chiaver, torna il suo valore di default se impostato, null altrimenti
   */
  static getDefaultValue(key: string): string | null {
    const variable = this.getVariable(key);

    return variable.default ?? null;
  }

  /**
   * Restituisce il valore delle impostazioni, gestendo il caso booleano
   */
  static getSettingValue(
    key: string,
    value: string | null | undefined
  ): string | boolean | null {
    const variable = SettingLogic.getVariable(key);
    // Se non è booleano ritorno il valore
    if (variable.type !== SettingVariableType.Boolean) {
      return value ?? SettingLogic.getDefaultValue(key);
    }

    // Se è booleano gestisco il caso "true" e "false" come stringhe, o la possibilità che sia null
    const booleanValue =
      value === 'true' ? true : value === 'false' ? false : null;

    return booleanValue === null
      ? SettingLogic.getDefaultValue(key)
      : booleanValue;
  }
}
