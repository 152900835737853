import React, { useEffect, useState } from 'react';
import { Steps } from 'antd';
const { Step } = Steps;
import {
  AuditOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  UploadOutlined
} from '@ant-design/icons';
import {
  getStepFromFlowState,
  IFlowStep
} from '../../common/document/step/getStepFromDocumentState';
import { FlowDto } from 'common/dto/generated/FlowDto';

const defaultSteps: IFlowStep[] = [
  {
    index: 0,
    step: {
      title: 'Documento',
      icon: <UploadOutlined />
    }
  },
  {
    index: 1,
    step: {
      title: 'Verifica Segreteria',
      icon: <FileSearchOutlined />
    }
  },
  {
    index: 2,
    step: {
      title: 'Verifica Responsabile',
      icon: <FileSearchOutlined />
    }
  },
  {
    index: 3,
    step: {
      title: 'Approvazione',
      icon: <AuditOutlined />
    }
  },
  {
    index: 4,
    step: {
      title: 'Conclusione',
      icon: <FileProtectOutlined />
    }
  }
];

const getCurrentStepsProps = (current: number, stateStep: IFlowStep) => {
  if (!stateStep) return {};
  return current === stateStep.index ? stateStep.step : {};
};

export interface FlowStepsProps {
  flowData: FlowDto;
}

/**
 * Steps del flusso
 */
export function FlowSteps(props: FlowStepsProps) {
  const [current, setCurrent] = useState<number>(0);

  const stateSteps = getStepFromFlowState(props.flowData.state);
  useEffect(() => {
    setCurrent(stateSteps.index);
  }, [stateSteps.index]);

  return (
    <Steps size="small" current={current}>
      {defaultSteps.map(item => (
        <Step
          key={item.index}
          {...item.step}
          {...getCurrentStepsProps(item.index, stateSteps)}
        />
      ))}
    </Steps>
  );
}
