// «Documenti»
// • Analisi di Processo
// • Mappa di Processo
// • Istruzione Operativa
// • Procedura Operativa
// • PDTA
// • Piano Aziendale
// • Documento Organizzativo
// «Consensi Informati»
// • Consenso Foglio Informativo
// «Regolamenti»
// • Regolamento

/**
 * Tipologie di Documenti gestite nei flussi di qualità
 */
export enum DocumentTypology {
  AnalisiProcesso = 'AnalisiProcesso',
  MappaProcesso = 'MappaProcesso',
  IstruzioneOperativa = 'IstruzioneOperativa',
  ProceduraOperativa = 'ProceduraOperativa',
  PDTA = 'PDTA',
  PianoAziendale = 'PianoAziendale',
  DocumentoOrganizzativo = 'DocumentoOrganizzativo',
  ConsensoFoglioInformativo = 'ConsensoFoglioInformativo',
  Regolamento = 'Regolamento'
}
