import { UserSwitchOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import React from 'react';

export interface IUserChangeRoleButtonProps {}

export function UserChangeRoleButton(props: IUserChangeRoleButtonProps) {
  const {} = props;

  const currentUser = useCurrentUser();

  // Se non c'è l'utente oppure i ruoli disponibili sono 0 o 1
  if (!currentUser || currentUser.avaiableRoles.length < 2) {
    return null;
  }

  return (
    <Menu.Item key="change-role" icon={<UserSwitchOutlined />}>
      <a href="/role">Ruolo</a>
    </Menu.Item>
  );
}
