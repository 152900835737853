import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import React from 'react';
import { DocumentTypologyList } from './DocumentTypologyList';

/**
 * Data la tipologia di documento ritorna l'oggetto IDocumentTypology
 */
export const getDocumentTypologyListItem = (type?: DocumentTypology) => {
  return DocumentTypologyList.find(item => item.documentType === type);
};
