import * as React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import './MainMenu.scss';
import { FileOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { useCurrentMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { usePermissions } from 'client/components/auth/AuthModule';
import { LogoutButton } from 'client/components/logout/LogoutButton';
import { UserPreferencesModalMenu } from 'client/components/schema/user/UserPreferencesModalMenu';
import { UserChangeRoleButton } from '../role/UserChangeRoleButton';

export interface MainMenuProps {}

/**
 * Menu principale dell'applicazione
 */
export function MainMenu(props: MainMenuProps) {
  const currentPathKey = useCurrentMenuKey();
  const permissions = usePermissions();
  return (
    <div className="main-menu-wrapper">
      <Menu
        selectedKeys={currentPathKey}
        // theme="dark"
        mode="horizontal"
        className="main-menu main"
        style={{ lineHeight: '34px' }}
      >
        {permissions.has(['flow.read', 'flow.read:own']) && (
          <Menu.Item key="flows" icon={<FileOutlined />}>
            <Link to="/flows">Tutti i Documenti</Link>
          </Menu.Item>
        )}
        {permissions.has('settings.write') && (
          <Menu.Item key="admin" icon={<SettingOutlined />}>
            <Link to="/admin/config">Amministrazione</Link>
          </Menu.Item>
        )}
      </Menu>
      <Menu
        selectedKeys={[]}
        defaultActiveFirst={false}
        mode="horizontal"
        className="main-menu logout"
        style={{ lineHeight: '34px' }}
      >
        <UserPreferencesModalMenu />
        <UserChangeRoleButton />
        <LogoutButton />
      </Menu>
    </div>
  );
}
