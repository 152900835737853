import React, { useContext, useState } from 'react';
import { LoadingScreen } from './LoadingScreen';

export const LoadingScreenContext = React.createContext({
  shown: false,
  message: undefined as string | undefined,
  setShown: (shown: boolean, message?: string) => {}
});

interface LoadingScreenContextProviderProps {
  children: React.ReactNode;
}

export const useLoadingScreenContext = () => useContext(LoadingScreenContext);

export function LoadingScreenContextProvider(
  props: LoadingScreenContextProviderProps
) {
  const [shown, setShown] = useState(false);
  const [message, setMessage] = useState(undefined as string | undefined);

  return (
    <LoadingScreenContext.Provider
      value={{
        shown,
        message,
        setShown: (shown: boolean, message?: string) => {
          setShown(shown);
          setMessage(message);
        }
      }}
    >
      <LoadingScreen />
      {props.children}
    </LoadingScreenContext.Provider>
  );
}
