import { FlowApi } from 'client/components/schema/flow/FlowApi';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { DocumentInstructionDto } from 'common/dto/generated/DocumentInstructionDto';
import { DocumentTemplateDto } from 'common/dto/generated/DocumentTemplateDto';
import React, { useCallback, useState } from 'react';

export interface IConfigDocumentDeleteButtonProps {
  file: DocumentTemplateDto | DocumentInstructionDto | undefined | null;
  type: 'instruction' | 'template';
}

export function ConfigDocumentDeleteButton(
  props: IConfigDocumentDeleteButtonProps
) {
  const { file, type } = props;

  const [deleteTemplate] = useApiMutation(FlowApi.templates.delete, {
    invalidates: [FlowApi.templates.list]
  });
  const [deleteInstruction] = useApiMutation(FlowApi.instructions.delete, {
    invalidates: [FlowApi.instructions.list]
  });
  const [loading, setLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    if (!file?.documentType) return;

    setLoading(true);
    try {
      if (type === 'instruction') {
        await deleteInstruction({ data: { type: file.documentType } });
      } else {
        await deleteTemplate({ data: { type: file.documentType } });
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }, [deleteTemplate, deleteInstruction]);

  if (!file || !file.documentType) {
    return null;
  }

  return (
    <ButtonDeleteConfirm
      title="Elimina File"
      iconOnlyButton
      buttonDanger
      buttonType="ghost"
      onConfirm={handleDelete}
    />
  );
}
