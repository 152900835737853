import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clx from 'classnames';
import './FileReviewListItem.scss';
import { Button, ButtonProps, Tooltip } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { FileListItem, FileListItemProps } from './FileListItem';
import { FileReviewMessage } from './FileReviewMessage';
import { ISODate } from '../display/date/ISODate';

export interface FileReviewListItemProps
  extends Omit<
    FileListItemProps,
    'revisionArea' | 'uploaderArea' | 'isRevisionFile'
  > {
  // Messaggio
  message?: string;
  // meta da mostrare se non è presente il documento
  createdAt?: Date;
  user?: string;
}
/*
 * Render Esteso di un elemento della lista dei file
 * che permette la visualizzazione dei messaggi di revisione
 */
export function FileReviewListItem(props: FileReviewListItemProps) {
  const { message, createdAt, user, ...othersProps } = props;

  if (!props.title && !message) return null;

  return (
    <div className="file-review-wrapper">
      <div className="file-review-icon">
        <MessageOutlined />
      </div>
      <div className="file-review-content">
        {props.title ? (
          <FileListItem
            isRevisionFile
            {...othersProps}
            icon={null}
            revisionArea={
              message ? (
                <FileReviewMessage>{message}</FileReviewMessage>
              ) : undefined
            }
          />
        ) : message ? (
          <div className="file-review-message-box">
            <span className="file-list-item-title">
              <span className="file-list-item-title--meta">
                <ISODate date={createdAt} />
                {user && <> - {user}</>}
              </span>
            </span>
            <FileReviewMessage>{message}</FileReviewMessage>
          </div>
        ) : null}
      </div>
    </div>
  );
}
