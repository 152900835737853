import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import { flowAcceptedFileFormatString } from 'client/components/schema/flow/detail/flowAcceptedFileFormat';
import { IConfigDocumentApiInput } from 'client/components/schema/flow/FlowApi';
import { RunApiMutation } from 'client/core/network/hooks/useApiMutation';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import React, { useState } from 'react';

export interface IConfigUploadDocumentButtonProps {
  typology: DocumentTypology;
  buttonText: string;
  onSuccessMessage: string;
  onSend: RunApiMutation<IConfigDocumentApiInput, any>;
}

export function ConfigUploadDocumentButton(
  props: IConfigUploadDocumentButtonProps
) {
  const { typology, buttonText, onSuccessMessage } = props;

  const [loading, setLoading] = useState(false);

  return (
    <Upload
      name="document"
      accept={flowAcceptedFileFormatString + ',.pdf'}
      beforeUpload={async file => {
        try {
          setLoading(true);
          if (!file) return false;
          const formData = new FormData();
          formData.append('document', file);
          await props.onSend({
            data: { type: typology, data: formData }
          });
          message.success(onSuccessMessage);
        } catch (e) {
          message.error(`Si è verificato un errore durante l'upload. Riprovare.`); // prettier-ignore
        } finally {
          setLoading(false);
          return false;
        }
      }}
      showUploadList={false}
      maxCount={0}
    >
      <Button size="small" icon={<UploadOutlined />} loading={loading}>
        {buttonText}
      </Button>
    </Upload>
  );
}
