import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired } from "cli/generate/decorators/SchemaDecorators";


/**
 * Dati relativi al flusso, quali le note al passaggio di stato
 */
@GenerateDto()
class FlowData {
    @SchemaLabel('Note')
    @SchemaNotRequired()
    notes?: string | null;   
}

/**
 * Rappresentazione DTO della classe FlowData 
 * Hash: 62456c1d6d6a24385a875d8d5a27336f
 */
@ValidationSchema(() => FlowDataSchema)
export class FlowDataDto {
  @ApiProperty({ required: false, type: String, description: 'Note' })
  notes?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<FlowDataDto>) {
    if (values != null) {
      Object.assign(this, values instanceof FlowDataDto ? values : plainToClass(FlowDataDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await FlowDataSchema.validate(classToPlain(this), options);
    return new FlowDataDto(validated);
  }
}

export const FlowDataSchema = yup
  .object({
    notes: yup.string().nullable().label('Note')
  })
  .noUnknown()
  .meta({ schemaName: "FlowDataSchema" })
  .required();
