import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";



@GenerateDto()
class WorkingGroupQuery {
    @SchemaLabel('Nome')
    name?: string | null;

    @SchemaLabel('Pagina')
    @SchemaValidate((schema) => schema.notRequired().default(1))
    page!: number;
  
    @SchemaLabel('Dimensione Pagina')
    @SchemaValidate((schema) => schema.notRequired().default(20))
    pagesize!: number;
}

/**
 * Rappresentazione DTO della classe WorkingGroupQuery 
 * Hash: f5cb52c12473ff1e48ec654353a6abd9
 */
@ValidationSchema(() => WorkingGroupQuerySchema)
export class WorkingGroupQueryDto {
  @ApiProperty({ required: false, type: String, description: 'Nome' })
  name?: string | null | undefined;
  @ApiProperty({ type: Number, description: 'Pagina' })
  page!: number;
  @ApiProperty({ type: Number, description: 'Dimensione Pagina' })
  pagesize!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<WorkingGroupQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof WorkingGroupQueryDto ? values : plainToClass(WorkingGroupQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await WorkingGroupQuerySchema.validate(classToPlain(this), options);
    return new WorkingGroupQueryDto(validated);
  }
}

export const WorkingGroupQuerySchema = yup
  .object({
    name: yup.string().nullable().label('Nome'),
    page: yup.number().required().notRequired().default(1).label('Pagina'),
    pagesize: yup.number().required().notRequired().default(20).label('Dimensione Pagina')
  })
  .noUnknown()
  .meta({ schemaName: "WorkingGroupQuerySchema" })
  .required();
