import * as React from 'react';
import { Button, ButtonProps, Space, Typography } from 'antd';
import './InfoBoxActions.scss';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

export interface IInfoBoxActionsProps {
  title?: React.ReactNode;
  text: React.ReactNode;
  actionButton?: ButtonProps;
  secondaryButton?: ButtonProps;
}

/**
 * Semplice Info Box che prevede la visualizzazione centrata degli elementi
 * è previsto un testo a sinstra per titolo o info
 */
export function InfoBoxActions(props: IInfoBoxActionsProps) {
  const { title, text, actionButton, secondaryButton } = props;

  return (
    <div className="info-box-actions">
      <Space align="center">
        <div className="info-box-actions-message">
          {title && <div className="title">{title}</div>}
          {text && <div className="text">{text}</div>}
        </div>
        {secondaryButton &&
          (React.isValidElement(secondaryButton) ? (
            secondaryButton
          ) : (
            <Button
              ghost
              danger
              size="large"
              icon={<CloseOutlined />}
              {...secondaryButton}
            />
          ))}
        {actionButton && (
          <Button
            type="primary"
            ghost
            size="large"
            icon={<CheckOutlined />}
            {...actionButton}
          />
        )}
      </Space>
    </div>
  );
}
