import React, { useState } from 'react';
import clx from 'classnames';
import './BottomActionsBar.scss';
import { Affix, Button, Space } from 'antd';
import { FixedBottomActionsBar } from './FixedBottomActionsBar';

export interface BottomActionsBarProps {
  children: React.ReactNode;
  fixedWidht?: boolean;
  fullWidht?: boolean;
  transparent?: boolean;
}

export function BottomActionsBar(props: BottomActionsBarProps) {
  const [affixed, setAffixed] = useState<boolean | undefined>(undefined);

  return (
    <>
      <Affix offsetBottom={0} onChange={a => setAffixed(a)}>
        <div
          className={clx('bottom-action-bar', {
            'fixed-width': props.fixedWidht
          })}
        >
          {props.fullWidht ? (
            props.children
          ) : (
            <Space className="actions-container">{props.children}</Space>
          )}
        </div>
      </Affix>
      {affixed && <FixedBottomActionsBar {...props} />}
    </>
  );
}
