import { BranchesOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import { FlowType } from '../../../../../common/schema/flow/FlowType';
import { getFlowTypeColor } from './getFlowTypeColor';
import { getFlowTypeLabel } from './getFlowTypeLabel';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import { FlowLogic } from 'common/schema/flow/FlowLogic';

export interface FlowTypeTagProps {
  flowType?: FlowType | null;
  typology?: DocumentTypology | null;
}

/**
 * Tag della Tipologia di flusso di qualità
 * in base alla tipologia di documenti oppure al tipo di flusso
 */
export function FlowTypeTag(props: FlowTypeTagProps) {
  if (!props.typology && !props.flowType) return null;

  const flow = props.typology
    ? FlowLogic.getFlowType(props.typology)
    : props.flowType;

  if (!flow) return null;
  return (
    <Tag color={getFlowTypeColor(flow)}>
      <BranchesOutlined rotate={90} /> {getFlowTypeLabel(flow)}
    </Tag>
  );
}
