import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowRevisionButton } from '../FlowRevisionButton';

export interface IFlowToQualitySecretaryRevisionButtonProps {
  data: FlowDto;
}

export function FlowToQualitySecretaryRevisionButton(
  props: IFlowToQualitySecretaryRevisionButtonProps
) {
  const { data } = props;

  const [toRevision] = useApiMutation(
    FlowApi.actions.toQualitySecretaryRevision,
    {}
  );

  return (
    <FlowRevisionButton
      data={data}
      modalTitle="Richiedi Modifiche"
      modalContent="Il documento verrà inoltrato alla segreteria qualità per le modifiche."
      sendButtonText="Richiedi Modifiche"
      initialState={[FlowState.QualityCheck, FlowState.RiskCheck]}
      nextState={FlowState.DocumentCheck}
      onSend={toRevision}
    />
  );
}
