import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowToDocumentCheckButtonProps {
  data: FlowDto;
}

/**
 * Bottone per inviare il documento alla segreteria (dal redattore)
 */
export function FlowToDocumentCheckButton(
  props: IFlowToDocumentCheckButtonProps
) {
  const { data } = props;

  const [toDocumentCheck] = useApiMutation(FlowApi.actions.toDocumentCheck, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Inviare il documento in approvazione?"
      modalContent="Il documento verrà presentato alla segreteria. Una volta inviato non sarà possibile apportare modifiche."
      initialState={[FlowState.Draft, FlowState.Submitted]}
      nextState={FlowState.DocumentCheck}
      sendButtonText="Invia in Approvazione"
      onSend={toDocumentCheck}
    />
  );
}
