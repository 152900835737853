import { Affix, AffixProps, PageHeader, PageHeaderProps } from 'antd';
import clx from 'classnames';
import * as React from 'react';
import { useState } from 'react';
import { pageFixedSizes } from '../../PageLayout';
import { PageFixedWidthWrapper } from '../content/PageFixedWidthWrapper';
import './PageHeading.scss';

/**
 * Funzione per ottenere il Nodo del titolo di pagina o le sue dimensioni
 * Utilizzato per gestire gli elementi fixed della pagina
 */
export function getPageHeading() {
  const node = document.getElementById('page-heading');
  return {
    node,
    clientRect: node?.getBoundingClientRect()
  };
}

export interface PageHeadingProps extends PageHeaderProps {
  children?: React.ReactNode;
  affix?: boolean;
  affixProps?: AffixProps;
  borderBottom?: boolean;
}

/**
 * Intestazione della singola pagina
 */
export function PageHeading(props: PageHeadingProps) {
  const [affixed, setAffixed] = useState(false);

  const { affix, affixProps, borderBottom, ...otherProps } = props;
  if (affix) {
    return (
      <Affix {...affixProps} onChange={affixed => setAffixed(affixed ?? false)}>
        <div
          className={clx('page-heading', {
            affixed,
            'border-bottom': borderBottom
          })}
        >
          <PageFixedWidthWrapper>
            <PageHeader {...otherProps} style={{ padding: 0 }} />
          </PageFixedWidthWrapper>
        </div>
      </Affix>
    );
  }

  return (
    <div
      className={clx('page-heading', {
        'border-bottom': borderBottom
      })}
    >
      <PageFixedWidthWrapper>
        <PageHeader {...otherProps} style={{ padding: 0 }} />
      </PageFixedWidthWrapper>
    </div>
  );
}

// id="page-heading"
