import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, List, Space } from 'antd';
import { Section } from 'client/ui/section/Section';
import { FileUnknownOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { DocumentListItem } from '../file/DocumentListItem';
import { useFormikContext } from 'formik';
import { FlowDto } from 'common/dto/generated/FlowDto';

export interface DocumentVersionsSectionProps {}
/**
 * Versioni del Documento
 */
export function DocumentVersionsSection(props: DocumentVersionsSectionProps) {
  const flowData = useFormikContext<FlowDto>();
  const documents = flowData.values.documents.sort(
    (a, b) => b.version - a.version
  );

  return (
    <Section
      icon={<FileUnknownOutlined />}
      title="Versioni precedenti del documento"
      hasPadding
    >
      {documents.map(document => (
        <DocumentListItem
          key={document.id}
          document={document}
          isHistory
          flowData={flowData.values}
        />
      ))}
    </Section>
  );
}
