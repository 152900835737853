import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { DocumentTypologyTag } from 'client/routes/flows/common/document/typology/DocumentTypologyTag';
import { ConfigDocumentDownloadLink } from './ConfigDocumentDownloadLink';
import { IConfigDocument } from './hooks/useConfigDocuments';

export const ConfigDocumentColumns: ColumnProps<IConfigDocument>[] = [
  {
    title: 'Tipologia',
    dataIndex: 'documentType',
    key: 'documentType',
    render: (_, record) => (
      <DocumentTypologyTag typology={record.typology.documentType} />
    ),
    width: 105
  },
  {
    title: 'File Template',
    dataIndex: 'template',
    key: 'template',
    render: (_, record) => (
      <ConfigDocumentDownloadLink file={record.template} type="template" />
    )
  },
  {
    title: 'File Istruzioni',
    dataIndex: 'instructions',
    key: 'instructions',
    render: (_, record) => (
      <ConfigDocumentDownloadLink
        file={record.instruction}
        type="instruction"
      />
    )
  }
];
