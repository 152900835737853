import { SendOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { useField } from 'formik';
import React from 'react';

export function FlowApprovalModalSendButton() {
  const [fileField] = useField('content');

  const disabled = !fileField.value;

  return (
    <FormikSendButton size="large" type="primary" disabled={disabled}>
      Approva <SendOutlined />
    </FormikSendButton>
  );
}
