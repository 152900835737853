import { FileOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { getFlowStateLabel } from 'common/schema/flow/flow-states/getFlowStateLabel';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IFlowStateTagProps {
  state?: FlowState;
}

export function FlowStateTag(props: IFlowStateTagProps) {
  if (!props.state) return null;

  const label = (
    <>
      <FileOutlined /> {getFlowStateLabel(props.state)}
    </>
  );

  switch (props.state) {
    case FlowState.Draft:
      return <Tag>{label}</Tag>;
    case FlowState.Submitted:
      return <Tag color="orange">{label}</Tag>;
    case FlowState.DocumentCheck:
      return <Tag color="blue">{label}</Tag>;
    case FlowState.QualityCheck:
      return <Tag color="geekblue">{label}</Tag>;
    case FlowState.RiskCheck:
      return <Tag color="geekblue">{label}</Tag>;
    case FlowState.Review:
      return <Tag color="pink">{label}</Tag>;
    case FlowState.StrategicReview:
      return <Tag color="pink">{label}</Tag>;
    case FlowState.Approved:
      return <Tag color="green">{label}</Tag>;
    case FlowState.Rejected:
      return <Tag color="red">{label}</Tag>;
    case FlowState.Published:
      return <Tag color="green">{label}</Tag>;
  }
  assertNever(props.state);
}
