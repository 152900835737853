import { Roles } from 'common/permissions/Roles';
import {
  ISettingVariable,
  SettingModuleKey,
  settings,
  SettingVariableType
} from './Settings';

/**
 * Definisce l'insieme delle variabili configurabili.
 */
const VariableList = [
  // FOLIUM
  settings('FOLIUM_URL', { label: 'URL Folium', help: 'URL del server di Folium', module: SettingModuleKey.Folium, placeholder: 'https://supporto.agora.it/demo/', order: 0 }), // prettier-ignore
  settings('FOLIUM_BASIC_AUTH', { label: 'Basic Auth', help: 'Token di autenticazione per Folium', module: SettingModuleKey.Folium, type: SettingVariableType.Password, order: 1 }), // prettier-ignore
  settings('FOLIUM_APP', { label: 'Applicazione', help: "Nome dell'applicazione su Folium", module: SettingModuleKey.Folium, order: 2 }), // prettier-ignore
  settings('FOLIUM_ENTE', { label: 'Ente', help: "Codice dell'ente su Folium", module: SettingModuleKey.Folium, order: 3 }), // prettier-ignore
  settings('FOLIUM_CLIENT_ID', { label: 'Client ID', help: 'Client ID per l\'autenticazione OAuth2', module: SettingModuleKey.Folium, placeholder: 'ClientDemoId', order: 4 }), // prettier-ignore
  settings('FOLIUM_CLIENT_SECRET', { label: 'Client Secret', help: 'Client Secret per l\'autenticazione OAuth2', module: SettingModuleKey.Folium, type: SettingVariableType.Password, order: 5 }), // prettier-ignore
  settings('FOLIUM_AOO', { label: 'AOO', help: 'AOO per l\'autenticazione OAuth2', module: SettingModuleKey.Folium, order: 6 }), // prettier-ignore
  settings('FOLIUM_SERVICE_USER_SERIAL', { label: 'Codice utente ws', help: 'Codice utente per le chiamate a Folium', module: SettingModuleKey.Folium, placeholder: 'qualita.ws', order: 7 }), // prettier-ignore
  settings('FOLIUM_SERVICE_USER_OFFICE_ID', { label: 'ID ufficio utente ws', help: 'ID numerico dell\'ufficio dell\'utente per le chiamate a Folium', module: SettingModuleKey.Folium, placeholder: '100', order: 8 }), // prettier-ignore
  settings('FOLIUM_NOTIFICATION_REGISTER', { label: 'Registro notifiche', help: 'ID registro dove inserire le notifiche su Folium', module: SettingModuleKey.Folium, type: SettingVariableType.Number, default: '1', order: 9 }), // prettier-ignore
  settings('FOLIUM_NOTIFICATION_CLASSIFICATION', { label: 'ID Titolario', help: 'ID del titolario di classificazione delle notifiche su Folium', module: SettingModuleKey.Folium, type: SettingVariableType.Number, placeholder: '100',  order: 10 }), // prettier-ignore

  // ALLINEAMENTO RUOLI FUOLIUM
  settings('FOLIUM_ROLE_SECRETARY', { label: Roles.QualitySecretary.displayName, help: `Codice ufficio associato alla ${Roles.QualitySecretary.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.QualitySecretary.name }, order: 0 }), // prettier-ignore
  settings('FOLIUM_ROLE_SECRETARY_ID', { label: `ID ${Roles.QualitySecretary.displayName}`, help: `ID Folium per l'ufficio ${Roles.QualitySecretary.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.QualitySecretary.name }, order: 1 }), // prettier-ignore

  settings('FOLIUM_ROLE_QUALITY', { label: Roles.QualityManager.displayName, help: `Codice ufficio associato al ${Roles.QualityManager.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.QualityManager.name }, order: 2 }), // prettier-ignore
  settings('FOLIUM_ROLE_QUALITY_ID', { label: `ID ${Roles.QualityManager.displayName}`, help: `ID Folium per l'ufficio ${Roles.QualityManager.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.QualityManager.name }, order: 3 }), // prettier-ignore

  settings('FOLIUM_ROLE_RISK', { label: Roles.RiskManager.displayName, help: `Codice ufficio associato al ${Roles.RiskManager.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.RiskManager.name }, order: 4 }), // prettier-ignore
  settings('FOLIUM_ROLE_RISK_ID', { label: `ID ${Roles.RiskManager.displayName}`, help: `ID Folium per l'ufficio ${Roles.RiskManager.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.RiskManager.name }, order: 5 }), // prettier-ignore

  settings('FOLIUM_ROLE_APPROVER', { label: Roles.Approver.displayName, help: `Codice ufficio associato al ${Roles.Approver.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.Approver.name }, order: 6 }), // prettier-ignore
  settings('FOLIUM_ROLE_APPROVER_ID', { label: `ID ${Roles.Approver.displayName}`, help: `ID Folium per l'ufficio ${Roles.Approver.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.Approver.name }, order: 7 }), // prettier-ignore

  settings('FOLIUM_ROLE_STRATEGIC', { label: Roles.StrategicDirector.displayName, help: `Codice ufficio associato alla ${Roles.StrategicDirector.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.StrategicDirector.name }, order: 8 }), // prettier-ignore
  settings('FOLIUM_ROLE_STRATEGIC_ID', { label: `ID ${Roles.StrategicDirector.displayName}`, help: `ID Folium per l'ufficio ${Roles.StrategicDirector.displayName}`, module: SettingModuleKey.FoliumRoles, options: { role: Roles.StrategicDirector.name } , order: 9 }), // prettier-ignore

  // FIRMA DIGITALE
  settings('DIGITAL_SIGNATURE_ENABLED', { label: 'Abilita Firma Demo', help: 'Da attivare solo in fase di test', module: SettingModuleKey.DigitalSignature, type: SettingVariableType.Boolean, expose: true }), // prettier-ignore

  // LDAP
  settings('LDAP_URL', {label: 'URL', help: 'URL di connessione al server LDAP', module: SettingModuleKey.Ldap, placeholder: 'ldap://ldap.host.com:389', order: 0 }), // prettier-ignore
  settings('LDAP_BIND_DN', {label: 'Bind DN', help: 'Bind DN per autenticazione', module: SettingModuleKey.Ldap, placeholder: 'cn=root', order: 1 }), // prettier-ignore
  settings('LDAP_BIND_CREDENTIALS', {label: 'Bind Credentials', help: 'Secret per l\'autenticazione', module: SettingModuleKey.Ldap, type: SettingVariableType.Password, order: 2 }), // prettier-ignore
  settings('LDAP_SEARCH_BASE', {label: 'Search Base', help: 'Posizione nella directory dove inizia la ricerca di un utente', module: SettingModuleKey.Ldap, placeholder: 'ou=users,dc=test,dc=com', order: 3 }), // prettier-ignore
  settings('LDAP_SEARCH_FILTER', {label: 'Filtro', help: 'Filtro per la ricerca utente', module: SettingModuleKey.Ldap, placeholder: '(uid={{username}})', order: 4 }), // prettier-ignore
  settings('LDAP_SERIAL', {label: 'Seriale', help: 'Campo dell\'utente LDAP da utilizzare come identificativo', module: SettingModuleKey.Ldap, placeholder: 'uid', order: 5 }), // prettier-ignore
  settings('LDAP_DISPLAY_NAME', {label: 'Nome utente mostrato', help: 'Campo dell\'utente LDAP da utilizzare come nome mostrato', module: SettingModuleKey.Ldap, placeholder: 'cn', order: 6 }), // prettier-ignore
  settings('LDAP_CERT_PATH', {label: 'Path certificati', help: 'Path ai certificati', module: SettingModuleKey.Ldap, placeholder: '/path/to/ldap/cert.crt', order: 7 }), // prettier-ignore

  // SERVIZIO MAIL
  settings('MAIL_ENABLED', { label: 'Abilita servizio mail', help: 'Abilita il servizio di invio mail', module: SettingModuleKey.Mail, type: SettingVariableType.Boolean, expose: true, order: 0 }), // prettier-ignore
  settings('MAIL_HOST', { label: 'Host', help: 'Host del server di posta', module: SettingModuleKey.Mail, placeholder: 'smtp.host.com', order: 1 }), // prettier-ignore
  settings('MAIL_PORT', { label: 'Porta', help: 'Porta del server di posta', module: SettingModuleKey.Mail, default: '465', order: 2 }), // prettier-ignore
  settings('MAIL_SECURE', { label: 'SSL', help: 'Abilita SSL', module: SettingModuleKey.Mail, type: SettingVariableType.Boolean, default: "true", order: 3 }), // prettier-ignore
  settings('MAIL_AUTH_USER', { label: 'Utente', help: 'Utente per l\'autenticazione', module: SettingModuleKey.Mail, placeholder: 'user@mail.test', order: 4 }), // prettier-ignore
  settings('MAIL_AUTH_PASS', { label: 'Password', help: 'Password per l\'autenticazione', module: SettingModuleKey.Mail, type: SettingVariableType.Password, order: 5 }), // prettier-ignore
  settings('MAIL_DEFAULT_FROM_NAME', {label: 'Nome mittente', help: 'Nome del mittente di default', module: SettingModuleKey.Mail, default: 'Portale Flusso Qualità', order: 6 }), // prettier-ignore
  settings('MAIL_DEFAULT_FROM_ADDRESS', {label: 'Indirizzo mittente', help: 'Indirizzo del mittente di default', module: SettingModuleKey.Mail, placeholder: 'flusso-qualità@mail.com', order: 7 }) // prettier-ignore
];

export type SettingKey = typeof VariableList[number]['key'];

export const SettingVariables = (VariableList as ISettingVariable[])
  // Li ordino per label
  .sort((a, b) => a.label.localeCompare(b.label));

export const SettingVariableKeys = SettingVariables.map(
  v => v.key
) as SettingKey[];

/** Variabili esposte Frontend */
export const ExposedSettingVariables = SettingVariables.filter(v =>
  Boolean(v.expose)
);
export const ExposedSettingVariableKeys = ExposedSettingVariables.map(
  v => v.key
) as SettingKey[];
