import { resolveApiPath } from 'client/core/appConfig';
import { apiClient } from 'client/core/network/apiClient';
import {
  DocumentInstructionDto,
  PaginatedDocumentInstructionDto
} from 'common/dto/generated/DocumentInstructionDto';
import {
  DocumentTemplateDto,
  PaginatedDocumentTemplateDto
} from 'common/dto/generated/DocumentTemplateDto';
import { FlowDto, PaginatedFlowDto } from 'common/dto/generated/FlowDto';
import { FlowApproveDataDto } from 'common/dto/query/FlowApproveDataDto';
import { FlowDataDto } from 'common/dto/query/FlowDataDto';
import { FlowListQueryDto } from 'common/dto/query/FlowListQueryDto';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';

export const FlowApi = {
  create: (opts: { input: FormData }) =>
    apiClient.requestWithResponseType(FlowDto).post(`/flows`, opts.input, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }),

  update: (opts: { id: number; input: FormData }) =>
    apiClient
      .requestWithResponseType(FlowDto)
      .put(`/flows/${opts.id}`, opts.input, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }),

  detail: (opts: { id: number }) =>
    apiClient.requestWithResponseType(FlowDto).get(`/flows/${opts.id}`),

  list: (opts: { query: FlowListQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedFlowDto)
      .get('/flows', { params: opts.query }),

  delete: (opts: { id: number }) =>
    apiClient.request().delete(`/flows/${opts.id}`),

  actions: {
    toDocumentCheck: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/toDocumentCheck`, opts.data),
    toQualityCheck: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/toQualityCheck`, opts.data),
    toRiskCheck: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/toRiskCheck`, opts.data),

    toReview: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/toReview`, opts.data),
    toStrategicReview: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/toStrategicReview`, opts.data),

    approve: (opts: { id: number; data: FormData }) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/approve`, opts.data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }),
    approveWithoutSign: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/approve-no-sign`, opts.data),
    reject: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/reject`, opts.data),

    publish: (opts: IFlowActionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/publish`, opts.data),

    // RICHIESTA MODIFICHE
    toCompilerRevision: (opts: IFlowNeedRevisionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(`/flows/${opts.id}/actions/toCompilerRevision`, opts.data, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }),

    toQualitySecretaryRevision: (opts: IFlowNeedRevisionApiInput) =>
      apiClient
        .requestWithResponseType(FlowDto)
        .put(
          `/flows/${opts.id}/actions/toQualitySecretaryRevision`,
          opts.data,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        )
  },
  templates: {
    list: () =>
      apiClient
        .requestWithResponseType(PaginatedDocumentTemplateDto)
        .get(`/document-templates`),
    get: (opts: { type: DocumentTypology }) =>
      apiClient
        .requestWithResponseType(DocumentTemplateDto)
        .get(`/document-templates/${opts.type}`),
    edit: (opts: IConfigDocumentApiInput) =>
      apiClient
        .requestWithResponseType(DocumentTemplateDto)
        .put(`/document-templates/${opts.type}`, opts.data),
    delete: (opts: { type: DocumentTypology }) =>
      apiClient.request().delete(`/document-templates/${opts.type}`),
    downloadLink: (opts: { type: DocumentTypology }) =>
      resolveApiPath(`/document-templates/${opts.type}/download`)
  },
  instructions: {
    list: () =>
      apiClient
        .requestWithResponseType(PaginatedDocumentInstructionDto)
        .get(`/document-instructions`),
    get: (opts: { type: DocumentTypology }) =>
      apiClient
        .requestWithResponseType(DocumentInstructionDto)
        .get(`/document-instructions/${opts.type}`),
    edit: (opts: IConfigDocumentApiInput) =>
      apiClient
        .requestWithResponseType(DocumentInstructionDto)
        .put(`/document-instructions/${opts.type}`, opts.data),
    delete: (opts: { type: DocumentTypology }) =>
      apiClient.request().delete(`/document-instructions/${opts.type}`),
    downloadLink: (opts: { type: DocumentTypology }) =>
      resolveApiPath(`/document-instructions/${opts.type}/download`)
  }
};

export interface IConfigDocumentApiInput {
  type: DocumentTypology;
  data: FormData;
}

export interface IFlowActionApiInput {
  id: number;
  data: FlowDataDto;
}

export interface IFlowNeedRevisionApiInput {
  id: number;
  data: FormData;
}
