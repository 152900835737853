import React from 'react';
import { Alert } from 'antd';
import { FlowDto } from 'common/dto/generated/FlowDto';

export interface RevisionAlertSectionProps {
  // per gestire il flusso di esempio
  // corrisponde ai dati del Documento nel flusso
  flowData?: FlowDto;
}

/**
 * Sezione da mostrare quando per il doicumento è stata richiesta una revisione
 */
export function RevisionAlertSection(props: RevisionAlertSectionProps) {
  const { flowData } = props;

  const isRevisionTask = flowData?.inRevision;

  if (!isRevisionTask) return null;

  const fakeText =
    'Testo della Nota del messaggio di richiesta di modifica. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

  return (
    <Alert
      message="Richiesta di Revisione Documento"
      description={fakeText}
      type="warning"
      showIcon
      closable
    />
  );
}
