import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, List, Space, Timeline } from 'antd';
import { Section } from 'client/ui/section/Section';
import { ClockCircleOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { useFormikContext } from 'formik';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { assertNever } from 'server/utils/typings/assertNever';

export enum FlowEventTypes {
  Created = 'created',
  Deleted = 'deleted',
  Advancement = 'advancement',
  RevisionRequest = 'revisionRequest',
  Approved = 'approved',
  Rejected = 'rejected',
  Published = 'published'
}

export function getTextFromEventType(eventType: FlowEventTypes | string) {
  switch (eventType) {
    case 'created':
    case FlowEventTypes.Created:
      return 'Creazione del Documento';
    case 'deleted':
    case FlowEventTypes.Deleted:
      return 'Cancellazione del Documento';
    case 'advancement':
    case FlowEventTypes.Advancement:
      return 'Invio in Approvazione del Documento';
    case 'revisionRequest':
    case FlowEventTypes.RevisionRequest:
      return 'Richiesta di Revisione';
    case 'approved':
    case FlowEventTypes.Approved:
      return 'Approvazione del Documento';
    case 'rejected':
    case FlowEventTypes.Rejected:
      return 'Rifiuto del Documento';
    case 'published':
    case FlowEventTypes.Published:
      return 'Pubblicazione del Documento';
    default:
      return `Evento: (${eventType})`;
  }
  // assertNever(eventType);
}
