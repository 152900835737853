import React from 'react';
import { message, Space, Tabs } from 'antd';
const { TabPane } = Tabs;
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeadingBrianza } from 'client/ui/layout/header-brianza/PageHeadingBrianza';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { yup } from 'common/validation/initYup';
import { RouteComponentProps } from 'react-router-dom';
import { FlowTypeTag } from '../../../components/schema/flow/flow-type/FlowTypeTag';
import { FlowPageSections } from './FlowPageSections';
import { FlowSteps } from './steps/FlowSteps';
import { FlowStateTag } from 'client/components/schema/flow/tags/FlowStateTag';
import { FlowApi } from 'client/components/schema/flow/FlowApi';
import { AlertPage } from 'client/ui/alert/AlertPage';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { FlowDto, FlowSchema } from 'common/dto/generated/FlowDto';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowSaveButton } from 'client/components/schema/flow/detail/FlowSaveButton';
import { FlowStateActionButton } from 'client/components/schema/flow/detail/FlowStateActionButton';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { FlowState } from 'common/schema/flow/FlowState';
import { useFlow } from 'client/components/schema/flow/detail/useFlow';
import { get } from 'lodash';
import { FlowDeleteButton } from 'client/components/schema/flow/detail/FlowDeleteButton';
import { PageTabs } from 'client/ui/tabs/PageTabs';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { FlowHistorySection } from './hystory/FlowHistorySection';
import { DocumentVersionsSection } from './versions/DocumentVersionsSection';
import { PageTabsExtraContent } from 'client/ui/tabs/PageTabsExtraContent';
import { FlowReleaseButton } from 'client/components/schema/flow/detail/FlowReleaseButton';
import { FlowSelfAssignButton } from 'client/components/schema/flow/detail/FlowSelfAssignButton';

export const FakeSchema = yup
  .object({
    name: yup.string().required().label('Oggetto Documento'),
    type: yup.string().required().label('Tipologia Documento')
  })
  .notRequired();

export interface FlowPageProps extends RouteComponentProps<{ id: string }> {}

/**
 * Pagina del flusso del documento
 */
export function FlowPage(props: FlowPageProps) {
  const user = useCurrentUser();
  const id =
    props.match.params.id === 'create'
      ? null
      : parseInt(props.match.params.id, 10);

  const { result, error, loading } = useFlow(id);

  const [create] = useApiMutation(FlowApi.create, {
    invalidates: [FlowApi.detail]
  });
  const [update] = useApiMutation(FlowApi.update, {
    invalidates: [FlowApi.detail]
  });

  const flowData =
    id == null
      ? new FlowDto({
          owner: { name: user?.name },
          state: FlowState.Draft
        })
      : result;

  if (loading || !flowData) {
    return <LoadingPage />;
  }

  if (error) {
    return (
      <AlertPage
        showIcon
        type="error"
        message="Impossibile accedere alla pagina"
        description={getNetworkErrorMessage(error)}
      />
    );
  }

  const canEdit = FlowMachine.can(flowData, user!, 'edit').allowed;
  const isNew = id == null;

  return (
    <CorePageLayout>
      <FormikAugmented<FlowDto>
        initialValues={flowData}
        enableReinitialize
        validationSchema={FlowSchema}
        validationContext={{}}
        onSubmit={async rawValues => {
          try {
            const input = await rawValues.validate();

            // Creo il form data per inviare il file e i dati del flusso
            const formData = new FormData();
            // utilizzo rawValues perché il validatore non gestisce correttamente la validazione dei file
            // Il campo è comunque utilizzato per non passare uno stato al componente figlio della upload
            // e per aggiornare in automatico "dirty" del formik
            if (rawValues.file) formData.append('document', rawValues.file);

            Object.keys(input)
              // Rimuovo il campo file perché non è un campo del flusso
              .filter(k => k !== 'file')
              .forEach(key => {
                const value = get(input, key);
                // Se è un array, lo gestisco diversamente per la conversione in from data
                if (Array.isArray(value)) {
                  for (const v of value) {
                    formData.append(`${key}[]`, JSON.stringify(v));
                  }
                } else {
                  formData.set(key, get(input, key));
                }
              });

            if (isNew) {
              const result = await create({
                data: { input: formData }
              });
              message.success('Documento creato con successo.');
              props.history.push(`/flows/${result.data!.id}`);
            } else {
              const result = await update({
                data: { id: id!, input: formData }
              });
              message.success('Documento salvato con successo.');
            }
          } catch (e) {
            message.error(`Si è verificato un errore durante il salvataggio. Riprovare`); // prettier-ignore
          }
        }}
      >
        <FormikForm editable={canEdit} helpInTooltips showVisualFeedback>
          <PageHeadingBrianza
            title={
              <PageHeadingTitle>
                {flowData.subject ?? 'Nuovo Documento'}
              </PageHeadingTitle>
            }
            tags={
              <Space size={0}>
                {/* <FlowTypeTag flowType={flowData.flowType} /> */}
                <FlowStateTag state={flowData.state} />
              </Space>
            }
            extra={
              <Space>
                <FlowDeleteButton flow={flowData} />
                <FlowReleaseButton flow={flowData} />
                <FlowSelfAssignButton flow={flowData} />
                <FlowSaveButton />
              </Space>
            }
            steps={<FlowSteps flowData={flowData} />}
          />
          {/* TAB GENERALE */}
          <PageTabs
            defaultActiveKey="general"
            tabBarExtraContent={
              <PageTabsExtraContent>
                <FlowTypeTag flowType={flowData.flowType} />
              </PageTabsExtraContent>
            }
          >
            <TabPane tab="Documento" key="general">
              <FlowPageSections />
              {/* Pulsanti azioni stato*/}
              <FlowStateActionButton />
            </TabPane>

            {/* TAB VERSIONI */}
            <TabPane
              tab="Versioni Precedenti"
              key="version"
              disabled={!flowData.documents?.length}
            >
              <PageContent fixedWidht>
                {/* <div>elenco file versioni precedenti</div> */}
                <DocumentVersionsSection />
              </PageContent>
            </TabPane>

            {/* TAB CRONOLOGIA */}
            <TabPane tab="Cronologia" key="history" disabled={!id}>
              <PageContent fixedWidht>
                {/* cronologia frusso approvazione*/}
                <FlowHistorySection />
              </PageContent>
            </TabPane>
          </PageTabs>
          {/*  MAIN SECTION CON GLI STEPS */}
          {/* <FlowPageSections /> */}
          {/* Pulsanti azioni stato*/}
          {/* <FlowStateActionButton /> */}
        </FormikForm>
      </FormikAugmented>
    </CorePageLayout>
  );
}
