import { IUserType } from 'common/dto/generated/UserDto';

export class UserLogic {
  static canLogout(user: IUserType | null) {
    if (user?.originalStrategy === 'jwt') return true;
    if (user?.originalStrategy === 'saml') return true;
    if (user?.originalStrategy === 'ldap') return true;

    return false;
  }

  static canOnlyReadOwned(user: IUserType) {
    return (
      user.getRole().hasPermission('flow.read:own') &&
      !user.getRole().hasPermission('flow.read')
    );
  }

  static canOnlyDeleteOwned(user: IUserType) {
    return (
      user.getRole().hasPermission('flow.delete:own') &&
      !user.getRole().hasPermission('flow.delete')
    );
  }
}
