import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clx from 'classnames';
import './FileReviewMessage.scss';
import { Button, ButtonProps, Tooltip } from 'antd';

export interface FileReviewMessageProps {
  // Messaggio
  children?: React.ReactNode;
  // Label del messaggio (default messaggio)
  label?: string;
}
/*
 * Render di un elemento della lista dei file
 */
export function FileReviewMessage(props: FileReviewMessageProps) {
  const { children, label } = props;
  if (!children) return null;

  return (
    <div className={clx('review-message-wrapper')}>
      <div className="label">
        <em>{label ?? 'Messaggio:'}</em>
      </div>
      <div className="message">{children}</div>
    </div>
  );
}
