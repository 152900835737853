import { Col, Row } from 'antd';
import { RoleSelectForm } from 'client/components/login/RoleSelectForm';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageLayout } from 'client/ui/layout/PageLayout';
import React from 'react';

export interface IRoleSelectionPageProps {}

export function RoleSelectionPage(props: IRoleSelectionPageProps) {
  const {} = props;
  return (
    <CorePageLayout>
      <Row justify="center" align="middle" style={{ width: '100%' }}>
        <Col xs={20} md={12}>
          <RoleSelectForm />
        </Col>
      </Row>
    </CorePageLayout>
  );
}
