import * as React from 'react';
import clx from 'classnames';
import './FixedBottomActionsBar.scss';
import { Button, Space } from 'antd';

export interface FixedBottomActionsBarProps {
  children: React.ReactNode;
  fixedWidht?: boolean;
  fullWidht?: boolean;
  transparent?: boolean;
}

export function FixedBottomActionsBar(props: FixedBottomActionsBarProps) {
  return (
    <div
      className={clx('fixed-bottom-action-bar', {
        'transparent-bar': props.transparent
      })}
    >
      <div className={clx('action-bar', { 'fixed-width': props.fixedWidht })}>
        {props.fullWidht ? (
          props.children
        ) : (
          <Space className="actions-container">{props.children}</Space>
        )}
      </div>
    </div>
  );
}
