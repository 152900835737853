import React from 'react';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeadingBrianza } from 'client/ui/layout/header-brianza/PageHeadingBrianza';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { TemplateSection } from './documents/ConfigDocumentSection';
import { usePermissions } from 'client/components/auth/AuthModule';
import { PageTabs } from 'client/ui/tabs/PageTabs';
import { Tabs } from 'antd';
import { SettingPanel } from './settings/SettingPanel';

const { TabPane } = Tabs;

export interface SettingPageProps {}

/**
 * Pagina pannello di controllo
 */
export function SettingPage(props: SettingPageProps) {
  const permissions = usePermissions();
  return (
    <CorePageLayout>
      <PageHeadingBrianza
        borderBottom={false}
        title={
          <PageHeadingTitle>{'Pannello di configurazione'}</PageHeadingTitle>
        }
      />
      <PageTabs defaultActiveKey="general">
        <TabPane tab="Configurazione" key="general">
          <SettingPanel />
        </TabPane>
        <TabPane tab="Template e Istruzioni" key="templates">
          <PageContent fixedWidht>
            {permissions.has(['instruction.write', 'template.write']) && (
              <TemplateSection />
            )}
          </PageContent>
        </TabPane>
      </PageTabs>
    </CorePageLayout>
  );
}
