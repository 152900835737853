import React from 'react';
import clx from 'classnames';
import { Typography, Col, Row, Layout } from 'antd';
const { Header } = Layout;
const { Title } = Typography;
import './PageLayoutHeaderBrianza.scss';
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { resolvePublicPath } from 'client/core/appConfig';
import { PageFixedWidthWrapper } from '../page/content/PageFixedWidthWrapper';
import { UserSubMenu } from '../header/UserSubMenu';

/**
 * Placeholder per il logo proncipale dell'applicativo:
 * inseriamo un'immagine trasparente con le dimensioni del logo (il logo sara inserito come background).
 * Le dimensioni e il file del logo sono definite nel thema scss.
 */
const LogoPlaceholder = require('./image/ASST_Brianza.svg');

export interface PageLayoutHeaderBrianzaProps {
  children: React.ReactNode;
  hasMainMenu?: boolean;
}

/**
 * Fascia in alto della pagina che contiene il logo
 * il nome e il ruolo dell'utente ed eventualmente il nome della UID
 */
export function PageLayoutHeaderBrianza(props: PageLayoutHeaderBrianzaProps) {
  const theme = useThemeConfig();

  return (
    <Header className="page-header-brianza">
      <PageFixedWidthWrapper>
        <div className="top-header">
          <div className="top-header-logo">
            <img src={resolvePublicPath(LogoPlaceholder)} alt="ASST Brianza" />
          </div>
          <div
            className={clx('top-header-menu', {
              'not-main-menu': !props.hasMainMenu
            })}
          >
            <Row align="middle" className="top-name-row">
              <Col flex="1 1 auto">
                <Title level={2}>Gestore Flusso Qualità</Title>
              </Col>
              <Col flex="0 0 auto">
                <UserSubMenu />
              </Col>
            </Row>
            <Row className="top-menu-row">
              <Col span={24}>{props.children}</Col>
            </Row>
          </div>
        </div>
      </PageFixedWidthWrapper>
    </Header>
  );
}
