import React from 'react';
import { Formik } from 'formik';
import { Card, Button, Radio } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import {
  ICreateAccessTokenDto,
  createAccessTokenValidator
} from 'common/dto/AuthDto';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Asserts } from 'yup';
import { AuthApi } from '../auth/AuthApi';
import { AuthActions } from '../auth/AuthModule';
import { ReactState } from 'client/core/hooks/ReactState';
import { LoginRadioAuthType } from './LoginRadioAuthType';

interface Props {
  authTypeState: ReactState<'ldap' | 'local'>;
}

/**
 * Form di login all'applicativo, tramite le API di creazione AccessToken JWT.
 */
export function LoginForm(props: Props) {
  const [login, { loading }] = useApiMutation(AuthApi.createAccessToken, {});
  const dispatch = useDispatch();

  return (
    <Formik<ICreateAccessTokenDto>
      initialValues={
        createAccessTokenValidator.cast({}, { assert: true }) as Asserts<
          typeof createAccessTokenValidator
        >
      }
      validate={schemaToValidator(createAccessTokenValidator)}
      onSubmit={async (values, helpers) => {
        const result = await login({ data: values });
        dispatch(AuthActions.loginSucceed('jwt', result.data.accessToken));
      }}
    >
      <LoaderSpin spinning={loading} transparent>
        <Card bordered={false} style={{ minWidth: '330px' }}>
          <FormikForm layout="vertical">
            <TextInputItem<ICreateAccessTokenDto>
              name="login"
              autoComplete="username"
              prefix={<UserOutlined style={{ color: '#d9d9d9' }} />}
              placeholder="Username"
            />
            <TextInputItem<ICreateAccessTokenDto>
              name="password"
              type="password"
              autoComplete="current-password"
              prefix={<LockOutlined style={{ color: '#d9d9d9' }} />}
              placeholder="Password"
            />
            <LoginRadioAuthType authTypeState={props.authTypeState} />
            <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
              Accedi
            </Button>
          </FormikForm>
        </Card>
      </LoaderSpin>
    </Formik>
  );
}
