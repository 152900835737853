import * as React from 'react';
import { Typography } from 'antd';
import './UserSubMenu.scss';
import { useCurrentUser } from 'client/components/auth/AuthModule';

const { Text } = Typography;

export interface UserSubMenuProps {}

/**
 * Visualizza il nome ed il ruolo dell'utente loggato.
 */
export function UserSubMenu(props: UserSubMenuProps) {
  const currentUser = useCurrentUser();

  if (!currentUser) return null;

  return (
    <div className="user-sub-menu">
      {/* <Avatar icon="user" size="small" /> */}
      <div className="name-container">
        <Text className="name">{currentUser?.name}</Text>
        <Text className="role">{currentUser?.getRole().displayName}</Text>
      </div>
    </div>
  );
}
