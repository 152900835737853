import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import { FlowState } from 'common/schema/flow/FlowState';
import { FlowType } from 'common/schema/flow/FlowType';
import { DocumentTemplateDto } from './DocumentTemplateDto';
import { EventLogDto } from './EventLogDto';
import { FlowDocumentDto } from './FlowDocumentDto';
import { UserDto } from './UserDto';
/**
 * Rappresentazione DTO della classe Flow definita in: src/server/schema/flow/Flow.entity.ts
 * Hash: b8a1997d939298efc4925b85ae28fc69
 */
@ValidationSchema(() => FlowSchema)
export class FlowDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Oggetto' })
  subject!: string;
  @ApiProperty({ required: false, type: String, description: 'Descrizione' })
  description?: string | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Da Revisionare' })
  inRevision?: boolean | null | undefined;
  @ApiProperty({ enum: ['AnalisiProcesso', 'MappaProcesso', 'IstruzioneOperativa', 'ProceduraOperativa', 'PDTA', 'PianoAziendale', 'DocumentoOrganizzativo', 'ConsensoFoglioInformativo', 'Regolamento'], description: 'Tipo Documento' })
  documentType!: DocumentTypology;
  @ApiProperty({ enum: ['Documenti', 'ConsensiInformati', 'Regolamenti'], description: 'Tipo Flusso' })
  flowType!: FlowType;
  @ApiProperty({ required: false, enum: ['Draft', 'Submitted', 'DocumentCheck', 'QualityCheck', 'RiskCheck', 'Review', 'StrategicReview', 'Approved', 'Rejected', 'Published'], description: 'Stato Flusso' })
  state!: FlowState;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;
  @ApiProperty({ required: false, type: Number })
  ownerId!: number;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  owner!: UserDto;
  @ApiProperty({ required: false, type: Number })
  workingUserId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  workingUser?: UserDto | null;
  @ApiProperty({ required: false, type: () => [EventLogDto] })
  @Type(() => EventLogDto)
  eventLogs!: EventLogDto[];
  @ApiProperty({ required: false, type: () => [FlowDocumentDto] })
  @Type(() => FlowDocumentDto)
  documents!: FlowDocumentDto[];
  @ApiProperty({ required: false, type: () => [UserDto], description: 'Gruppo di Lavoro' })
  @Type(() => UserDto)
  workingGroup!: UserDto[];
  @ApiProperty({ required: false, type: Object, description: 'File' })
  file?: File | null | undefined;
  @ApiProperty({ required: false, type: () => DocumentTemplateDto, description: 'Template' })
  @Type(() => DocumentTemplateDto)
  documentTemplate?: DocumentTemplateDto | null;
  @ApiProperty({ required: false, type: () => DocumentTemplateDto, description: 'Istruzioni' })
  @Type(() => DocumentTemplateDto)
  documentInstructions?: DocumentTemplateDto | null;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<FlowDto>) {
    if (values != null) {
      Object.assign(this, values instanceof FlowDto ? values : plainToClass(FlowDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await FlowSchema.validate(classToPlain(this), options);
    return new FlowDto(validated);
  }
}

/** Interfaccia simmetrica al DTO FlowDto */
export type IFlowType = IDto<FlowDto>;

/**
 * DTO Paginato della classe Flow
 */
export class PaginatedFlowDto {
  @ApiProperty({ type: [FlowDto] })
  @Type(() => FlowDto)
  items!: FlowDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const FlowSchema = yup
  .object({
    id: yup.number(),
    subject: yup.string().required().label('Oggetto'),
    description: yup.string().nullable().label('Descrizione'),
    inRevision: yup.boolean().nullable().label('Da Revisionare'),
    documentType: yup.string().oneOfEnum(DocumentTypology).required().label('Tipo Documento'),
    flowType: yup.string().oneOfEnum(FlowType).required().label('Tipo Flusso'),
    state: yup.string().oneOfEnum(FlowState).default(FlowState.Draft).label('Stato Flusso'),
    ownerId: yup.number(),
    workingUserId: yup.number().nullable(),
    workingGroup: yup.array(yup.object({ id: yup.number().required() })).default([]).label('Gruppo di Lavoro'),
    file: yup.mixed().nullable().label('File')
  })
  .noUnknown()
  .meta({ schemaName: "FlowSchema" })
  .required();
