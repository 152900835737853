import { SendOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import { FlowDocumentLogic } from 'common/schema/flow/flow-document/FlowDocumentLogic';
import { FlowLogic } from 'common/schema/flow/FlowLogic';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { FlowState } from 'common/schema/flow/FlowState';
import { useFormikContext } from 'formik';
import React, { useMemo, useState } from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';
import { FlowApprovalModal } from '../modal/FlowApprovalModal';
import { FlowNoSignApproveButton } from './FlowNoSignApproveButton';

export interface IFlowApproveButtonProps {
  data: FlowDto;
}

export function FlowApproveButton(props: IFlowApproveButtonProps) {
  const { data } = props;

  const [visibile, setVisible] = useState(false);
  const user = useCurrentUser();

  const { dirty } = useFormikContext();

  const lastVersionId = useMemo(() => {
    const result = FlowDocumentLogic.getLastVersionId(data);
    if (!result && data.state !== FlowState.Draft) {
      console.warn(`[FlowApproveButton] Nessun documento trovato per il flusso ${data.id}`); // prettier-ignore
    }
    return result;
  }, [data]);

  if (!FlowLogic.isState(data, [FlowState.Review, FlowState.StrategicReview])) {
    return null;
  }

  const { allowed } = FlowMachine.to(data, FlowState.Approved, user);

  if (!allowed) {
    return null;
  }

  if (!lastVersionId) {
    return null;
  }

  // Se non ha bisogno di essere firmato, mostra il pulsante di approvazione senza firma
  if (!FlowDocumentLogic.needToBeSignedApproved(data)) {
    return <FlowNoSignApproveButton data={data} />;
  }

  return (
    <>
      <Tooltip
        title={dirty ? 'Salva il documento prima di inviarlo' : undefined}
      >
        <Button
          size="large"
          type="primary"
          onClick={() => setVisible(true)}
          disabled={dirty}
        >
          Approva <SendOutlined />
        </Button>
      </Tooltip>
      <FlowApprovalModal
        visible={visibile}
        setVisible={setVisible}
        documentId={lastVersionId}
        flowId={data.id}
      />
    </>
  );
}
