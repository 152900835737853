import React from 'react';
import { Divider, Space } from 'antd';

export interface WorkingGroupUserWrapperProps {
  children: React.ReactNode;
}

/**
 * Contenitore per mostrare la lista di utenti redattori.
 */
export function WorkingGroupUserWrapper(props: WorkingGroupUserWrapperProps) {
  return (
    <Space
      wrap
      style={{ rowGap: 0 }}
      split={<Divider style={{ margin: 0 }} type="vertical" />}
    >
      {props.children}
    </Space>
  );
}
