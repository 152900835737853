import React from 'react';
import { Popconfirm, Button } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { ButtonProps } from 'antd/lib/button';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

export interface ButtonConfirmProps extends PopconfirmProps {
  loading?: boolean;
  size?: SizeType;
  icon?: ButtonProps['icon'];
  type?: ButtonProps['type'];
  children?: React.ReactElement | string;
  disabled?: boolean;
}

export function ButtonConfirm(props: ButtonConfirmProps) {
  const {
    placement,
    loading,
    size,
    type,
    children,
    icon,
    disabled,
    ...otherProps
  } = props;

  return (
    <Popconfirm
      placement={placement ? placement : 'topRight'}
      {...otherProps}
      disabled={disabled}
      okButtonProps={{ type: 'primary', danger: true }}
    >
      <Button
        type={type}
        icon={icon}
        size={size ?? 'small'}
        loading={loading}
        disabled={disabled}
      >
        {children}
      </Button>
    </Popconfirm>
  );
}
