import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowToStrategicReviewButtonProps {
  data: FlowDto;
}

export function FlowToStrategicReviewButton(
  props: IFlowToStrategicReviewButtonProps
) {
  const { data } = props;

  const [toStrategicReview] = useApiMutation(
    FlowApi.actions.toStrategicReview,
    {}
  );

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Inviare in approvazione?"
      modalContent="Il documento verrà presentato alla Direzione Strategica. Una volta inviato non sarà possibile apportare modifiche."
      sendButtonText="Invia in Approvazione"
      initialState={[FlowState.QualityCheck]}
      nextState={FlowState.StrategicReview}
      onSend={toStrategicReview}
    />
  );
}
