import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { FlowApi } from '../FlowApi';
import { useMemo } from 'react';
import { FlowDto } from 'common/dto/generated/FlowDto';

export function useFlow(id: number | null | undefined) {
  const { response, loading, error } = useApiQuery(FlowApi.detail, {
    skip: id == null,
    data: {
      id: id!
    }
  });

  const result = useMemo(() => {
    if (!response?.data) return null;
    const result = {
      ...response.data,
      documents: response.data.documents.sort((a, b) => a.version - b.version)
    };

    return new FlowDto(result);
  }, [response?.data]);

  return { result, loading, error };
}
