import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { ILabelWidth } from 'client/ui/form/field/ILabelWidth';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { PasswordInput } from 'client/ui/form/input/PasswordInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import {
  ISettingVariable,
  SettingVariableType
} from 'common/schema/settings/Settings';
import React from 'react';
import { SettingSwitchInput } from './SettingSwitchInput';

export interface ISettingInputSwitchComponentProps {
  variable: ISettingVariable;
}

export function SettingInputSwitchComponent(
  props: ISettingInputSwitchComponentProps
) {
  const { variable } = props;

  const labelWidth: ILabelWidth = 'width-160';

  switch (variable.type) {
    case SettingVariableType.Boolean:
      return (
        <FormFieldItem
          key={variable.key}
          name={variable.key}
          label={variable.label}
          component={SettingSwitchInput}
          defaultChecked={variable.default === 'true'}
          hideSuccess={true}
          labelWidth={labelWidth}
        />
      );
    case SettingVariableType.Password:
      return (
        <FormFieldItem
          key={variable.key}
          name={variable.key}
          label={variable.label}
          component={PasswordInput}
          placeholder={variable.label}
          defaultValue={variable.default ?? undefined}
          hideSuccess={true}
          autoComplete="off"
          labelWidth={labelWidth}
        />
      );
    case SettingVariableType.Number:
      return (
        <FormFieldItem
          key={variable.key}
          name={variable.key}
          label={variable.label}
          component={NumberInput}
          placeholder={variable.placeholder ?? variable.default ?? ''}
          defaultValue={
            variable.default == null || variable.default == ''
              ? undefined
              : Number(variable.default)
          }
          hideSuccess={true}
          labelWidth={labelWidth}
        />
      );
    case SettingVariableType.String:
    default:
      return (
        <FormFieldItem
          key={variable.key}
          name={variable.key}
          label={variable.label}
          component={TextInput}
          placeholder={variable.placeholder ?? variable.default ?? ''}
          defaultValue={variable.default ?? undefined}
          hideSuccess={true}
          labelWidth={labelWidth}
        />
      );
  }
}
