import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowRejectButtonProps {
  data: FlowDto;
}

export function FlowRejectButton(props: IFlowRejectButtonProps) {
  const { data } = props;

  const [reject] = useApiMutation(FlowApi.actions.reject, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Valutazione documento"
      modalContent="Il documento verrà approvato e inviato alla segreteria per la pubblicazione. Una volta approvato non sarà più possibile effettuare modifiche."
      initialState={[FlowState.Review, FlowState.StrategicReview]}
      nextState={FlowState.Rejected}
      sendButtonText="Non Approvare"
      sendButtonProps={{ type: 'primary', danger: true }}
      onSend={reject}
      hideIcon={true}
    />
  );
}
