import { assertNever } from 'server/utils/typings/assertNever';
import { FlowType } from '../../../../../common/schema/flow/FlowType';

/**
 * Ottiene la label del tipo di flusso
 */
export const getFlowTypeLabel = (type: FlowType) => {
  switch (type) {
    case FlowType.Documenti:
      return 'Documenti';
    case FlowType.ConsensiInformati:
      return 'Consensi Informati';
    case FlowType.Regolamenti:
      return 'Regolamenti';
  }
  assertNever(type);
};
