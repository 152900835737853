import { FlowDto } from 'common/dto/generated/FlowDto';
import { UserDto } from 'common/dto/generated/UserDto';
import { IDto } from 'common/dto/shared/DtoInterfaces';
import { Roles } from 'common/permissions/Roles';
import { DocumentTypology } from './DocumentTypology';
import { FlowState, priorityList } from './FlowState';
import { FlowType } from './FlowType';

export class FlowLogic {
  /**
   * Indica per quali stati il flusso è modificabile dall'utente
   */
  static getEditableStatesByRole(user: IDto<UserDto>) {
    switch (user.getRole().name) {
      case Roles.Compiler.name:
        return [FlowState.Draft, FlowState.Submitted];
      case Roles.QualitySecretary.name:
        return [FlowState.DocumentCheck, FlowState.Approved];
      case Roles.QualityManager.name:
        return [FlowState.QualityCheck];
      case Roles.RiskManager.name:
        return [FlowState.RiskCheck];
      case Roles.Approver.name:
        return [FlowState.Review];
      case Roles.StrategicDirector.name:
        return [FlowState.StrategicReview];
      default:
        return [];
    }
  }

  /**
   * Indica per quali stati il flusso è visibile all'utente
   */
  static getVisibleStatesByRole(user: IDto<UserDto>) {
    switch (user.getRole().name) {
      case Roles.Compiler.name:
        return [
          FlowState.Draft,
          FlowState.Submitted,
          FlowState.DocumentCheck,
          FlowState.QualityCheck,
          FlowState.RiskCheck,
          FlowState.Review,
          FlowState.StrategicReview,
          FlowState.Rejected,
          FlowState.Approved,
          FlowState.Published
        ];
      case Roles.QualitySecretary.name:
      case Roles.QualityManager.name:
      case Roles.RiskManager.name:
      case Roles.Approver.name:
      case Roles.StrategicDirector.name:
        return [
          FlowState.Submitted,
          FlowState.DocumentCheck,
          FlowState.QualityCheck,
          FlowState.RiskCheck,
          FlowState.Review,
          FlowState.StrategicReview,
          FlowState.Rejected,
          FlowState.Approved,
          FlowState.Published
        ];
      default:
        return [];
    }
  }

  static getFlowType(documentType: DocumentTypology): FlowType {
    switch (documentType) {
      case DocumentTypology.AnalisiProcesso:
      case DocumentTypology.MappaProcesso:
      case DocumentTypology.IstruzioneOperativa:
      case DocumentTypology.ProceduraOperativa:
      case DocumentTypology.PDTA:
      case DocumentTypology.PianoAziendale:
      case DocumentTypology.DocumentoOrganizzativo:
        return FlowType.Documenti;

      case DocumentTypology.ConsensoFoglioInformativo:
        return FlowType.ConsensiInformati;

      case DocumentTypology.Regolamento:
        return FlowType.Regolamenti;
    }
  }

  /**
   * Ritorna vero se il flow è in uno stato specifico. È possibile passare
   * un array di stati per verificare se il flow è in uno di questi.
   */
  static isState(flow: FlowDto, state: FlowState | FlowState[]) {
    if (Array.isArray(state)) {
      return state.includes(flow.state);
    }

    return flow.state === state;
  }

  /**
   * Ritorna vero se l'utente fa parte del gruppo di lavoro del flusso.
   */
  static isInWorkingGroup(flow: IDto<FlowDto>, user: IDto<UserDto>) {
    return Boolean(flow.workingGroup?.some(u => u.id === user.id));
  }

  /**
   * Ritona vero se l'utente è assegnato (presa in carico) al flusso.
   */
  static isAssigned(flow: IDto<FlowDto>, user: IDto<UserDto>) {
    return flow.workingUserId === user.id;
  }
}
