import React from 'react';
import { SelectOption } from 'client/ui/form/input/SelectInput';
import { DocumentTypologyList } from './DocumentTypologyList';

/**
 * Ritorna l'elenco delle tipologie di documenti come opzioni per un SelectInput
 */
export const getDocumentTypologySelectOptions = (): SelectOption[] => {
  return DocumentTypologyList.map(item => ({
    label: item.label,
    value: item.documentType
  }));
};
