import React from 'react';
import { Section } from 'client/ui/section/Section';
import { UserOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FormFieldLabel } from 'client/ui/form/field/FormFieldLabel';
import { useFormikContext } from 'formik';
import { getDocumentTypologyLabel } from '../../common/document/typology/getDocumentTypologyLabel';
import moment from 'moment';
import { WorkingGroupSelectInput } from 'client/components/user/WorkingGroupSelectInput';

export interface DocumentGeneralDataSectionProps {}
/**
 * Dati principali del documento
 */
export function FlowGeneralSection(props: DocumentGeneralDataSectionProps) {
  const formik = useFormikContext<FlowDto>();

  return (
    <Section
      icon={<UserOutlined />}
      title="Dati Generali"
      hasPadding

      // extra={
      //   <SectionButton
      //     onClick={e => {
      //       e.preventDefault();
      //       e.stopPropagation();
      //       formik.setFieldValue('documentType', null);
      //       formik.setFieldValue('flowType', null);
      //     }}
      //   >
      //     Cambia tipologia Documento
      //   </SectionButton>
      // }
    >
      <FormFieldsContainer>
        <FormFieldItem
          component={TextAreaInput}
          label="Oggetto"
          name="subject"
          placeholder="Oggetto"
          rows={3}
        />
        {/* <FormFieldItem
            component={TextAreaInput}
            label="Descrizione"
            name="description"
            placeholder="Descrizione"
            rows={4}
          /> */}
      </FormFieldsContainer>

      <FormFieldsContainer columns={4}>
        <FormFieldLabel colSpan={2} label="Tipo Documento">
          {getDocumentTypologyLabel(formik.values.documentType)}
        </FormFieldLabel>
        <FormFieldLabel inline label="Autore">
          {formik.values.owner?.name}
        </FormFieldLabel>
        <FormFieldLabel inline label="Data creazione">
          {moment(formik.values.createdAt).format('DD/MM/YYYY')}
        </FormFieldLabel>
      </FormFieldsContainer>

      <FormFieldsContainer>
        <FormFieldItem
          component={WorkingGroupSelectInput}
          mode="tags"
          label="Gruppo di lavoro"
          name="workingGroup"
          placeholder="Gruppo di lavoro"
        />
      </FormFieldsContainer>
    </Section>
  );
}
