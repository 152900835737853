import { apiClient } from 'client/core/network/apiClient';
import { UpdateUserEmailBodyDto } from 'common/dto/query/UpdateUserEmailBodyDto';
import { UserAuthoritiesDto } from 'common/dto/documental-bridge/UserAuthoritiesDto';
import { UserAuthoritiesSelectDto } from 'common/dto/documental-bridge/UserAuthoritiesSelectDto';
import { PaginatedUserDto, UserDto } from 'common/dto/generated/UserDto';
import { WorkingGroupQueryDto } from 'common/dto/query/WorkingGroupQueryDto';

export const UserApi = {
  findCurrentUser: (opts: {}) =>
    apiClient.requestWithResponseType(UserDto).get(`/users/current`),

  workingGroupList: (opts: { query: WorkingGroupQueryDto }) =>
    apiClient
      .requestWithResponseType(PaginatedUserDto)
      .get(`/users/compilers`, { params: opts.query }),

  updateEmail: (opts: { body: UpdateUserEmailBodyDto }) =>
    apiClient.request().put(`/users/email`, opts.body),

  getUserEmail: () =>
    apiClient
      .request()
      .get<{ email: string | null | undefined }>(`/users/email`),

  documental: {
    authorities: {
      list: (opts: {}) =>
        apiClient
          .requestWithResponseType(UserAuthoritiesDto)
          .get(`/users/current/documental/authorities`),
      set: (opts: { input: UserAuthoritiesSelectDto }) =>
        apiClient
          .request()
          .put(`/users/current/documental/authorities`, opts.input)
    }
  }
};
