import { apiClient } from 'client/core/network/apiClient';

/**
 * API per la gestione dell'assegnazione e rilascio di un flusso
 */
export const FlowAssignmentApi = {
  /** Presa in Carico */
  selfAssign: (opts: { id: number }) =>
    apiClient
      .request()
      .put<{ success: boolean }>(`/flows/${opts.id}/actions/self-assign`),
  /** Rilascio */
  release: (opts: { id: number }) =>
    apiClient
      .request()
      .put<{ success: boolean }>(`/flows/${opts.id}/actions/release`)
};
