import { SendOutlined } from '@ant-design/icons';
import { Space, Button, Row, Col, Typography, message } from 'antd';
import { DigitalSignatureInput } from 'client/components/digital-signature/DigitalSignatureInput';
import { base64 } from 'client/core/encoding/base64';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { DrawerActionsBar } from 'client/ui/actions-bar/DrawerActionsBar';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import {
  FlowApproveDataDto,
  FlowApproveDataSchema
} from 'common/dto/query/FlowApproveDataDto';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowApprovalModalSendButton } from './FlowApprovalModalSendButton';

const { Text } = Typography;

export interface IFlowApprovalModalProps {
  setVisible: (visible: boolean) => void;
  visible: boolean;
  documentId: number;
  flowId: number;
}

export function FlowApprovalModal(props: IFlowApprovalModalProps) {
  const { setVisible, visible, documentId, flowId } = props;

  const [approve] = useApiMutation(FlowApi.actions.approve, {
    invalidates: [FlowApi.detail]
  });

  const t = useFormikContext();

  return (
    <FormikAugmented<FlowApproveDataDto>
      initialValues={
        new FlowApproveDataDto({
          documentId
        })
      }
      enableReinitialize
      validationSchema={FlowApproveDataSchema}
      // Attivo la validazione del file così da assicurarci che sia presente
      validationContext={{ fileValidation: true }}
      onSubmit={async rawValues => {
        try {
          const values = await rawValues.validate();

          const { content, filename, ...otherValues } = values;
          const formData = new FormData();

          // Recupero il file firmato
          const signedFile = base64.toFile(content!, filename!);

          formData.append('signedDocument', signedFile);

          Object.keys(otherValues).forEach(key => {
            formData.set(key, get(values, key));
          });

          await approve({ data: { id: flowId, data: formData } });
          message.success(`Documento firmato e approvato correttamente`);
          setVisible(false);
        } catch (e) {
          console.error({ error: e });
          message.error(`Errore durante l'approvazione del documento. Riprovare.`); // prettier-ignore
        }
      }}
      validateOnBlur={true}
      validateOnChange={false}
    >
      <ModalDialog
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Firma e approva documento"
        footer={
          <DrawerActionsBar fixedWidht>
            <Space>
              <Button onClick={() => setVisible(false)} size="large">
                Annulla
              </Button>
              <FlowApprovalModalSendButton />
            </Space>
          </DrawerActionsBar>
        }
      >
        <FormikForm showVisualFeedback={true} editable={true} layout="vertical">
          <Row gutter={40}>
            <Col span={20} offset={2}>
              <VerticalSpace size={5} />
              <Text>
                Il documento firmato verrà approvato e inviato alla segreteria
                per la pubblicazione. Una volta approvato non sarà più possibile
                effettuare modifiche.
              </Text>
              <VerticalSpace size={20} />
              <FormFieldsContainer>
                <FormFieldItem
                  component={TextAreaInput}
                  label={null}
                  name="notes"
                  placeholder="Note per la segreteria..."
                  rows={4}
                />
              </FormFieldsContainer>
              <FormFieldsContainer>
                <FormFieldItem
                  component={DigitalSignatureInput}
                  documentId={documentId}
                  flowId={flowId}
                  fileNameFieldName="filename"
                  fileContentFieldName="content"
                  label={null}
                  name="signature"
                  hideSuccess={true}
                />
              </FormFieldsContainer>
            </Col>
          </Row>
        </FormikForm>
      </ModalDialog>
    </FormikAugmented>
  );
}
