// import '@babel/polyfill';
// import 'whatwg-fetch';

import 'reflect-metadata';
import 'antd/dist/antd.less';

// Polyfill
import 'ts-polyfill/lib/es2015-core';
import 'ts-polyfill/lib/es2015-collection';
import 'ts-polyfill/lib/es2015-iterable';
import 'ts-polyfill/lib/es2015-promise';
import 'ts-polyfill/lib/es2015-reflect';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'core-js/es/symbol';

// Locale
import it_IT from 'antd/lib/locale-provider/it_IT';
import 'common/validation/initYup';
import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'client/core/store/configureReduxStore';
import { Routes } from './routes/Routes';
import { ConfigProvider } from 'antd';
import { NetworkProvider } from './core/network/context/NetworkContext';
import { networkState } from './core/network/context/configureNetworkState';
import { BreadcrumbProvider } from './core/router/breadcrumb/BreadcrumbContext';
import { appConfig } from './core/appConfig';

import './ui/AntStyles.scss';
import { LoadingScreenContextProvider } from './ui/loading-screen/LoadingScreenContext';

export function App() {
  return (
    <BrowserRouter basename={appConfig.publicPath}>
      <Provider store={store}>
        <ConfigProvider locale={it_IT}>
          <NetworkProvider value={networkState}>
            <BreadcrumbProvider>
              <LoadingScreenContextProvider>
                <Routes />
              </LoadingScreenContextProvider>
            </BreadcrumbProvider>
          </NetworkProvider>
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
