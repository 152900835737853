import { Radio } from 'antd';
import { ReactState } from 'client/core/hooks/ReactState';
import React from 'react';

export interface ILoginRadioAuthTypeProps {
  authTypeState: ReactState<'ldap' | 'local'>;
  defaultAuthType?: 'ldap' | 'local';
}

/**
 * Componente che permette la scelta del tipo di autenticazione da utilizzare
 * per il login
 */
export function LoginRadioAuthType(props: ILoginRadioAuthTypeProps) {
  const { authTypeState, defaultAuthType } = props;
  const [authType, setAuthType] = authTypeState;
  return (
    <div style={{ margin: '10px 0' }}>
      <Radio.Group
        defaultValue={defaultAuthType ?? 'ldap'}
        buttonStyle="solid"
        value={authType}
        onChange={value => {
          setAuthType(value.target.value);
        }}
      >
        <Radio value="ldap">LDAP</Radio>
        <Radio value="local">Locale</Radio>
      </Radio.Group>
    </div>
  );
}
