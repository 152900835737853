import * as React from 'react';
import Select, { OptionProps, SelectProps } from 'antd/lib/select';
import {
  FormikValues,
  useField,
  useFormikContext,
  FieldInputProps,
  FieldHelperProps,
  FormikContextType
} from 'formik';
import {
  ApiGet,
  ApiQueryOptions,
  useApiQuery
} from 'client/core/network/hooks/useApiQuery';
import { Modal, Spin } from 'antd';
import { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import { AsyncSelectInputMeta } from './AsyncSelectInputMeta';
import { useFormikFormContext } from '../FormikFormContext';
import { SelectOption } from './SelectInput';

type Item<R extends Array<any>> = R extends Array<infer I> ? I : never;

type AsyncSelectQueryFn<T extends FormikValues, A, R> = (
  formik: FormikContextType<T>,
  search?: string | undefined
) => ApiQueryOptions<A, R>;

/**
 * Dati aggiuntivi visualizzabili nella select.
 */
export type AsyncSelectMeta = {
  shown: number;
  total: number;
};

export interface AsyncSelectInputProps<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
> extends SelectProps<any> {
  name: string;
  /** Permette di caricare con una query le opzioni. */
  query: {
    apiFn: ApiGet<A, R>;
    options: ApiQueryOptions<A, R> | AsyncSelectQueryFn<T, A, R>;
  };
  refreshOnSearch?: boolean;
  shouldConfirmOnChange?: () => boolean;
  confirmOnChangeMessage?: string;
  responseTransform?: (response: R) => O;
  responseMeta?: (response: R) => AsyncSelectMeta;
  optionTransform: (option: Item<O>) => SelectOption;
  /** Gestisce impostazioni aggiuntive alla selezione */
  onAfterSelect?: (
    option: Item<O> | undefined,
    helpers: FieldHelperProps<T | null | undefined>
  ) => void;
  /**
   * Trasforma i value del componente Select (string | number) permettendo di gestire
   * i valori come oggetti nel formikField. ex.
   * {
   *   from: (value) => value.id,
   *   to: (value) => ({id: value})
   * }
   * permette di avere oggetti {id: number} come value del formikField.
   */
  valueTransform?: {
    from: (value: T | null | undefined) => any;
    to: (value: any, options?: Item<O>[]) => T | null | undefined;
  };
  /**
   * Messaggio mostrato quando non ci sono risultati.
   * Defalt: "Nessun elemento trovato."
   */
  notFoundMessage?:
    | string
    | ((search: string | undefined) => string | undefined);
}

type InferArray<T> = T extends any[] ? T : never;

/**
 * Select collegata direttamente a Formik.
 */
// TODO: Gestire errori
export function AsyncSelectInput<
  T extends FormikValues,
  A,
  R,
  O extends Array<any>
>(props: AsyncSelectInputProps<T, A, R, O>) {
  const {
    name,
    query,
    responseTransform,
    responseMeta,
    optionTransform,
    refreshOnSearch,
    onAfterSelect,
    confirmOnChangeMessage,
    shouldConfirmOnChange,
    valueTransform,
    notFoundMessage: optionNotFoundMessage,
    ...otherProps
  } = props;
  const confirmOnChange = shouldConfirmOnChange ?? (() => false);
  const [field, meta, helpers] = useField<Maybe<T>>(name);
  const [search, setSearch] = useState(undefined as string | undefined);
  const formik = useFormikContext<T>();
  const { response, loading, error } = useApiQuery(
    query.apiFn,
    typeof query.options === 'function'
      ? query.options(formik, search)
      : query.options
  );

  const { disabled } = useFormikFormContext();

  const responseTransformFn = responseTransform ?? (i => (i as unknown) as O);
  const valueTransformFrom =
    valueTransform?.from ?? ((i: T | null | undefined) => i);
  const valueTransformTo = valueTransform?.to ?? ((i, options) => i as any);

  // Ricerca
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 200),
    [refreshOnSearch]
  );

  const transformedResponse =
    (response?.data && responseTransformFn(response?.data)) ??
    (([] as unknown) as O);

  // Creo le opzioni associate all`'item'` in modo da poterlo passare
  // alla callback `onSelect`
  const options = transformedResponse.map((item: Item<O>) => ({
    option: optionTransform(item),
    item
  }));

  // ModalConfirm
  const createModalConfirm = useCallback(
    (onOk: () => void) => {
      return Modal.confirm({
        title: 'Attenzione',
        content:
          confirmOnChangeMessage ??
          'Sei sicuro di voler svolgere questa operazione?',
        onOk
      });
    },
    [confirmOnChangeMessage]
  );

  // Chiamata quando viene modificata la scelta della select
  const onChangeValue = useCallback(
    (value: any, nextValue: any) => {
      helpers.setValue(valueTransformTo(nextValue, transformedResponse));
      if (search) setSearch(undefined); // Altrimenti rimarrebbe il filtro attivo
      if (onAfterSelect) {
        const option = options.find(o => o.option.value === value);
        onAfterSelect(option?.item, helpers);
      }
    },
    [options, helpers, transformedResponse, search, onAfterSelect]
  );

  const notFoundMessage =
    typeof optionNotFoundMessage == 'string'
      ? optionNotFoundMessage
      : optionNotFoundMessage?.(search);

  // Informo che esistono altri dati
  return (
    <Select<any>
      {...otherProps}
      disabled={otherProps.disabled || disabled}
      loading={loading}
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : (
          notFoundMessage ?? 'Nessun elemento trovato.'
        )
      }
      value={valueTransformFrom(field.value)}
      filterOption={!refreshOnSearch}
      onSearch={refreshOnSearch ? handleSearch : undefined}
      onChange={value => {
        const nextValue = value == undefined ? null : value;
        // Non apre la modal se la Select è vuota (undefined)
        if (confirmOnChange() && field.value != undefined) {
          createModalConfirm(() => onChangeValue(value, nextValue));
        } else {
          onChangeValue(value, nextValue);
        }
        helpers.setTouched(true, true);
      }}
      dropdownRender={menu => (
        <div>
          {menu}
          <AsyncSelectInputMeta
            meta={
              responseMeta && response ? responseMeta(response.data) : undefined
            }
          />
        </div>
      )}
    >
      {options.map(({ option, item }) => {
        return (
          <Select.Option key={option.value} {...option}>
            {option.label}
          </Select.Option>
        );
      })}
    </Select>
  );
}
