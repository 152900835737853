import { Button } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import React from 'react';
import { FlowApi } from '../FlowApi';
import { FlowAssignmentApi } from '../FlowAssignmentApi';

export interface IFlowSelfAssignButtonProps {
  flow: FlowDto | null | undefined;
}

export function FlowSelfAssignButton(props: IFlowSelfAssignButtonProps) {
  const { flow } = props;

  const user = useCurrentUser();

  const { allowed: canAssign } = FlowMachine.can(flow, user, 'selfAssign');

  const [selfAssign] = useApiMutation(FlowAssignmentApi.selfAssign, {
    data: { id: flow?.id },
    invalidates: [FlowApi.detail]
  });

  const handleAssign = () => {
    selfAssign({});
  };

  if (!user) return null;
  if (!canAssign) return null;
  if (!flow) return null;

  return (
    <Button type="primary" size="large" onClick={handleAssign}>
      Prendi in Carico
    </Button>
  );
}
