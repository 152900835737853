import { AxiosInstance } from 'axios';
import { store } from 'client/core/store/configureReduxStore';
import { AuthActions } from 'client/components/auth/AuthModule';
import { AuthApi } from 'client/components/auth/AuthApi';
import { message } from 'antd';
import { logger } from 'client/core/logger/logger';

type Subscriber = (err: null | Error) => void;

export function refreshTokenInterceptor(axios: AxiosInstance) {
  let isRefreshing = false;

  /**
   * Insieme delle richieste in attesa.
   */
  let subscribers: Subscriber[] = [];

  const subscribeTokenRefresh = (sub: Subscriber) => subscribers.push(sub);
  const onTokenRefreshed = (err: null | Error) => {
    subscribers.forEach(subscriber => subscriber(err));
    subscribers = [];
    isRefreshing = false;
  };

  axios.interceptors.response.use(undefined, error => {
    const { config, response } = error;
    const originalConfig = config;

    if (response == null || response.status !== 401) {
      return Promise.reject(error);
    }

    /**
     * Se non si è loggati, non si può tentare il refresh. Allo stesso modo,
     * se l'autenticazione è via Windows, non c'è un token.
     */
    const { isLogged, current: currentUser, strategy } = store.getState().auth;
    logger.log(`[Auth/refresh] Utente ${currentUser?.name ?? '<NA>'} - tentativo di refresh del token (401)`); // prettier-ignore

    if (currentUser && currentUser.strategy !== 'jwt') {
      store.dispatch(AuthActions.logout());
      message.error(
        `La sessione è scaduta. Si prega di effettuare nuovamente l'accesso.`
      );
      return Promise.reject(error);
    }

    if (!isLogged || (isLogged && strategy !== 'jwt')) {
      if (isLogged) store.dispatch(AuthActions.logout());
      return Promise.reject(error);
    }

    /**
     * In caso di errore per utente non autenticato, significa che l'`accessToken`
     * è scaduto. In questo caso, prima di mostrare nuovamente la pagina di login
     * all'utente andiamo a tentare l'aggiornamento mediante il `refreshToken`.
     */
    if (!isRefreshing) {
      isRefreshing = true;

      /**
       * Registriamo il token come scaduto all'interno dello store.
       * Al momento non viene utilizzato, ma potrebbe essere utile.
       * Nota: non effettuiamo il logout perché altrimenti verrebbero
       * applicati i redirect di `react-router`
       */
      store.dispatch(AuthActions.tokenExpired());

      AuthApi.refreshToken()
        .then(res => {
          /**
           * L'aggiornamento del token ha avuto successo.
           * Registro il nonce sullo store di redux, per sapere in generale
           * che l'utente è ora autenticato.
           */
          store.dispatch(AuthActions.tokenRefreshed('jwt', res.data.nonce));

          /**
           * Aggiorno il token per le richiesta con il token scaduto.
           */
          onTokenRefreshed(null);
        })
        .catch(err => {
          console.error('FORCED LOGOUT', err);
          /**
           * Se il refreshToken non è più valido effettuiamo il logout.
           */
          // TODO/LOW Salvare l'errore e mostrarlo nell'interfaccia, possibilmente sopra il modulo di login
          store.dispatch(AuthActions.logout());

          onTokenRefreshed(err);
        });
    }

    /**
     * Inseriamo la richiesta nella coda.
     */
    return new Promise((resolve, reject) => {
      subscribeTokenRefresh(err => {
        if (err) return reject(err);

        return resolve(axios({ ...config }));
      });
    });
  });
}
