import { Button, message, Modal } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FlowApi } from '../FlowApi';

export interface IFlowDeleteButtonProps {
  flow: FlowDto | null | undefined;
}

/**
 * Pulsante eliminazione flusso. Visibile solo ai medici per i flussi in bozza
 */
export function FlowDeleteButton(props: IFlowDeleteButtonProps) {
  const { flow } = props;

  const [deleteFlow, { loading }] = useApiMutation(FlowApi.delete, {});
  const history = useHistory();
  const user = useCurrentUser();

  const handleDelete = useCallback(
    async (id: number) => {
      Modal.confirm({
        title: 'Sei sicuro di voler eliminare questo documento?',
        content: 'Questa operazione non può essere annullata.',
        okText: 'Elimina',
        okType: 'danger',
        cancelText: 'Annulla',
        onOk: async () => {
          try {
            await deleteFlow({ data: { id } });
            message.success('Documento eliminato correttamente.');
            history.push('/flows');
          } catch (e) {
            message.error('Errore durante l\'eliminazione del documento. Riprovare.'); // prettier-ignore
          }
        }
      });
    },
    [deleteFlow]
  );

  if (flow?.id == null) {
    return null;
  }

  if (!user) {
    return null;
  }

  if (!FlowMachine.can(flow, user, 'delete').allowed) {
    return null;
  }

  return (
    <Button
      type="ghost"
      danger
      onClick={() => handleDelete(flow.id)}
      loading={loading}
      size="large"
    >
      Elimina
    </Button>
  );
}
