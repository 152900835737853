/**
 * Lista di formati accettati come documento del flusso
 */
export const flowAcceptedFileFormat = [
  '.doc',
  '.docx',
  '.odt',
  '.txt',
  '.rtf',
  '.csv',
  '.xls',
  '.xlsx'
];

/**
 * Lista di formati accettati come documento del flusso
 * in versione stringa separata da virgola.
 */
export const flowAcceptedFileFormatString = flowAcceptedFileFormat.join(',');
