import { Collapse } from 'antd';
import clx from 'classnames';
import { SectionTitle } from './SectionTitle';
import * as React from 'react';
import './Section.scss';
import { CollapsibleType } from 'antd/lib/collapse/CollapsePanel';

const { Panel } = Collapse;

export interface ISectionProps {
  /** Titolo della sezione */
  title: React.ReactNode;
  /** Icona di intestazione */
  icon?: React.ReactNode;
  /** Contenuto aggiuntivo dell'intestazione, in alto a destra */
  extra?: React.ReactNode;
  /** Contenuto */
  children?: React.ReactNode;
  /** Specifica se ha un padding */
  hasPadding?: boolean;
  /** Se può essere collassata */
  collapsible?: CollapsibleType;
  /** Specifica che la sezione è bloccata
   * ovvero: come collapsible='disabled' ma senza modificare
   * lo stile grafico dell'header */
  blocked?: boolean;
  /** Specifica se il pannello deve essere "chiuso"
   * all'apertura della pagina */
  collapsed?: boolean;
}

/**
 * Una sezione in cui viene visualizzata parte dei dati di una risorsa.
 */
export function Section(props: ISectionProps) {
  const collapsible = props.blocked ? 'disabled' : props.collapsible;
  return (
    <Collapse
      defaultActiveKey={props.collapsed ? [] : ['general']}
      className={clx('section', { 'section-blocked': props.blocked })}
      bordered={false}
      collapsible={collapsible}
    >
      <Panel
        header={<SectionTitle icon={props.icon} title={props.title} />}
        key="general"
        extra={props.extra}
      >
        <div style={{ width: '100%', padding: props.hasPadding ? '14px' : 0 }}>
          {props.children}
        </div>
      </Panel>
    </Collapse>
  );
}
