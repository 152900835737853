import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowToRiskCheckButtonProps {
  data: FlowDto;
}

export function FlowToRiskCheckButton(props: IFlowToRiskCheckButtonProps) {
  const { data } = props;

  const [toRiskCheck] = useApiMutation(FlowApi.actions.toRiskCheck, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Inviare al controllo qualità?"
      modalContent="Il documento verrà presentato al Risk Manager. Una volta inviato non sarà possibile apportare modifiche."
      sendButtonText="Invia in Approvazione"
      initialState={FlowState.DocumentCheck}
      nextState={FlowState.RiskCheck}
      onSend={toRiskCheck}
    />
  );
}
