import React from 'react';
import { Space } from 'antd';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { useFormikContext } from 'formik';
import { SelectDocumentTypeCard } from './sections/SelectDocumentTypeCard';
import { FlowGeneralSection } from './sections/FlowGeneralSection';
import { SlideEffect } from 'client/ui/slide/SlideEffect';
import { DocumentFileSection } from './sections/DocumentFileSection';
import { DocumentQualityDataSection } from './sections/DocumentQualityDataSection';
import { RevisionAlertSection } from './sections/RevisionAlertSection';
import { FlowDto } from 'common/dto/generated/FlowDto';

export interface FlowPageSectionsProps {}

/**
 * Corpo della pagina contenete le sezioni con i dati del processo (dati Documento)
 */
export function FlowPageSections(props: FlowPageSectionsProps) {
  const {} = props;

  const formik = useFormikContext<FlowDto>();

  // TODO gestire meglio la visibilità della prima card e dell'animazione
  const hasDocumentType = !!formik.values.documentType;

  return (
    <PageContent fixedWidht>
      {!hasDocumentType && (
        <SlideEffect visible={!hasDocumentType}>
          <SelectDocumentTypeCard />
        </SlideEffect>
      )}
      <SlideEffect visible={hasDocumentType}>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          <RevisionAlertSection />
          <FlowGeneralSection />
          <DocumentQualityDataSection />
          <DocumentFileSection />
        </Space>
      </SlideEffect>
    </PageContent>
  );
}
