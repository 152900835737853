import { Switch, SwitchProps } from 'antd';
import { useFormikFormContext } from 'client/ui/form/FormikFormContext';
import { FormikValues, useField, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

export interface ISettingSwitchInputProps<T extends FormikValues>
  extends Omit<SwitchProps, 'name'> {
  name: keyof T & string;
}

/**
 * Come uno switchInput, ma gestisce i campi come campi string "true" o "false".
 */
export function SettingSwitchInput<T extends FormikValues>(
  props: ISettingSwitchInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField<string>(name);
  const formik = useFormikContext<T>();
  const { disabled } = useFormikFormContext();
  const handleChange = useCallback(
    (value: boolean) => {
      formik.setFieldValue(name, value ? 'true' : 'false');
      setTimeout(() => {
        formik.setFieldTouched(name, true, true);
      }, 0);
    },
    [name, formik.setFieldTouched, formik.setFieldValue]
  );

  return (
    <Switch
      {...props}
      {...field}
      disabled={props.disabled || disabled}
      checked={field.value ? field.value === 'true' : undefined}
      onChange={handleChange}
      style={{ width: 'auto' }}
    />
  );
}
