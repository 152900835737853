import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe FlowDocument definita in: src/server/schema/flow/flow-document/FlowDocument.entity.ts
 * Hash: 337990d2f267c2bef394f79a4061572c
 */
@ValidationSchema(() => FlowDocumentSchema)
export class FlowDocumentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Percorso documento' })
  filePath!: string;
  @ApiProperty({ type: String, description: 'Nome file' })
  filename!: string;
  @ApiProperty({ required: false, type: Number, description: 'Versione' })
  version!: number;
  @ApiProperty({ required: false, type: Boolean, description: 'Firmato' })
  signed!: boolean;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id Flusso' })
  flowId!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<FlowDocumentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof FlowDocumentDto ? values : plainToClass(FlowDocumentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await FlowDocumentSchema.validate(classToPlain(this), options);
    return new FlowDocumentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO FlowDocumentDto */
export type IFlowDocumentType = IDto<FlowDocumentDto>;

/**
 * DTO Paginato della classe FlowDocument
 */
export class PaginatedFlowDocumentDto {
  @ApiProperty({ type: [FlowDocumentDto] })
  @Type(() => FlowDocumentDto)
  items!: FlowDocumentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const FlowDocumentSchema = yup
  .object({
    id: yup.number(),
    version: yup.number().default(1).label('Versione'),
    signed: yup.boolean().default(false).label('Firmato'),
    flowId: yup.number().label('Id Flusso')
  })
  .noUnknown()
  .meta({ schemaName: "FlowDocumentSchema" })
  .required();
