import { PermissionsType } from './Permissions';
import { Role } from './Role';

const SuperAdmin = new Role('SuperAdmin', 'Super Amministratore', [
  'current-user.read',
  'settings.read',
  'settings.write',
  'template.write',
  'instruction.write'
]);

const DocumentalUser = new Role('DocumentalUser', 'Utente Documentale', [
  'current-user.read',
  'settings.read:public'
]);

/**
 * Utente generico senza ruolo
 */
const GenericUser = new Role('GenericUser', 'Utente Generico', [
  'current-user.read',
  'settings.read:public'
]);

// Medico Redattore
const Compiler = new Role('Compiler', 'Redattore', [
  'current-user.read',
  'flow.create',
  'flow.read:own',
  'flow.write',
  'flow.delete:own',
  'compilers.read',
  'flow.self-assign'
]);

const QualitySecretary = new Role('QualitySecretary', 'Segretaria Qualità', [
  'current-user.read',
  'flow.read',
  'flow.write',
  'settings.read:public',
  'compilers.read'
]);

const QualityManager = new Role('QualityManager', 'Responsabile Qualità', [
  'current-user.read',
  'flow.read',
  'flow.write',
  'settings.read:public',
  'compilers.read'
]);

const RiskManager = new Role('RiskManager', 'Risk Manager', [
  'current-user.read',
  'flow.read',
  'flow.write',
  'settings.read:public',
  'compilers.read'
]);

const Approver = new Role('Approver', 'Direttore', [
  'current-user.read',
  'flow.read',
  'flow.write',
  'settings.read:public',
  'compilers.read'
]);

const StrategicDirector = new Role(
  'StrategicDirector',
  'Direzione Strategica',
  [
    'current-user.read',
    'flow.read',
    'flow.write',
    'settings.read:public',
    'compilers.read'
  ]
);

export const Roles = {
  SuperAdmin,
  DocumentalUser,
  GenericUser,
  Compiler,
  QualitySecretary,
  QualityManager,
  RiskManager,
  Approver,
  StrategicDirector
};

export const RoleOptions = Object.keys(Roles).map(role => ({
  label: Roles[role as RoleName].displayName,
  value: Roles[role as RoleName].name
}));

export type RoleName = keyof typeof Roles;
