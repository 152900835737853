/**
 * Encoding e decoding Base64 con supporto unicode.
 * See https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 */
export const base64 = {
  encode(plain: string) {
    return btoa(
      encodeURIComponent(plain).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(parseInt(p1, 16));
        }
      )
    );
  },
  decode(encoded: string) {
    return decodeURIComponent(
      atob(encoded)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  },

  toFile(content: string, filename: string, filetype?: string) {
    const base64Content = content;
    const blob = convertBase64ToBlob(base64Content);
    return new File([blob], filename, {
      type: filetype ?? 'application/octet-stream'
    });
  }
};

// Converte un file da base64 a blob.
// In questo modo è possibile poi utilizzare come `File` e inviarlo al BE in un FormData per esempio.
// https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
function convertBase64ToBlob(fileBase64: string) {
  // Decode Base64 string
  const decodedData = atob(fileBase64);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array]);
}
