import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowNoSignApproveButtonProps {
  data: FlowDto;
}

/**
 * Pulsante di approvazione che non richiede la firma del file per il completamento dell'azione
 */
export function FlowNoSignApproveButton(props: IFlowNoSignApproveButtonProps) {
  const { data } = props;

  const [approve] = useApiMutation(FlowApi.actions.approveWithoutSign, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Valutazione documento"
      modalContent="Il documento verrà approvato e inviato alla segreteria per la pubblicazione. Una volta approvato non sarà più possibile effettuare modifiche."
      initialState={[FlowState.Review, FlowState.StrategicReview]}
      nextState={FlowState.Approved}
      sendButtonText="Approva"
      onSend={approve}
    />
  );
}
