import React from 'react';
import { Button, Space } from 'antd';
import { AuthLink } from 'client/core/router/links/AuthLink';
import { DownloadOutlined, FileUnknownOutlined } from '@ant-design/icons';
import './FileDownloadLink.scss';
import { DocumentTemplateDto } from 'common/dto/generated/DocumentTemplateDto';
import { DocumentInstructionDto } from 'common/dto/generated/DocumentInstructionDto';
import { FlowApi } from 'client/components/schema/flow/FlowApi';
import { ConfigDocumentDeleteButton } from './ConfigDocumentDeleteButton';

export interface IConfigDocumentDownloadLinkProps {
  file: DocumentTemplateDto | DocumentInstructionDto | undefined | null;
  type: 'instruction' | 'template';
}

/**
 * Visualizzazione del Nome del File e Download
 */
export function ConfigDocumentDownloadLink(
  props: IConfigDocumentDownloadLinkProps
) {
  const { file, type } = props;

  if (!file) {
    return (
      <Space align="center" size={4} className="file-download-link empty">
        <FileUnknownOutlined />
        Non presente
      </Space>
    );
  }

  const downloadLink =
    type === 'instruction'
      ? FlowApi.instructions.downloadLink({ type: file.documentType })
      : FlowApi.templates.downloadLink({ type: file.documentType });

  return (
    <Space align="center" className="file-download-link">
      {file.filename}
      <Space align="center" className="file-download-actions">
        <AuthLink href={downloadLink}>
          <a>
            <Button
              size="small"
              icon={<DownloadOutlined />}
              type="primary"
              ghost
            />
          </a>
        </AuthLink>
        <ConfigDocumentDeleteButton file={file} type={type} />
      </Space>
    </Space>
  );
}
