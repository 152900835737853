import { Affix, PageHeader, PageHeaderProps } from 'antd';
import clx from 'classnames';

import * as React from 'react';
import { useState } from 'react';
import { PageHeading } from '../page/heading/PageHeading';

export interface PageHeadingBrianzaProps extends PageHeaderProps {
  children?: React.ReactNode;
  steps?: React.ReactNode;
  borderBottom?: boolean;
}

/**
 * Intestazione della singola pagina
 */
export function PageHeadingBrianza(props: PageHeadingBrianzaProps) {
  const { steps, borderBottom, ...otherProps } = props;
  return (
    <>
      <PageHeading
        affix
        {...otherProps}
        borderBottom={steps ? false : borderBottom === false ? false : true}
      />
      {steps && <PageHeading style={{ paddingTop: 0 }}>{steps}</PageHeading>}
    </>
  );
}
