/**
 * Stato del Documento
 */
export enum FlowState {
  // medico | proponente
  Draft = 'Draft',
  Submitted = 'Submitted',

  // segreteria qualità
  DocumentCheck = 'DocumentCheck',

  // Responsabile Qualità
  QualityCheck = 'QualityCheck',

  // RiskManager
  RiskCheck = 'RiskCheck',

  // Direttore Responsabile
  Review = 'Review',

  // Direzione Strategica
  StrategicReview = 'StrategicReview',

  // Segreteria qualità
  Approved = 'Approved',

  // stati finali
  Rejected = 'Rejected',
  Published = 'Published'
}

/**
 * Ordine di priorità degli stati del flusso
 */
export const priorityList = [
  // 1
  FlowState.Draft,
  FlowState.Submitted,
  // 2
  FlowState.DocumentCheck,
  // 3
  FlowState.QualityCheck,
  FlowState.RiskCheck,
  // 4
  FlowState.Review,
  FlowState.StrategicReview,
  // 5
  FlowState.Approved,
  FlowState.Rejected,
  // 6
  FlowState.Published
];
