import { resolveApiPath } from 'client/core/appConfig';
import { apiClient } from 'client/core/network/apiClient';

export const FlowDocumentApi = {
  downloadLink: (opts: { documentId: number; flowId: number }) =>
    resolveApiPath(
      `/flows/${opts.flowId}/flow-documents/${opts.documentId}/download`
    ),

  getFile: (opts: { documentId: number; flowId: number }) =>
    apiClient
      .request()
      .get<{ filename: string; content: string }>(
        `/flows/${opts.flowId}/flow-documents/${opts.documentId}/file`
      )
};
