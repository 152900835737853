import { Spin } from 'antd';
import * as React from 'react';
import { useLoadingScreenContext } from './LoadingScreenContext';
import './LoadingScreen.scss';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export interface ILoadingScreenProps {}

export function LoadingScreen(props: ILoadingScreenProps) {
  const context = useLoadingScreenContext();
  if (!context.shown) return null;
  return (
    <div className="loading-screen">
      <div className="loading-screen-content">
        <Spin
          style={{ color: 'white' }}
          indicator={<Loading3QuartersOutlined spin />}
          tip={context.message}
        />
      </div>
    </div>
  );
}
