import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, List, Space, Timeline } from 'antd';
import { Section } from 'client/ui/section/Section';
import { ArrowRightOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import { useFormikContext } from 'formik';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowEventTypes, getTextFromEventType } from './getTextFromEventType';
import { ISODate } from 'client/ui/display/date/ISODate';
import { FlowStateTag } from 'client/components/schema/flow/tags/FlowStateTag';
import { FlowState } from 'common/schema/flow/FlowState';
import moment from 'moment';

export interface IFlowHistorySectionProps {}
/**
 * Cronologia degli eventi del flusso di lavoro
 */
export function FlowHistorySection(props: IFlowHistorySectionProps) {
  const flowData = useFormikContext<FlowDto>();
  // Ordino gli event log per data di creazione decrescente
  const events = flowData.values.eventLogs.sort((a, b) =>
    moment(b.createdAt).diff(moment(a.createdAt))
  );

  return (
    <Section
      icon={<ClockCircleOutlined />}
      title="Cronologia del flusso di lavoro"
      hasPadding
    >
      <div
        style={{ padding: '24px', paddingLeft: '30px', marginBottom: '-40px' }}
      >
        <Timeline>
          {events.map(event => (
            <Timeline.Item key={event.id}>
              <span style={{ minWidth: '140px', display: 'inline-block' }}>
                <ISODate date={event.createdAt} format="DD/MM/YYYY kk:mm" />
              </span>
              {getTextFromEventType(event.type)}{' '}
              {event.toState && (
                <>
                  <ArrowRightOutlined />{' '}
                  <FlowStateTag state={event.toState as FlowState} />
                </>
              )}{' '}
              ({event?.user ? event.user.name : 'Nome utente non disponibile'})
              {event.notes && (
                <div style={{ paddingLeft: '140px' }}>
                  <em>{event.notes}</em>
                </div>
              )}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </Section>
  );
}
