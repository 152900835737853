// import { IUserType } from 'common/dto/generated/UserDto';
import { UserDto } from 'common/dto/generated/UserDto';
import { PermissionsType } from './Permissions';

/**
 * Gestisce la logica di controllo dei permessi.
 */
export class PermissionLogic {
  /**
   * Controlla se il ruolo dell'utente da accesso al permesso richiesto.
   * In caso di più permessi,  controlla se il ruolo dell'utente
   * dà accesso ad **ALMENO UNO** dei permessi richiesti
   */
  static has(
    user: Maybe<UserDto>,
    permission: PermissionsType | PermissionsType[]
  ) {
    if (!user) return false;
    return user.getRole().hasPermission(permission);
  }
}
