import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Divider, List, Space } from 'antd';
import {
  DownloadOutlined,
  FileProtectOutlined,
  FileTextOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { FileListItem } from 'client/ui/file-list/FileListItem';
import { FileListItemTitle } from 'client/ui/file-list/FileListItemTitle';
import { FileReviewListItem } from 'client/ui/file-list/FileReviewListItem';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowDocumentDto } from 'common/dto/generated/FlowDocumentDto';
import { FormikContextType } from 'formik';
import { IDto } from 'common/dto/shared/DtoInterfaces';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { FlowDocumentLogic } from 'common/schema/flow/flow-document/FlowDocumentLogic';
import { useFormikAugmentedContext } from 'client/ui/form/FormikAugmentedContext';
import { useFormikFieldValidation } from 'client/ui/form/hooks/validation/useFormikFieldValidation';
import { AuthLink } from 'client/core/router/links/AuthLink';
import { FlowDocumentApi } from 'client/components/schema/flow/FlowDocumentApi';
import { ReviewDocumentApi } from 'client/components/schema/flow/ReviewDocumentApi';

export interface DocumentListItemProps {
  document?: IDto<FlowDocumentDto> | null | undefined;
  // inserito per l'aggiornamento del file (upload)
  uploadArea?: React.ReactNode;
  // indica se è visualizzato nella storia delle versioni
  isHistory?: boolean;

  flowData: IDto<FlowDto>;
}
/**
 * Elemento per visualizzare un singolo Documento della lista
 *
 */
export function DocumentListItem(props: DocumentListItemProps) {
  const { document, uploadArea, isHistory, flowData } = props;
  // apertura del pannello upload file (aggiornamento) interno al componente
  const [showUpload, setShowUpload] = useState(false);
  const user = useCurrentUser();
  const validation = useFormikFieldValidation('file');

  const documentEventReviews = FlowDocumentLogic.getReviews(
    flowData,
    document?.id
  );

  const hasReview = documentEventReviews.length > 0;

  if (!user) return null;

  const { allowed: canUpload } = FlowMachine.can(
    flowData,
    user,
    'uploadFlowDocument'
  );
  const { allowed: canReadOnly } = FlowMachine.can(
    flowData,
    user,
    'readOnlyFlowDocument'
  );
  const showUploadComponent = canUpload && !isHistory && validation.editable;

  if (!document && !canUpload) {
    return (
      <Alert
        description="Non è ancora stato caricato nessun doumento. È necessario che l'utente responsabile o un componente del Gruppo di Lavoro prendano in carico il flusso per procedere con l'upload del documento."
        showIcon
        message="Nessun documento caricato"
        type="info"
      />
    );
  }

  if (!document && showUploadComponent) {
    return <>{uploadArea}</>;
  }

  if (!document) return null;

  return (
    <div>
      {canReadOnly && !isHistory && (
        <div style={{ marginBottom: '16px' }}>
          {flowData.workingUser?.name ? (
            <Alert
              description={`Il documento in carico all'utente ${flowData.workingUser?.name} ed è disponibile in sola lettura. Per poterlo modificare è necessario che l'utente che ha in carico l'attività o l'utente responsabile rilascino il documento al Gruppo di Lavoro.`}
              showIcon
              message={`Documento disponibile in sola lettura`}
              type="info"
            />
          ) : (
            <Alert
              description={`Per modificare il documento è necessario prendere in carico l'attività.`}
              showIcon
              message={`Documento disponibile in sola lettura`}
              type="info"
            />
          )}
        </div>
      )}
      <FileListItem
        icon={document.signed ? <FileProtectOutlined /> : <FileTextOutlined />}
        title={
          <FileListItemTitle
            filename={document.filename}
            createdAt={document.createdAt}
            version={document.version}
            // TODO potrebbe essere comodo mostrare il nome dell'utente che ha caricato il file?
            user={flowData.owner?.name}
            showMeta={isHistory}
          />
        }
        actions={
          <Space>
            {showUploadComponent && (
              <Button
                icon={<UploadOutlined />}
                type="primary"
                ghost
                onClick={() => {
                  setShowUpload(showUpload => !showUpload);
                }}
              >
                {showUpload ? 'Chiudi Pannello Upload' : 'Aggiorna Documento'}
              </Button>
            )}
            <AuthLink
              href={FlowDocumentApi.downloadLink({
                flowId: flowData.id,
                documentId: document.id
              })}
            >
              <a>
                <Button icon={<DownloadOutlined />} type="primary" ghost>
                  Download
                </Button>
              </a>
            </AuthLink>
          </Space>
        }
        uploadArea={showUploadComponent && showUpload ? uploadArea : undefined}
        revisionArea={
          hasReview && (
            <Space direction="vertical" style={{ width: '100%' }}>
              {documentEventReviews.map((event, index) => (
                <FileReviewListItem
                  key={event.id}
                  isHistory={isHistory}
                  createdAt={event.createdAt}
                  user={event.user?.name}
                  title={
                    event.review && (
                      <FileListItemTitle
                        filename={event.review.filename}
                        createdAt={event.createdAt}
                        user={event.user?.name}
                        showMeta
                      />
                    )
                  }
                  actions={
                    <AuthLink
                      href={ReviewDocumentApi.downloadLink({
                        flowId: flowData.id,
                        documentId: event.review?.id!
                      })}
                    >
                      <a>
                        <Button
                          type={isHistory ? 'link' : 'default'}
                          icon={<DownloadOutlined />}
                        >
                          Download Commenti Revisione
                        </Button>
                      </a>
                    </AuthLink>
                  }
                  message={event.notes ?? undefined}
                />
              ))}
            </Space>
          )
        }
      />
    </div>
  );
}
