import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowRevisionButton } from '../FlowRevisionButton';

export interface IFlowCompilerRevisionButtonProps {
  data: FlowDto;
}

/**
 * Pulsante per richiedere la modifica all'utente redattore (medico)
 */
export function FlowCompilerRevisionButton(
  props: IFlowCompilerRevisionButtonProps
) {
  const { data } = props;

  const [toRevision] = useApiMutation(FlowApi.actions.toCompilerRevision, {});

  return (
    <FlowRevisionButton
      data={data}
      modalTitle="Richiedi revisione"
      modalContent="Il documento verrà inoltrato al redattore per le modifiche."
      sendButtonText="Richiedi Modifiche"
      initialState={FlowState.DocumentCheck}
      nextState={FlowState.Submitted}
      onSend={toRevision}
    />
  );
}
