import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import {
  useAuth,
  useCurrentUser,
  usePermissions
} from 'client/components/auth/AuthModule';
import { NotFoundPage } from 'client/components/errors/not-found/NotFoundPage';
import { useModalHistoryCleanup } from 'client/ui/modal/useModalHistoryCleanup';
import { ClientConfigLoader } from 'client/components/schema/config/ClientConfigLoader';
import { FlowListPage } from './flows/list/FlowListPage';
import { LoginPage } from './login/LoginPage';
import { FlowPage } from './flows/detail/FlowPage';
import { SettingPage } from './admin/SettingPage';
import { PrivateRoute } from 'client/components/auth/routes/PrivateRoute';
import { RoleSelectionPage } from './role/RoleSelectionPage';

export const Routes = () => {
  const { logged } = useAuth();
  const currentUser = useCurrentUser();

  const permissions = usePermissions();

  const multipleRoles =
    currentUser && !currentUser.selectedRole && currentUser.foliumJwt;

  // Chiudiamo tutte le modal alla navigazione
  useModalHistoryCleanup();

  return (
    <ClientConfigLoader>
      <AuthCurrentLoader>
        {!logged ? (
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Redirect to="/login" />
            {/** Pagina non trovata */}
            <Route component={NotFoundPage} />
          </Switch>
        ) : multipleRoles ? (
          <Switch>
            <Route path="/role" component={RoleSelectionPage} />
            <Redirect to="/role" />
          </Switch>
        ) : (
          <Switch>
            <Redirect exact from="/login" to="/" />
            {currentUser && currentUser.avaiableRoles.length > 1 && (
              <Route path="/role" component={RoleSelectionPage} />
            )}

            <PrivateRoute
              path="/flows/:id"
              component={FlowPage}
              permission={['flow.read', 'flow.read:own']}
            />
            <PrivateRoute
              path="/flows"
              component={FlowListPage}
              permission={['flow.read', 'flow.read:own']}
            />

            <PrivateRoute
              path="/admin/config"
              component={SettingPage}
              permission={['settings.write']}
            />

            {/* Redirect alla lista dei flussi per gli utenti con permesso di visibilità */}
            {permissions.has(['flow.read', 'flow.read:own']) && (
              <Redirect exact from="/" to="/flows" />
            )}
            {/* Redirect alla configurazione per gli utenti con permesso di modifica del config (Admin) */}
            {permissions.has('settings.write') && (
              <Redirect exact from="/" to="/admin/config" />
            )}
            {/** Pagina non trovata */}
            <Route component={NotFoundPage} />
          </Switch>
        )}
      </AuthCurrentLoader>
    </ClientConfigLoader>
  );
};
