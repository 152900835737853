import { Tabs, TabsProps } from 'antd';
import clx from 'classnames';

import React from 'react';
import './PageTabsExtraContent.scss';

export interface PageTabsExtraContentProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export function PageTabsExtraContent(props: PageTabsExtraContentProps) {
  const { className, ...othersProps } = props;
  return (
    <div
      className={clx('tabs-extra-content-wrapper', className)}
      {...othersProps}
    />
  );
}
