import { FlowApi } from 'client/components/schema/flow/FlowApi';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import {
  DocumentTypologyList,
  IDocumentTypology
} from 'client/routes/flows/common/document/typology/DocumentTypologyList';
import { DocumentInstructionDto } from 'common/dto/generated/DocumentInstructionDto';
import { DocumentTemplateDto } from 'common/dto/generated/DocumentTemplateDto';
import { useEffect, useState } from 'react';

export interface IConfigDocument {
  typology: IDocumentTypology;
  template: DocumentTemplateDto | undefined;
  instruction: DocumentInstructionDto | undefined;
}

export function useConfigDocuments() {
  const templateResult = useApiQuery(FlowApi.templates.list, { data: {} });
  const instructionResult = useApiQuery(FlowApi.instructions.list, {
    data: {}
  });
  const [configDocuments, setConfigDocuments] = useState<IConfigDocument[]>([]);

  useEffect(() => {
    const templates = templateResult.response?.data.items ?? [];
    const instructions = instructionResult.response?.data.items ?? [];

    const result = DocumentTypologyList.map(typology => ({
      typology,
      template: templates.find(t => t.documentType === typology.documentType),
      instruction: instructions.find(
        i => i.documentType === typology.documentType
      )
    }));

    setConfigDocuments(result);
  }, [templateResult.response, instructionResult.response]);

  return {
    configDocuments,
    loading: templateResult.loading || instructionResult.loading,
    error: templateResult.error || instructionResult.error
  };
}
