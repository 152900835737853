import React from 'react';
import clx from 'classnames';
import { ISODate } from '../display/date/ISODate';
import './FileListItemTitle.scss';

export interface FileListItemTitleProps {
  filename?: string;

  // Mostriamo alcuni metadati, come utente e data di creazione
  showMeta?: boolean;

  // Dati da mostrare solo nel tab delle versioni
  createdAt?: string | Date;
  user?: string;
  // la versione non la mostriamo per i documenti dei commenti
  version?: number;
}
/*
 * Render del titolo di un elemento della lista dei file
 */
export function FileListItemTitle(props: FileListItemTitleProps) {
  const { filename, createdAt, user, showMeta, version } = props;

  // Se non c'è la versione, allora è un file di revisione
  const isRevision = version == null;

  return (
    <span className="file-list-item-title">
      {!isRevision && (
        <strong className="file-list-item-title--version">v.{version}</strong>
      )}
      <strong className="file-list-item-title--title">{filename}</strong>
      {showMeta && (
        <span className="file-list-item-title--meta">
          <ISODate date={createdAt} />
          {user && isRevision && <> - {user}</>}
        </span>
      )}
    </span>
  );
}
