import { QuestionCircleOutlined } from '@ant-design/icons';
import { Alert, Col, message, Row, Space, Typography } from 'antd';
const { Text } = Typography;
import { ClientConfigApi } from 'client/components/schema/config/ClientConfigApi';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { Section } from 'client/ui/section/Section';
import {
  getSettingModuleLabel,
  getSettingModuleMessage,
  ISettings,
  SettingModuleKey
} from 'common/schema/settings/Settings';
import { SettingVariables } from 'common/schema/settings/SettingVariables';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { SettingInputSwitchComponent } from './components/SettingInputSwitchComponent';
import { SettingSaveInfoBox } from './components/SettingSaveInfoBox';

export interface ISettingPanelProps {}

export function SettingPanel(props: ISettingPanelProps) {
  const {} = props;

  const {
    response,
    loading,
    error
  } = useApiQuery(ClientConfigApi.getAdminSettings, { data: {} });

  const [save] = useApiMutation(ClientConfigApi.saveConfig, {
    invalidates: [ClientConfigApi.getAdminSettings]
  });

  const settings = response?.data;

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Impossibile accedere alla configurazione"
        description={getNetworkErrorMessage(error)}
      />
    );
  }

  if (loading || !settings) {
    return <LoaderSpin transparent />;
  }

  return (
    <FormikAugmented<ISettings>
      initialValues={settings}
      onSubmit={async values => {
        try {
          await save({ data: { settings: values } });
          message.success(`Configurazione salvata con successo`);
        } catch (e) {
          console.error(`Errore durante il salvataggio della configurazione`, e); // prettier-ignore
          message.error(`Si è verificato un errore durante il savataggio. Riprovare.`); // prettier-ignore
        }
      }}
      validationContext={{}}
      validationSchema={yup.object()}
      enableReinitialize
    >
      <PageContent fixedWidht>
        <SettingSaveInfoBox />
        <FormikForm editable={true} showVisualFeedback={false} helpInTooltips>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            {Object.values(SettingModuleKey).map(module => {
              const variables = SettingVariables.filter(
                v => v.module === module
              ).sort((a, b) => {
                // Se sia a che b hanno l'ordine impostato, allora li ordino per ordine
                if (a.order != null && b.order != null)
                  return a.order - b.order;
                // Altrimenti li ordino alfabeticamente per chiave
                return a.key.localeCompare(b.key);
              });
              if (variables.length === 0) return null;

              return (
                <Section
                  title={getSettingModuleLabel(module)}
                  key={module}
                  hasPadding
                  extra={
                    <Text type="warning">
                      {getSettingModuleMessage(module)}
                    </Text>
                  }
                >
                  {variables.map(v => (
                    <FormFieldsContainer key={v.key}>
                      <Row key={v.key} align="middle" gutter={16}>
                        <Col span={14}>
                          <SettingInputSwitchComponent variable={v} />
                        </Col>
                        <Col span={10}>
                          <Text type="secondary">
                            <QuestionCircleOutlined /> {v.help}
                          </Text>
                        </Col>
                      </Row>
                    </FormFieldsContainer>
                  ))}
                </Section>
              );
            })}
          </Space>
        </FormikForm>
      </PageContent>
    </FormikAugmented>
  );
}
