import { apiClient } from 'client/core/network/apiClient';
import { IClientConfig } from 'common/config/IClientConfig';
import { ISettings } from 'common/schema/settings/Settings';

export const ClientConfigApi = {
  getConfig: (opts: {}) => apiClient.request().get<IClientConfig>(`/config`),
  getAdminSettings: () => apiClient.request().get<ISettings>(`/settings`),
  saveConfig: (opts: { settings: ISettings }) =>
    apiClient.request().put(`/settings`, opts.settings)
};
