import React from 'react';
import './VerticalSpace.scss';

export interface VerticalSpaceProps {
  /**
   * Numero di pixel di margin-bottom css
   */
  size?: number;
}

/**
 * Spazio distanziatore verticale.
 */
export function VerticalSpace(props: VerticalSpaceProps) {
  const size = props.size ?? 24;

  return (
    <div
      className="ui-vertical-space"
      style={{ marginBottom: size + 'px' }}
    ></div>
  );
}
