import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowToReviewButtonProps {
  data: FlowDto;
}

export function FlowToReviewButton(props: IFlowToReviewButtonProps) {
  const { data } = props;

  const [toReview] = useApiMutation(FlowApi.actions.toReview, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Inviare in approvazione?"
      modalContent="Il documento verrà presentato al Direttore. Una volta inviato non sarà possibile apportare modifiche."
      sendButtonText="Invia in Approvazione"
      initialState={[FlowState.QualityCheck, FlowState.RiskCheck]}
      nextState={FlowState.Review}
      onSend={toReview}
    />
  );
}
