import { message } from 'antd';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { AsyncSelectInput } from 'client/ui/form/input/AsyncSelectInput';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import { UserDto } from 'common/dto/generated/UserDto';
import { WorkingGroupQueryDto } from 'common/dto/query/WorkingGroupQueryDto';
import React from 'react';
import { useCurrentUser } from '../auth/AuthModule';
import { useRelationEditor } from '../form/relations/useRelationEditor';
import { UserApi } from '../schema/user/UserApi';
import { WorkingGroupUserWrapper } from './WorkingGroupUserWrapper';

const MIN_LEN = 2;

export interface IWorkingGroupSelectInputProps {
  name: string;
}

/**
 * Componente che permette la scelta di una lista di utenti redattori.
 * Utilizzato per la scelta di un gruppo di lavoro da parte del redattore.
 */
export function WorkingGroupSelectInput(props: IWorkingGroupSelectInputProps) {
  const { name } = props;

  const currentUser = useCurrentUser();
  const editor = useRelationEditor<UserDto>(name);

  if (!currentUser) return null;

  return (
    <>
      <AsyncSelectInput
        name={'select' + name}
        placeholder="Aggiungi membro al gruppo di lavoro..."
        dropdownMatchSelectWidth={false}
        allowClear
        showSearch
        refreshOnSearch
        query={{
          apiFn: UserApi.workingGroupList,
          options: (_, search) => ({
            data: {
              query: new WorkingGroupQueryDto({
                name: search,
                page: 1,
                pagesize: 20
              })
            },
            skip: !search || search.length < MIN_LEN
          })
        }}
        optionTransform={option => ({
          label: option.name,
          value: option.id
        })}
        responseTransform={response => response.items}
        responseMeta={data => ({
          shown: data.items.length,
          total: data.meta.total
        })}
        onAfterSelect={(option, selectHelpers) => {
          if (!option) return;
          if (option.id === currentUser.id) {
            message.warn('Non puoi aggiungerti al gruppo di lavoro');
            return;
          }
          if (!editor.items.some(v => v.id === option?.id)) {
            editor.add(option);
            selectHelpers.setValue(null);
          }
        }}
        notFoundMessage={(search: string | undefined) =>
          !search || search.length < MIN_LEN
            ? 'Digitare per iniziare la ricerca...'
            : 'Nessun utente trovato'
        }
      />
      <VerticalSpace size={2} />
      <WorkingGroupUserWrapper>
        {editor.items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(user => (
            <span>
              {user.name}
              {user.id !== currentUser.id && (
                <ButtonDeleteConfirm
                  title={`Rimuovere ${user.name} dal GdL?`}
                  onConfirm={() => {
                    editor.remove(user);
                  }}
                  iconOnlyButton
                  okText="Rimuovi"
                  buttonDanger
                  buttonType="link"
                />
              )}
            </span>
          ))}
      </WorkingGroupUserWrapper>
    </>
  );
}

WorkingGroupSelectInput.renderFieldValue = (values: UserDto[]) => {
  return (
    <WorkingGroupUserWrapper>
      {values
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(user => (
          <span>{user.name} </span>
        ))}
    </WorkingGroupUserWrapper>
  );
};
