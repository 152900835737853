import { apiClient } from 'client/core/network/apiClient';
import { ICreateAccessTokenDto } from 'common/dto/AuthDto';

export const AuthApi = {
  /** Creazione dell'access token per il login */
  createAccessToken: (data: ICreateAccessTokenDto) =>
    apiClient.request().post('/auth/access-token', data),
  logout: () => apiClient.request().post('/auth/logout'),
  refreshToken: () => apiClient.request().post('/auth/refresh-token')
};
