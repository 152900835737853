import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { EventLogType } from 'common/schema/event-log/EventLogType';
import { UserDto } from './UserDto';
import { ReviewDocumentDto, ReviewDocumentSchema } from './ReviewDocumentDto';

/**
 * Rappresentazione DTO della classe EventLog definita in: src/server/schema/flow/event-log/EventLog.entity.ts
 * Hash: 78be6b068ccff9d93cf502ba0be7e82c
 */
@ValidationSchema(() => EventLogSchema)
export class EventLogDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ required: false, type: String, description: 'Note' })
  notes?: string | null | undefined;
  @ApiProperty({ enum: ['created', 'deleted', 'advancement', 'revisionRequest', 'approved', 'rejected', 'published'], description: 'Tipo' })
  type!: EventLogType;
  @ApiProperty({ required: false, type: String, description: 'Stato di partenza' })
  fromState?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Stato di arrivo' })
  toState?: string | null | undefined;
  @ApiProperty({ required: false, type: Number })
  userId!: number;
  @ApiProperty({ required: false, type: () => UserDto })
  @Type(() => UserDto)
  user!: UserDto;
  @ApiProperty({ required: false, type: Number })
  flowId!: number;
  @ApiProperty({ required: false, type: Number })
  reviewId?: number | null | undefined;
  @ApiProperty({ required: false, type: () => ReviewDocumentDto })
  @Type(() => ReviewDocumentDto)
  review?: ReviewDocumentDto | null;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;
  @ApiProperty({ required: false, type: Number, description: 'Id Documento' })
  mainDocumentId?: number | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<EventLogDto>) {
    if (values != null) {
      Object.assign(this, values instanceof EventLogDto ? values : plainToClass(EventLogDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await EventLogSchema.validate(classToPlain(this), options);
    return new EventLogDto(validated);
  }
}

/** Interfaccia simmetrica al DTO EventLogDto */
export type IEventLogType = IDto<EventLogDto>;

/**
 * DTO Paginato della classe EventLog
 */
export class PaginatedEventLogDto {
  @ApiProperty({ type: [EventLogDto] })
  @Type(() => EventLogDto)
  items!: EventLogDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const EventLogSchema = yup
  .object({
    id: yup.number(),
    notes: yup.string().nullable().label('Note'),
    type: yup.string().oneOfEnum(EventLogType).required().label('Tipo'),
    fromState: yup.string().nullable().label('Stato di partenza'),
    toState: yup.string().nullable().label('Stato di arrivo'),
    userId: yup.number(),
    flowId: yup.number(),
    reviewId: yup.number().nullable(),
    mainDocumentId: yup.number().nullable().label('Id Documento')
  })
  .noUnknown()
  .meta({ schemaName: "EventLogSchema" })
  .required();
