import { Tag, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { FlowStateTag } from 'client/components/schema/flow/tags/FlowStateTag';
import { ISODate } from 'client/ui/display/date/ISODate';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { IUserType } from 'common/dto/generated/UserDto';
import { FlowLogic } from 'common/schema/flow/FlowLogic';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';

export function getFlowColumns(user: IUserType): ColumnProps<FlowDto>[] {
  return [
    {
      title: '',
      dataIndex: 'inRevision',
      key: 'inRevision',
      width: 35,
      render: (_, record) =>
        record.inRevision ? (
          <Tooltip title="Richiesta revisione" placement="topRight">
            <Tag color="red">R</Tag>
          </Tooltip>
        ) : null,
      ...createColumnFilter(FilterInputType.CheckBox, {
        options: [{ label: 'Richiesta revisione', value: 'inRevision' }]
      })
    },
    {
      title: 'Stato',
      dataIndex: 'state',
      key: 'state',
      width: 170,
      render: (_, record) => <FlowStateTag state={record.state} />,
      ...createColumnFilter(FilterInputType.Enum, {
        options: FlowLogic.getVisibleStatesByRole(user).map(k => ({
          label: <FlowStateTag state={k} />,
          value: k
        }))
      })
    },

    {
      title: 'Oggetto',
      dataIndex: 'subject',
      key: 'subject',
      ...createColumnFilter(FilterInputType.Text),
      sorter: true
    },
    {
      title: 'In carico',
      dataIndex: 'workingUser',
      key: 'workingUser',
      sorter: true,
      render: (_, record) => record.workingUser?.name ?? '',
      ...createColumnFilter(FilterInputType.Text),
      width: 200
    },
    {
      title: 'Autore',
      dataIndex: 'owner',
      width: 200,
      key: 'owner',
      render: (_, record) => record.owner?.name,
      ...createColumnFilter(FilterInputType.Text),
      sorter: true
    },
    {
      title: 'Data',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...createColumnFilter(FilterInputType.DateRange),
      width: 110,
      render: (_, record) => <ISODate date={record.createdAt} />,
      sorter: true
    }
  ];
}
