import * as React from 'react';
import clx from 'classnames';
import './UploadInputWrapper.scss';
import Dragger, { DraggerProps } from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { UploadInputWrapper } from './UploadInputWrapper';

export interface UploadInputProps extends DraggerProps {
  placeholder?: string;
  hint?: string;
}

/**
 * Container per assegnare gli stili ai componenti Drawer o Upload di Ant
 */
export function UploadInput(props: UploadInputProps) {
  const { placeholder, hint, ...otherProps } = props;
  return (
    <UploadInputWrapper>
      <Dragger
        // fileList={fileList}
        // accept={acceptedFileTypes}
        // multiple={multipleFile}
        showUploadList={{
          showDownloadIcon: false,
          showPreviewIcon: false,
          showRemoveIcon: true
        }}
        {...otherProps}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {placeholder ??
            'Fai click o trascina un file in quest’area per caricare gli allegati.'}
        </p>
        {hint && <p className="ant-upload-hint">{props.hint}</p>}
      </Dragger>
    </UploadInputWrapper>
  );
}
