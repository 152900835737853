import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import clx from 'classnames';
import './FileListItem.scss';
import { FileTextOutlined } from '@ant-design/icons';

export interface FileListItemProps {
  // Titolo del file Passato come componente per permettere di personalizzare il titolo
  // FileListItemTitle.tsx
  title?: React.ReactNode;
  // Icona del file
  icon?: React.ReactNode;
  // pulsant Azione
  actions?: React.ReactNode;
  //Area extra Revisioni
  revisionArea?: React.ReactNode;
  //Area extra Uploader
  uploadArea?: React.ReactNode;
  // indica che è un file di revisione
  isRevisionFile?: boolean;
  // indica se è visualizzato nella storia delle versioni
  isHistory?: boolean;

  // DEPRECATA Proprietà del bottone (delete)
  // deleteButtonProps?: ButtonProps;
  // DEPRECATA usare actions
  // sostituisce i bottoni di default
  buttons?: React.ReactNode;
}
/*
 * Render di un elemento della lista dei file
 */
export function FileListItem(props: FileListItemProps) {
  const {
    icon,
    title,
    revisionArea,
    uploadArea,
    actions,
    isRevisionFile
  } = props;
  if (!title) return null;

  return (
    <div
      className={clx('file-list-item-wrapper', {
        'is-revision-file': isRevisionFile
      })}
    >
      <div className={clx('file-list-item', { 'is-history': props.isHistory })}>
        <div className="icon">{icon ?? <FileTextOutlined />}</div>
        <div className="title">{title}</div>
        <div className="actions">{actions}</div>
      </div>
      {uploadArea && (
        <div className="extra-area-wrapper upload-area">{uploadArea}</div>
      )}
      {revisionArea && <div className="extra-area-wrapper">{revisionArea}</div>}
    </div>
  );
}
