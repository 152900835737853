import { Space, SpaceProps, Table, TableProps } from 'antd';
import * as React from 'react';
import clx from 'classnames';

import './PageContent.scss';
import { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { PageContentFixedWrapper } from './FixedPageContent';

export interface PageContentProps {
  children: React.ReactNode;
  /**
   * Permette di eliminare il componente Space.
   * Space è utilizzato per "spaziare" le Section della pagina.
   */
  noSpace?: boolean;
  /**
   * Permette di eliminare il padding perimetrale intorno al contenuto.
   * Da impostare per le tabelle a tutto formato
   */
  noPadding?: boolean;
  /** Dimensione fissa */
  fixedWidht?: boolean;
  /** Proprietà dello space (solo se `noSpace` è false) */
  spaceProps?: SpaceProps;
}

/**
 * Contiene la pagina, che sia una tabella oppure gli elementi collapsible
 * di un dettaglio.
 */
export function PageContent(props: PageContentProps) {
  return (
    // <PageContentFixedWrapper>
    <div
      className={clx('page-content content-brianza', {
        'no-padding': props.noPadding,
        'fixed-width': props.fixedWidht
      })}
    >
      {props.noSpace ? (
        props.children
      ) : (
        <Space
          direction="vertical"
          size={16}
          style={{ width: '100%' }}
          {...props.spaceProps}
        >
          {props.children}
        </Space>
      )}
    </div>
    // </PageContentFixedWrapper>
  );
}
