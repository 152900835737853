import { Button, Tooltip } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { ButtonConfirm } from 'client/ui/button/ButtonConfirm';
import { useFormikAugmentedContext } from 'client/ui/form/FormikAugmentedContext';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { useFormikContext } from 'formik';
import React from 'react';
import { FlowApi } from '../FlowApi';
import { FlowAssignmentApi } from '../FlowAssignmentApi';

export interface IFlowReleaseButtonProps {
  flow: FlowDto | null | undefined;
}

export function FlowReleaseButton(props: IFlowReleaseButtonProps) {
  const { flow } = props;

  const user = useCurrentUser();

  const { allowed: canRelease } = FlowMachine.can(flow, user, 'release');
  const { dirty } = useFormikContext();

  const [release] = useApiMutation(FlowAssignmentApi.release, {
    data: { id: flow?.id },
    invalidates: [FlowApi.detail]
  });

  const handleRelease = () => {
    release({});
  };

  if (!user) return null;
  if (!canRelease) return null;
  if (!flow) return null;

  const confirmTitle =
    flow.workingUserId === user.id
      ? 'Rilasciare il documento?'
      : `Rimuovere il documento in carico da ${flow.workingUser?.name}?`;

  return (
    <Tooltip title={dirty ? 'Salvare le modifiche' : undefined}>
      <ButtonConfirm
        title={confirmTitle}
        okButtonProps={{ type: 'primary' }}
        placement="bottomRight"
        size="large"
        onConfirm={handleRelease}
        okText="Rilascia"
        disabled={dirty}
      >
        Rilascia Documento
      </ButtonConfirm>
    </Tooltip>
  );
}
