import {
  AuditOutlined,
  CloseCircleFilled,
  EditOutlined,
  FileDoneOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { StepProps, Steps, Tag } from 'antd';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { assertNever } from 'server/utils/typings/assertNever';

export interface IFlowStep {
  index: number;
  step: StepProps;
}

export function getStepFromFlowState(state?: FlowState): IFlowStep {
  if (!state)
    return {
      index: 0,
      step: {
        // title: 'Documento',
        icon: <UploadOutlined />
      }
    };

  switch (state) {
    case FlowState.Draft:
      return {
        index: 0,
        step: {
          // title: 'Nuovo Documento',
          icon: <UploadOutlined />
        }
      };
    case FlowState.Submitted:
      return {
        index: 0,
        step: {
          title: 'In Revisione',
          icon: <EditOutlined />
        }
      };
    case FlowState.DocumentCheck:
      return {
        index: 1,
        step: {
          // title: 'Verifica Documento',
          icon: <FileSearchOutlined />
        }
      };

    case FlowState.QualityCheck:
      return {
        index: 2,
        step: {
          // title: 'Approvazione',
          icon: <FileSearchOutlined />
        }
      };

    case FlowState.RiskCheck:
      return {
        index: 2,
        step: {
          // title: 'Approvazione',
          icon: <FileSearchOutlined />
        }
      };

    case FlowState.Review:
      return {
        index: 3,
        step: {
          // title: 'Approvazione',
          icon: <AuditOutlined />
        }
      };

    case FlowState.StrategicReview:
      return {
        index: 3,
        step: {
          // title: 'Approvazione',
          icon: <AuditOutlined />
        }
      };

    case FlowState.Approved:
      return {
        index: 3,
        step: {
          title: 'Approvato',
          icon: <FileDoneOutlined />
        }
      };
    case FlowState.Rejected:
      return {
        index: 4,
        step: {
          title: 'Respinto',
          status: 'error',
          icon: <CloseCircleFilled />
        }
      };
    case FlowState.Published:
      return {
        index: 4,
        step: {
          title: 'Pubblicato',
          icon: <FileProtectOutlined />
        }
      };
  }
  assertNever(state);
}
