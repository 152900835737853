import { Tag } from 'antd';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import React from 'react';
import { getDocumentTypologyListItem } from './getDocumentTypologyListItem';

export interface DocumentTypologyTagProps {
  typology?: DocumentTypology;
}
/**
 * Tag della Tipologia di Documento
 */
export function DocumentTypologyTag(props: DocumentTypologyTagProps) {
  if (!props.typology) return null;

  const type = getDocumentTypologyListItem(props.typology);
  if (!type) return null;

  return <Tag color={type?.color}>{type?.label}</Tag>;
}
