import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { ColumnSortDirections } from 'client/ui/table/TableColumnSort';
import { FlowState } from 'common/schema/flow/FlowState';



@GenerateDto()
class FlowListQuery {
    @SchemaLabel('Pagina')
    @SchemaNotRequired()
    @SchemaValidate((schema) => schema.default(1))
    page!: number;

    @SchemaLabel('Dimensione Pagina')
    @SchemaNotRequired()
    @SchemaValidate((schema) => schema.default(20))
    pagesize!: number;

    @SchemaLabel("Ordinamento")
    orderBy?: string = "id";
    @SchemaLabel("Direzione di Ordinamento")
    orderByDirection?: ColumnSortDirections = ColumnSortDirections.descend;

    @SchemaLabel('Oggetto')
    @SchemaNotRequired()
    subject?: string | null;

    @SchemaLabel('Stato')
    @SchemaNotRequired()
    state?: FlowState | null;

    @SchemaLabel('In Revisione')
    @SchemaNotRequired()
    inRevision?: boolean | null;

    @SchemaLabel('Autore')
    @SchemaNotRequired()
    owner?: string | null;


    @SchemaLabel('In carico')
    @SchemaNotRequired()
    workingUser?: string | null;

    @SchemaLabel('Creato dal')
    @SchemaNotRequired()
    createdFrom?: Date | null;

    @SchemaLabel('Creato al')
    @SchemaNotRequired()
    createdTo?: Date | null;

    @SchemaLabel('Solo da lavorare')
    @SchemaNotRequired()
    onlyToWork?: boolean | null = false;

}

/**
 * Rappresentazione DTO della classe FlowListQuery 
 * Hash: 7d2ca334c8d57d7bef6366419c2deeea
 */
@ValidationSchema(() => FlowListQuerySchema)
export class FlowListQueryDto {
  @ApiProperty({ required: false, type: Number, description: 'Pagina' })
  page!: number;
  @ApiProperty({ required: false, type: Number, description: 'Dimensione Pagina' })
  pagesize!: number;
  @ApiProperty({ required: false, type: String, description: 'Ordinamento' })
  orderBy?: string | undefined = "id";
  @ApiProperty({ required: false, enum: ['ASC', 'DESC'], description: 'Direzione di Ordinamento' })
  orderByDirection?: ColumnSortDirections | undefined = ColumnSortDirections.descend;
  @ApiProperty({ required: false, type: String, description: 'Oggetto' })
  subject?: string | null | undefined;
  @ApiProperty({ required: false, enum: ['Draft', 'Submitted', 'DocumentCheck', 'QualityCheck', 'RiskCheck', 'Review', 'StrategicReview', 'Approved', 'Rejected', 'Published'], description: 'Stato' })
  state?: FlowState | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'In Revisione' })
  inRevision?: boolean | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Autore' })
  owner?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'In carico' })
  workingUser?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Creato dal' })
  createdFrom?: Date | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time', description: 'Creato al' })
  createdTo?: Date | null | undefined;
  @ApiProperty({ required: false, type: Boolean, description: 'Solo da lavorare' })
  onlyToWork?: boolean | null | undefined = false;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<FlowListQueryDto>) {
    if (values != null) {
      Object.assign(this, values instanceof FlowListQueryDto ? values : plainToClass(FlowListQueryDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await FlowListQuerySchema.validate(classToPlain(this), options);
    return new FlowListQueryDto(validated);
  }
}

export const FlowListQuerySchema = yup
  .object({
    page: yup.number().default(1).label('Pagina'),
    pagesize: yup.number().default(20).label('Dimensione Pagina'),
    orderBy: yup.string().default("id").label('Ordinamento'),
    orderByDirection: yup.string().oneOfEnum(ColumnSortDirections).default(ColumnSortDirections.descend).label('Direzione di Ordinamento'),
    subject: yup.string().nullable().label('Oggetto'),
    state: yup.string().oneOfEnum(FlowState).nullable().label('Stato'),
    inRevision: yup.boolean().nullable().label('In Revisione'),
    owner: yup.string().nullable().label('Autore'),
    workingUser: yup.string().nullable().label('In carico'),
    createdFrom: yup.date().nullable().label('Creato dal'),
    createdTo: yup.date().nullable().label('Creato al'),
    onlyToWork: yup.boolean().nullable().default(false).label('Solo da lavorare')
  })
  .noUnknown()
  .meta({ schemaName: "FlowListQuerySchema" })
  .required();
