import {
  Button,
  ButtonProps,
  Col,
  message,
  Row,
  Space,
  Typography
} from 'antd';
import { RunApiMutation } from 'client/core/network/hooks/useApiMutation';
import { DrawerActionsBar } from 'client/ui/actions-bar/DrawerActionsBar';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { UploadInput } from 'client/ui/form/input/UploadInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowDataDto, FlowDataSchema } from 'common/dto/query/FlowDataDto';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { FlowState } from 'common/schema/flow/FlowState';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FlowApi, IFlowNeedRevisionApiInput } from '../../../FlowApi';
import { useHistory } from 'react-router';
import { useApiHelpers } from 'client/core/network/hooks/useApiHelpers';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { flowAcceptedFileFormatString } from '../../flowAcceptedFileFormat';

const { Text } = Typography;

export interface IFlowRevisionModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  data: FlowDto;
  modalTitle: string;
  modalContent: string;
  sendButtonText: string;
  sendButtonProps: ButtonProps;
  nextState: FlowState;
  onSend: RunApiMutation<IFlowNeedRevisionApiInput, any>;
}

/** Modal per rimandare "indietro" il flusso per una revisione */
export function FlowRevisionModal(props: IFlowRevisionModalProps) {
  const { visible, setVisible } = props;
  const flowContext = useFormikContext<FlowDto>();
  const history = useHistory();
  const apiHelper = useApiHelpers();
  const user = useCurrentUser();

  // File del documento con commenti
  const [file, setFile] = useState<File>();

  const { allowed } = FlowMachine.to(flowContext.values, props.nextState, user);

  if (!allowed) return null;

  return (
    <FormikAugmented<FlowDataDto>
      initialValues={new FlowDataDto()}
      validationContext={{}}
      validationSchema={FlowDataSchema}
      onSubmit={async values => {
        if (!user) return;
        if (!flowContext.values.id) {
          console.warn(`[FlowRevisionModal] Missing id`, values);
          return;
        }

        if (flowContext.dirty) {
          console.warn(`[FlowRevisionModal] Il flusso non è stato salvato`);
          message.error(`Salvare per procedere`);
          return;
        }

        const formData = new FormData();

        if (file) formData.append('reviewDocument', file);

        Object.keys(values).forEach(key => {
          formData.set(key, get(values, key));
        });

        const result = await props.onSend({
          data: { id: flowContext.values.id, data: formData }
        });
        message.success('Documento inviato correttamente');

        // Ricarico la pagina oppure redirect alla lista dei flussi
        if (FlowMachine.can(result.data, user, 'view').allowed) {
          apiHelper.invalidates([FlowApi.detail]);
        } else {
          history.push('/flows');
        }
      }}
    >
      <ModalDialog
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        title={props.modalTitle}
        footer={
          <DrawerActionsBar fixedWidht>
            <Space>
              <Button onClick={() => setVisible(false)} size="large">
                Annulla
              </Button>
              <FormikSendButton {...props.sendButtonProps}>
                {props.sendButtonText ?? 'Invia'}
              </FormikSendButton>
            </Space>
          </DrawerActionsBar>
        }
      >
        <Row gutter={40}>
          <Col span={20} offset={2}>
            <VerticalSpace size={5} />
            <Text>{props.modalContent}</Text>
            <VerticalSpace size={20} />
            <FormFieldsContainer>
              <FormFieldItem
                component={TextAreaInput}
                label={null}
                name={`notes`}
                placeholder="Inserisci una nota"
                rows={4}
              />
            </FormFieldsContainer>

            <FormFieldsContainer>
              <FormFieldItem
                inline
                component={UploadInput}
                accept={flowAcceptedFileFormatString + ',.pdf'}
                label={null}
                listType="picture"
                name="file"
                placeholder="Fai click o trascina un file in quest’area per caricare il Documento con i commenti di revisione."
                hint="Caricare il documento in formato Word (.doc, .docx, .pdf)"
                beforeUpload={(file: File) => {
                  if (!file) return false;
                  setFile(file);
                  return false;
                }}
                onRemove={() => setFile(undefined)}
                maxCount={1}
              />
            </FormFieldsContainer>
          </Col>
        </Row>
      </ModalDialog>
    </FormikAugmented>
  );
}
