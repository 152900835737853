import React, { useState } from 'react';
import {
  BorderOutlined,
  CheckSquareOutlined,
  PlusOutlined,
  TableOutlined
} from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { CorePageLayout } from 'client/ui/layout/CorePageLayout';
import { PageHeadingBrianza } from 'client/ui/layout/header-brianza/PageHeadingBrianza';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { PageHeadingTitle } from 'client/ui/layout/page/heading/PageHeadingTitle';
import { Section } from 'client/ui/section/Section';
import { Link, RouteComponentProps } from 'react-router-dom';
import { SectionButton } from 'client/ui/section/SectionButton';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { FlowApi } from 'client/components/schema/flow/FlowApi';
import {
  FlowListQueryDto,
  FlowListQuerySchema
} from 'common/dto/query/FlowListQueryDto';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { SorterResult } from 'antd/lib/table/interface';
import {
  getDateRange,
  getFilter,
  getOrder
} from 'client/ui/table/TableColumnFilter';
import { FlowState } from 'common/schema/flow/FlowState';
import { getFlowColumns } from './FlowColumns';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';

export interface IssuesListPageProps extends RouteComponentProps {}

export interface IFlowListPageProps extends RouteComponentProps {}

export function FlowListPage(props: IFlowListPageProps) {
  const user = useCurrentUser()!;
  const [query, setQuery] = useState(
    new FlowListQueryDto(FlowListQuerySchema.cast({}))
  );

  const [onlyToWork, setOnlyToWork] = useState<boolean>(false);

  const { response, error, loading } = useApiQuery(FlowApi.list, {
    data: { query }
  });

  const flows = response?.data?.items || [];
  const flowColumns = getFlowColumns(user);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco delle segnalazioni"
        error={error}
      />
    );
  }

  const canCreate = user.getRole().hasPermission('flow.create');

  return (
    <CorePageLayout>
      <PageHeadingBrianza
        title={<PageHeadingTitle>Tutti i Documenti</PageHeadingTitle>}
        extra={
          canCreate ? (
            <Link to="/flows/create">
              <Button size="large" type="primary" icon={<PlusOutlined />}>
                Nuovo Documento
              </Button>
            </Link>
          ) : null
        }
      />
      <PageContent fixedWidht>
        <Section
          icon={<TableOutlined />}
          title="Elenco Documenti"
          blocked
          extra={
            <Space>
              <SectionButton
                type={onlyToWork ? 'primary' : 'default'}
                icon={onlyToWork ? <CheckSquareOutlined /> : <BorderOutlined />}
                onClick={() => {
                  setQuery(
                    query =>
                      new FlowListQueryDto({
                        ...query,
                        onlyToWork: !onlyToWork
                      })
                  );
                  setOnlyToWork(onlyToWork => !onlyToWork);
                }}
              >
                Da Lavorare
              </SectionButton>
            </Space>
          }
        >
          <Table<FlowDto>
            sticky={{ offsetHeader: loading || !flows ? 0 : 56 }}
            loading={tableLoaderSpin(loading || !flows)}
            rowKey="id"
            onRow={record => ({
              style: {
                cursor: 'pointer'
              },
              onClick: () => {
                props.history.push(`/flows/${record.id}`);
              }
            })}
            columns={flowColumns}
            dataSource={flows}
            size="middle"
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<FlowDto>;

              const inRevision =
                getFilter<string>(filters, 'inRevision') === 'inRevision';

              const state = getFilter<FlowState>(filters, 'state');
              const subject = getFilter<string>(filters, 'subject');
              const owner = getFilter<string>(filters, 'owner');
              const workingUser = getFilter<string>(filters, 'workingUser');
              const createdAtRange = getDateRange(filters.createdAt);

              const { orderBy, orderByDirection } = getOrder(order, field);

              setQuery(
                new FlowListQueryDto({
                  ...query,
                  subject,
                  inRevision,
                  state,
                  owner,
                  workingUser,
                  onlyToWork,
                  createdFrom: createdAtRange?.from,
                  createdTo: createdAtRange?.to,
                  orderBy,
                  orderByDirection,
                  page: pagination.current || query.page,
                  pagesize: pagination.pageSize || query.pagesize
                })
              );
            }}
            pagination={{
              size: 'small',
              position: ['bottomRight'],
              showSizeChanger: true,
              defaultPageSize: 30,
              pageSizeOptions: ['30', '50', '100']
            }}
          />
        </Section>
      </PageContent>
    </CorePageLayout>
  );
}
