import React from 'react';
import { Alert, Space, Table } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import { Section } from 'client/ui/section/Section';
import { ConfigDocumentColumns } from './ConfigDocumentColumns';
import {
  IConfigDocument,
  useConfigDocuments
} from './hooks/useConfigDocuments';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { ConfigUploadDocumentButton } from './ConfigUploadDocumentButton';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowApi } from 'client/components/schema/flow/FlowApi';

export function TemplateSection() {
  const { configDocuments, loading, error } = useConfigDocuments();
  const columns = ConfigDocumentColumns;

  const [uploadTemplate] = useApiMutation(FlowApi.templates.edit, {
    invalidates: [FlowApi.templates.list]
  });
  const [uploadInstructions] = useApiMutation(FlowApi.instructions.edit, {
    invalidates: [FlowApi.instructions.list]
  });

  if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Impossibile accedere alla configurazione dei documenti"
        description={getNetworkErrorMessage(error)}
      />
    );
  }

  return (
    <Section icon={<FileTextOutlined />} title="Configurazione Template">
      <Table<IConfigDocument>
        size="small"
        rowKey="id"
        dataSource={configDocuments}
        loading={loading}
        columns={[
          ...columns,

          {
            title: 'Upload File',
            key: 'actions',
            width: 220,
            align: 'right',
            render: (_, record) => (
              <Space size="small">
                <ConfigUploadDocumentButton
                  typology={record.typology.documentType}
                  buttonText="Template"
                  onSuccessMessage="Template caricato"
                  onSend={uploadTemplate}
                />
                <ConfigUploadDocumentButton
                  typology={record.typology.documentType}
                  buttonText="Istruzioni"
                  onSuccessMessage="Istruzioni caricate"
                  onSend={uploadInstructions}
                />
              </Space>
            )
          }
        ]}
        pagination={false}
      />
    </Section>
  );
}
