import { Button, ButtonProps } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IFormikSendButtonProps extends ButtonProps {
  disableIfDirty?: boolean;
}

export function FormikSendButton(props: IFormikSendButtonProps) {
  const { disableIfDirty } = props;
  const formik = useFormikContext();
  return (
    <Button
      type="primary"
      disabled={formik.isSubmitting || (disableIfDirty && !formik.dirty)}
      loading={formik.isSubmitting}
      {...props}
      onClick={formik.submitForm}
    />
  );
}
