import { Space } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { BottomActionsBar } from 'client/ui/actions-bar/BottomActionsBar';
import { PageContent } from 'client/ui/layout/page/content/PageContent';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { useFormikContext } from 'formik';
import React from 'react';
import { FlowApproveButton } from './state-actions/actions/FlowApproveButton';
import { FlowCompilerRevisionButton } from './state-actions/actions/FlowCompilerRevisionButton';
import { FlowPublishButton } from './state-actions/actions/FlowPublishButton';
import { FlowRejectButton } from './state-actions/actions/FlowRejectButton';
import { FlowToDocumentCheckButton } from './state-actions/actions/FlowToDocumentCheckButton';
import { FlowToQualityCheckButton } from './state-actions/actions/FlowToQualityCheckButton';
import { FlowToQualitySecretaryRevisionButton } from './state-actions/actions/FlowToQualitySecretaryRevisionButton';
import { FlowToReviewButton } from './state-actions/actions/FlowToReviewButton';
import { FlowToRiskCheckButton } from './state-actions/actions/FlowToRiskCheckButton';
import { FlowToStrategicReviewButton } from './state-actions/actions/FlowToStrategicReviewButton';

export interface IFlowStateActionButtonProps {}

export function FlowStateActionButton(props: IFlowStateActionButtonProps) {
  const formik = useFormikContext<FlowDto>();
  const user = useCurrentUser();

  const { allowed: canEdit } = FlowMachine.can(formik.values, user, 'edit');

  if (!user) return null;
  if (!canEdit) return null;

  return (
    <PageContent fixedWidht>
      <BottomActionsBar fixedWidht>
        <Space>
          {/* Richieste di Revisione */}
          <FlowCompilerRevisionButton data={formik.values} />
          <FlowToQualitySecretaryRevisionButton data={formik.values} />
          {/* Avanzamento di stato */}
          <FlowToDocumentCheckButton data={formik.values} />
          <FlowToQualityCheckButton data={formik.values} />
          <FlowToRiskCheckButton data={formik.values} />
          <FlowToReviewButton data={formik.values} />
          <FlowToStrategicReviewButton data={formik.values} />
          <FlowRejectButton data={formik.values} />
          <FlowApproveButton data={formik.values} />
          <FlowPublishButton data={formik.values} />
        </Space>
      </BottomActionsBar>
    </PageContent>
  );
}
