import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowPublishButtonProps {
  data: FlowDto;
}

export function FlowPublishButton(props: IFlowPublishButtonProps) {
  const { data } = props;

  const [publish] = useApiMutation(FlowApi.actions.publish, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Impostare il documento come pubblicato?"
      modalContent="Il documento è stato pubblicato e protocollato. Una volta completata l'operazione, non sarà possibile apportare modifiche."
      initialState={FlowState.Approved}
      nextState={FlowState.Published}
      sendButtonText="Pubblicato"
      onSend={publish}
    />
  );
}
