import React from 'react';
import clx from 'classnames';
import './SlideEffect.scss';

export interface SlideEffectProps extends React.HTMLAttributes<HTMLDivElement> {
  visible?: boolean;
  slideEffect?: 'slide-in' | 'slide-out';
}

export function SlideEffect(props: SlideEffectProps) {
  const { className, children, visible, slideEffect, ...otherProps } = props;
  return (
    <div
      {...otherProps}
      className={clx('slider-effect', className, slideEffect ?? 'slide-in', {
        visible: visible
      })}
    >
      <div className="slider-effect-content">{children}</div>
    </div>
  );
}
