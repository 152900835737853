import React, { useState } from 'react';
import { Button, ButtonProps, Card, Input, Space } from 'antd';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { getDocumentTypologySelectOptions } from '../../common/document/typology/getDocumentTypologySelectOptions';
import { useField } from 'formik';
import { FlowLogic } from 'common/schema/flow/FlowLogic';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import { FlowType } from 'common/schema/flow/FlowType';

export interface SelectDocumentTypeCardProps {}

export function SelectDocumentTypeCard(props: SelectDocumentTypeCardProps) {
  const [selected, setSelected] = useState<DocumentTypology | null>(null);
  const [, , documentType] = useField<DocumentTypology>('documentType');
  const [, , flowType] = useField<FlowType>('flowType');

  return (
    <div>
      <Card>
        <Space
          direction="vertical"
          align="center"
          style={{ width: '100%', margin: '50px auto' }}
        >
          <Title level={3}>Scelta Tipologia di documento</Title>
          <Paragraph>
            Seleziona una tipologia di documento per procedere con la
            compilazione e avviare il flusso gestito.
          </Paragraph>

          <Input.Group compact>
            <SelectInput
              style={{ width: '400px' }}
              listHeight={300}
              size="large"
              name="type"
              placeholder="Tipologia Documento"
              options={getDocumentTypologySelectOptions()}
              onChange={item => {
                setSelected(item as DocumentTypology);
              }}
            />
            <Button
              size="large"
              type="primary"
              children="Avanti"
              disabled={!selected}
              onClick={() => {
                if (!selected) return;
                documentType.setValue(selected);
                flowType.setValue(FlowLogic.getFlowType(selected));
              }}
            />
          </Input.Group>
        </Space>
      </Card>
    </div>
  );
}
