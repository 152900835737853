import { Button, ButtonProps, Col, Divider, message, Row, Space } from 'antd';
import { RunApiMutation } from 'client/core/network/hooks/useApiMutation';
import { DrawerActionsBar } from 'client/ui/actions-bar/DrawerActionsBar';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowDataDto, FlowDataSchema } from 'common/dto/query/FlowDataDto';
import { FlowActionList, FlowMachine } from 'common/schema/flow/FlowMachine';
import { FlowState } from 'common/schema/flow/FlowState';
import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { FlowApi, IFlowActionApiInput } from '../../../FlowApi';
import { Typography } from 'antd';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { SendOutlined } from '@ant-design/icons';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiHelpers } from 'client/core/network/hooks/useApiHelpers';
import { useHistory } from 'react-router';
const { Text } = Typography;

export interface IFlowStateTransitionModalProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  data: FlowDto;
  modalTitle: string;
  modalContent: string;
  sendButtonText: string;
  sendButtonProps: ButtonProps;
  nextState: FlowState;
  onSend: RunApiMutation<IFlowActionApiInput, any>;
  hideIcon?: boolean;
}

/**
 * Modal per la transizione "in avanti" di stato di un flusso
 */
export function FlowStateTransitionModal(
  props: IFlowStateTransitionModalProps
) {
  const { visible, setVisible } = props;
  const flowContext = useFormikContext<FlowDto>();

  const history = useHistory();
  const apiHelper = useApiHelpers();
  const user = useCurrentUser();

  const { allowed } = FlowMachine.to(flowContext.values, props.nextState, user);

  if (!allowed) return null;

  return (
    <FormikAugmented<FlowDataDto>
      initialValues={new FlowDataDto()}
      validationContext={{}}
      validationSchema={FlowDataSchema}
      onSubmit={async values => {
        if (!user) return;
        if (!flowContext.values.id) {
          console.warn(`[FlowStateTransitionModal] Missing id`);
          return;
        }

        if (flowContext.dirty) {
          console.warn(`[FlowStateTransitionModal] Il flusso non è stato salvato`); // prettier-ignore
          message.error(`Salvare per procedere`);
          return;
        }

        // È necessario che sia presente il documento per procedere alla transizione di stato
        if (!flowContext.values.file && !flowContext.values.documents?.length) {
          console.warn(`[FlowStateTransitionModal] Il flusso non ha file associati`); // prettier-ignore
          message.error(`Nessun documento associato. Caricare un documento per procedere`); // prettier-ignore
          return;
        }

        const result = await props.onSend({
          data: { id: flowContext.values.id, data: values }
        });
        message.success('Documento inviato correttamente');

        // Ricarico la pagina oppure redirect alla lista dei flussi
        if (FlowMachine.can(result.data, user, 'view').allowed) {
          apiHelper.invalidates([FlowApi.detail]);
        } else {
          history.push('/flows');
        }
      }}
    >
      <ModalDialog
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        title={props.modalTitle}
        footer={
          <DrawerActionsBar fixedWidht>
            <Space>
              <Button onClick={() => setVisible(false)} size="large">
                Annulla
              </Button>
              <FormikSendButton {...props.sendButtonProps}>
                {props.sendButtonText ?? 'Invia'}
                {!props.hideIcon && <SendOutlined />}
              </FormikSendButton>
            </Space>
          </DrawerActionsBar>
        }
      >
        <Row gutter={40}>
          <Col span={20} offset={2}>
            <VerticalSpace size={5} />
            <Text>{props.modalContent}</Text>
            <VerticalSpace size={20} />
            <FormFieldsContainer>
              <FormFieldItem
                component={TextAreaInput}
                label={null}
                name={`notes`}
                placeholder="Inserisci una nota"
                rows={4}
              />
            </FormFieldsContainer>
          </Col>
        </Row>
      </ModalDialog>
    </FormikAugmented>
  );
}
