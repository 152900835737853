import React from 'react';
import { TagProps } from 'antd';
import { assertNever } from 'server/utils/typings/assertNever';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';

/**
 * Ottiene l'etichetta di una tipologia di documento
 */
export const getDocumentTypologyLabel = (type: DocumentTypology | null) => {
  if (!type) return '';
  switch (type) {
    case DocumentTypology.AnalisiProcesso:
      return 'Analisi di Processo';
    case DocumentTypology.MappaProcesso:
      return 'Mappa di Processo';
    case DocumentTypology.IstruzioneOperativa:
      return 'Istruzione Operativa';
    case DocumentTypology.ProceduraOperativa:
      return 'Procedura Operativa';
    case DocumentTypology.PDTA:
      return 'PDTA';
    case DocumentTypology.PianoAziendale:
      return 'Piano Aziendale';
    case DocumentTypology.DocumentoOrganizzativo:
      return 'Documento Organizzativo';

    case DocumentTypology.ConsensoFoglioInformativo:
      return 'Consenso Foglio Informativo';

    case DocumentTypology.Regolamento:
      return 'Regolamento';
  }
  assertNever(type);
};
