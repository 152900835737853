import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';

/**
 * Rappresentazione DTO della classe ReviewDocument definita in: src/server/schema/flow/review-document/ReviewDocument.entity.ts
 * Hash: 0887af1f7d9034057721261a50dfddb1
 */
@ValidationSchema(() => ReviewDocumentSchema)
export class ReviewDocumentDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ type: String, description: 'Percorso documento' })
  filePath!: string;
  @ApiProperty({ type: String, description: 'Nome file' })
  filename!: string;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;
  @ApiProperty({ type: Number, description: 'Id Evento' })
  eventId!: number;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<ReviewDocumentDto>) {
    if (values != null) {
      Object.assign(this, values instanceof ReviewDocumentDto ? values : plainToClass(ReviewDocumentDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await ReviewDocumentSchema.validate(classToPlain(this), options);
    return new ReviewDocumentDto(validated);
  }
}

/** Interfaccia simmetrica al DTO ReviewDocumentDto */
export type IReviewDocumentType = IDto<ReviewDocumentDto>;

/**
 * DTO Paginato della classe ReviewDocument
 */
export class PaginatedReviewDocumentDto {
  @ApiProperty({ type: [ReviewDocumentDto] })
  @Type(() => ReviewDocumentDto)
  items!: ReviewDocumentDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const ReviewDocumentSchema = yup
  .object({
    id: yup.number(),
    eventId: yup.number().required().label('Id Evento')
  })
  .noUnknown()
  .meta({ schemaName: "ReviewDocumentSchema" })
  .required();
