import { Reducer } from 'redux';
import { createAction, ActionTypes } from 'client/core/store/actions';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { IClientConfig } from 'common/config/IClientConfig';
import { fromPairs } from 'lodash';
import { IClientModuleConfig } from 'common/config/IModulesConfig';
import { SettingLogic } from 'common/schema/settings/SettingLogic';
import { SettingKey } from 'common/schema/settings/SettingVariables';

export const ClientConfigActions = {
  loaded: createAction('@clientConfig/loaded', (config: IClientConfig) => ({
    config
  }))
};

export interface ClientConfigState {
  config: IClientConfig | undefined;
  transformedModules: {
    [key: string]: IClientModuleConfig;
  };
  transformedTheme: {
    options: { [key: string]: any };
    labels: { [key: string]: string };
  };
}

export const ClientConfigReducer: Reducer<
  ClientConfigState,
  ActionTypes<typeof ClientConfigActions>
> = (
  state = {
    config: undefined,
    transformedModules: {},
    transformedTheme: {
      options: {},
      labels: {}
    }
  },
  action
) => {
  switch (action.type) {
    /**
     * Al caricamento, salviamo la configurazione
     */
    case '@clientConfig/loaded':
      return {
        ...state,
        config: action.payload.config,
        transformedModules: fromPairs(
          action.payload.config.modules.map(m => [m.feature, m])
        ),
        transformedTheme: {
          options: fromPairs(
            action.payload.config.theme.options.map(o => [o.name, o.value])
          ),
          labels: fromPairs(
            action.payload.config.theme.labels.map(l => [l.name, l.text])
          )
        }
      };

    default:
      return state;
  }
};

/**
 * Hook per ottenere la configurazione corrente
 */
export const useClientConfig = () =>
  useSelector((state: RootState) => state.config.config, shallowEqual);

/**
 * Hook per ottenere le opzioni di configurazione per l'UI (tema)
 */
export const useThemeConfig = () =>
  useSelector(
    (state: RootState) => state.config.transformedTheme,
    shallowEqual
  );

/**
 * Hook per ottenere le opzioni di configurazione per dei moduli
 */
export const useModulesConfig = () =>
  useSelector(
    (state: RootState) => state.config.transformedModules,
    shallowEqual
  );

export const useModuleConfigOption = (feature: string, option: string) =>
  useSelector(
    (state: RootState) =>
      state.config.transformedModules[feature]?.options?.find(
        o => o.name === option
      ),
    shallowEqual
  );

export const useModuleConfigEnum = (feature: string, key: string) =>
  useSelector(
    (state: RootState) =>
      state.config.transformedModules[feature]?.enums?.find(o => o.key === key),
    shallowEqual
  );

export const useModuleConfigOptions = (feature: string) =>
  useSelector(
    (state: RootState) => state.config.transformedModules[feature]?.options,
    shallowEqual
  );

/**
 * Hook per recuperare un valore dai Settings (configurazione impostabile lato utente)
 */
export const useSettingsConfigOption = (key: SettingKey) => {
  const config = useClientConfig();
  if (!config) {
    console.warn(`Configurazione non torvata.`);
    return undefined;
  }
  const { settings } = config;

  return SettingLogic.getSettingValue(key, settings[key]);
};

export const useDocumentalConfigOption = (option: string) => {
  const features = useClientConfig()?.documental.features;
  return useModuleConfigOption(features?.configFeatureName ?? 'none', option);
};

/**
 * Hook che ritorna la configurazione relativa all'attivazione della firma digitale demo
 */
export const useDemoSignatureEnabledConfigOption = () => {
  const enabled = useSettingsConfigOption(
    'DIGITAL_SIGNATURE_ENABLED'
  ) as Maybe<boolean>;

  if (enabled == null) {
    if (process.env.NODE_ENV === 'development') {
      console.warn("[useDemoSignatureEnabledConfigOption] Modulo o opzione firma digitale non trovato"); // prettier-ignore
    }
    return false;
  }
  return enabled;
};
