import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { RoleName } from "common/permissions/Roles";



@GenerateDto()
class UserAuthorities {
  roles!: RoleName[];
}

/**
 * Rappresentazione DTO della classe UserAuthorities 
 * Hash: 3c8eee929eb2ceb362669b52c0f15e86
 */
@ValidationSchema(() => UserAuthoritiesSchema)
export class UserAuthoritiesDto {
  @ApiProperty({ })
  roles!: ("SuperAdmin" | "DocumentalUser" | "GenericUser" | "Compiler" | "QualitySecretary" | "QualityManager" | "RiskManager" | "Approver" | "StrategicDirector")[];

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UserAuthoritiesDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UserAuthoritiesDto ? values : plainToClass(UserAuthoritiesDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UserAuthoritiesSchema.validate(classToPlain(this), options);
    return new UserAuthoritiesDto(validated);
  }
}

export const UserAuthoritiesSchema = yup
  .object({
    roles: yup.array(yup.mixed().required()).required()
  })
  .noUnknown()
  .meta({ schemaName: "UserAuthoritiesSchema" })
  .required();
