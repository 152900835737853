import React, { useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { logger } from 'client/core/logger/logger';
import { Col, Radio, Row } from 'antd';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { useAuth } from 'client/components/auth/AuthModule';
import { LoginForm } from 'client/components/login/LoginForm';
import { LdapLoginForm } from 'client/components/auth/strategies/ldap/LdapLoginForm';

interface Props extends RouteComponentProps {}

/**
 * Disponibile soltanto se l'utente non ha ancora effettuato il login.
 * In caso di successo, lo reindirizza alla pagina corretta.
 */
export const LoginPage = (props: Props) => {
  const { logged } = useAuth();

  const authTypeState = useState<'ldap' | 'local'>('ldap');

  // Calcolo l'origine
  const { from } = (props.location.state as any) || { from: { pathname: '/' } };

  logger.log(`Redirect utente dopo login: ->`, from);
  if (logged) return <Redirect to={from} />;

  return (
    <PageLayout>
      <Row justify="center" align="middle" style={{ width: '100%' }}>
        <Col xs={20} md={12}>
          {authTypeState[0] === 'local' ? (
            <LoginForm authTypeState={authTypeState} />
          ) : (
            <LdapLoginForm authTypeState={authTypeState} />
          )}
        </Col>
      </Row>
    </PageLayout>
  );
};
