import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";


@GenerateDto()
class LdapAuth {
  username!: string;
  password!: string;
}

/**
 * Rappresentazione DTO della classe LdapAuth 
 * Hash: b537d6453bad5b99ac4f80b82ead2731
 */
@ValidationSchema(() => LdapAuthSchema)
export class LdapAuthDto {
  @ApiProperty({ type: String })
  username!: string;
  @ApiProperty({ type: String })
  password!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<LdapAuthDto>) {
    if (values != null) {
      Object.assign(this, values instanceof LdapAuthDto ? values : plainToClass(LdapAuthDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await LdapAuthSchema.validate(classToPlain(this), options);
    return new LdapAuthDto(validated);
  }
}

export const LdapAuthSchema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required()
  })
  .noUnknown()
  .meta({ schemaName: "LdapAuthSchema" })
  .required();
