import { ExclamationCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, ButtonProps, message, Modal, Tooltip } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { ValidationErrorModal } from 'client/components/errors/validation/ValidationErrorModal';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { RunApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto, FlowSchema } from 'common/dto/generated/FlowDto';
import { FlowLogic } from 'common/schema/flow/FlowLogic';
import { FlowActionList, FlowMachine } from 'common/schema/flow/FlowMachine';
import { FlowState } from 'common/schema/flow/FlowState';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import { IFlowActionApiInput, IFlowNeedRevisionApiInput } from '../../FlowApi';
import { FlowRevisionModal } from './modal/FlowRevisionModal';
import { FlowStateTransitionModal } from './modal/FlowStateTransitionModal';

export interface IFlowRevisionButtonProps {
  data: FlowDto;
  modalTitle: string;
  modalContent: string;
  sendButtonText: string;
  sendButtonProps?: ButtonProps;
  initialState: FlowState | FlowState[];
  nextState: FlowState;
  onSend: RunApiMutation<IFlowNeedRevisionApiInput, any>;
}

/**
 * Pulsante generico per gestire le azioni di richiesta di modifica
 */
export function FlowRevisionButton(props: IFlowRevisionButtonProps) {
  const {
    data,
    modalTitle,
    modalContent,
    sendButtonText,
    initialState,
    nextState,
    onSend
  } = props;

  const [visibile, setVisible] = useState(false);
  const user = useCurrentUser();

  const { dirty } = useFormikContext();

  if (!FlowLogic.isState(data, initialState)) return null;

  const { allowed } = FlowMachine.to(data, nextState, user);

  if (!allowed) return null;

  return (
    <>
      <Tooltip
        title={dirty ? 'Salva il documento prima di inviarlo' : undefined}
      >
        <Button
          {...props.sendButtonProps}
          size="large"
          type="primary"
          ghost
          onClick={() => setVisible(true)}
          disabled={dirty}
        >
          {sendButtonText}
        </Button>
      </Tooltip>
      <FlowRevisionModal
        visible={visibile}
        setVisible={setVisible}
        data={data}
        modalTitle={modalTitle}
        modalContent={modalContent}
        sendButtonText={sendButtonText}
        sendButtonProps={{
          type: 'primary',
          size: 'large',
          ...props.sendButtonProps
        }}
        nextState={nextState}
        onSend={onSend}
      />
    </>
  );
}
