import React from 'react';
import { Section } from 'client/ui/section/Section';
import { UserOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { FlowState } from 'common/schema/flow/FlowState';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { useFormikContext } from 'formik';

export interface DocumentQualityDataSectionProps {}

/**
 * Altri dati del documento che vengono gestiti dalla Segreteria Qualità
 */
export function DocumentQualityDataSection(
  props: DocumentQualityDataSectionProps
) {
  const flowData = useFormikContext<FlowDto>();
  const showProcessData = ![FlowState.Draft, FlowState.Submitted].includes(
    flowData.values.state
  );

  if (!showProcessData) return null;

  return (
    <Section
      icon={<UserOutlined />}
      title="Altri Dati"
      hasPadding
      extra={<>A cura della Segreteria Qualità</>}
    >
      <FormFieldsContainer>
        <FormFieldItem
          component={TextInput}
          label="Dato Segreteria"
          name="data1"
          placeholder="Dato Segreteria"
        />
        <FormFieldItem
          component={TextInput}
          label="Altro Dato"
          name="data2"
          placeholder="Altro Dato"
        />
      </FormFieldsContainer>
    </Section>
  );
}
