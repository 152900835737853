import { InfoBoxActions } from 'client/ui/info-box/InfoBoxActions';
import { useFormikContext } from 'formik';
import React from 'react';

export interface ISettingSaveInfoBoxProps {}

export function SettingSaveInfoBox(props: ISettingSaveInfoBoxProps) {
  const {} = props;
  const formik = useFormikContext();

  if (!formik.dirty) {
    return null;
  }
  return (
    <InfoBoxActions
      title="Modifiche alla configurazione"
      text="Sono presenti modifiche alla configurazione. Salvare le modifiche prima di proseguire."
      actionButton={{
        children: 'Salva',
        onClick: async e => await formik.submitForm()
      }}
    />
  );
}
