import { yup } from 'common/validation/initYup';

export const createAccessTokenValidator = yup
  .object({
    login: yup.string().required().label('Nome Utente'),
    password: yup.string().required().label('Password')
  })
  .required();

export type ICreateAccessTokenDto = yup.InferType<
  typeof createAccessTokenValidator
>;
