import { Alert, Button, Card, message } from 'antd';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { SelectInputItem } from 'client/ui/form/input/SelectInputItem';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import {
  UserAuthoritiesSelectDto,
  UserAuthoritiesSelectSchema
} from 'common/dto/documental-bridge/UserAuthoritiesSelectDto';
import { Roles } from 'common/permissions/Roles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthActions, useCurrentUser } from '../auth/AuthModule';
import { UserApi } from '../schema/user/UserApi';

export interface IRoleSelectFormProps {}

export function RoleSelectForm(props: IRoleSelectFormProps) {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const [roleSelection, { loading }] = useApiMutation(
    UserApi.documental.authorities.set,
    {}
  );

  const history = useHistory();

  if (!currentUser) {
    return (
      <Alert
        type="error"
        message="Si è verificato un errore durante il login"
        showIcon
      />
    );
  }

  return (
    <FormikAugmented<UserAuthoritiesSelectDto>
      initialValues={
        new UserAuthoritiesSelectDto({
          role: currentUser.selectedRole ?? currentUser.avaiableRoles[0].role
        })
      }
      validationSchema={UserAuthoritiesSelectSchema}
      onSubmit={async value => {
        try {
          await roleSelection({ data: { input: value } });
          const result = await UserApi.findCurrentUser({});
          dispatch(AuthActions.currentLoaded(result.data));
          message.success('Accesso completato');
          history.push('/');
        } catch (e) {
          console.error(`Si è verificato un errore durante la selezione del ruolo "${value.role}`, {error: e} ); // prettier-ignore
          message.error(`Si è verificato un errore. Riprovare.`); // prettier-ignore
        }
      }}
      validationContext={{}}
    >
      <LoaderSpin spinning={loading} transparent>
        <Card bordered={false} style={{ minWidth: '330px' }}>
          <FormikForm
            layout="vertical"
            editable={true}
            helpInTooltips
            hideSuccess
          >
            <FormFieldItem
              component={SelectInputItem}
              name="role"
              label="Ruolo"
              placeholder="Selezionare ruolo"
              options={currentUser?.avaiableRoles.map(role => ({
                label: Roles[role.role].displayName,
                value: role.role
              }))}
            />
            <Button htmlType="submit" type="primary" style={{ width: '100%' }}>
              Seleziona
            </Button>
          </FormikForm>
        </Card>
      </LoaderSpin>
    </FormikAugmented>
  );
}
