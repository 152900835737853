import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowState } from 'common/schema/flow/FlowState';
import React from 'react';
import { FlowApi } from '../../../FlowApi';
import { FlowStateTransitionButton } from '../FlowStateTransitionButton';

export interface IFlowToQualityCheckButtonProps {
  data: FlowDto;
}

export function FlowToQualityCheckButton(
  props: IFlowToQualityCheckButtonProps
) {
  const { data } = props;

  const [toQualityCheck] = useApiMutation(FlowApi.actions.toQualityCheck, {});

  return (
    <FlowStateTransitionButton
      data={data}
      modalTitle="Inviare al controllo qualità?"
      modalContent="Il documento verrà presentato al responabilità della qualità. Una volta inviato non sarà possibile apportare modifiche."
      sendButtonText="Invia in Approvazione"
      initialState={FlowState.DocumentCheck}
      nextState={FlowState.QualityCheck}
      onSend={toQualityCheck}
    />
  );
}
