import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaNotRequired, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";


/**
 * Input per la gestione dell'approvazione del flusso.
 * Carica il documento firmato dal direttore/direzione insieme a tutte le informazioni necessarie.
 */
@GenerateDto()
class FlowApproveData {

    @SchemaLabel('ID Documento')
    documentId!: number;

    @SchemaLabel('Note')
    @SchemaNotRequired()
    notes?: string | null;   

    // La validazione della presenza del file è gestita in modo dinamico
    // in base al valore di fileValidation, valorizzato solo FE, dato che BE viene inviato il file attraverso
    // FormData e non è presente quindi nel body della richiesta sotto forma di base64.
    @SchemaLabel('Nome file')
    @SchemaValidate(schema => schema.when('$fileValidation', (fileValidation, schema ) => fileValidation ? schema.required() : schema))
    filename?: string | null;

    @SchemaLabel('Contenuto file') // base64
    @SchemaValidate(schema => schema.when('$fileValidation', (fileValidation, schema ) => fileValidation ? schema.required('È obbligatorio caricare il documento firmato') : schema))
    content?: string | null;
}

/**
 * Rappresentazione DTO della classe FlowApproveData 
 * Hash: 87144e9bdb881109fabbb48926883ba4
 */
@ValidationSchema(() => FlowApproveDataSchema)
export class FlowApproveDataDto {
  @ApiProperty({ type: Number, description: 'ID Documento' })
  documentId!: number;
  @ApiProperty({ required: false, type: String, description: 'Note' })
  notes?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome file' })
  filename?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Contenuto file' })
  content?: string | null | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<FlowApproveDataDto>) {
    if (values != null) {
      Object.assign(this, values instanceof FlowApproveDataDto ? values : plainToClass(FlowApproveDataDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await FlowApproveDataSchema.validate(classToPlain(this), options);
    return new FlowApproveDataDto(validated);
  }
}

export const FlowApproveDataSchema = yup
  .object({
    documentId: yup.number().required().label('ID Documento'),
    notes: yup.string().nullable().label('Note'),
    filename: yup.string().nullable().when('$fileValidation', (fileValidation, schema ) => fileValidation ? schema.required() : schema).label('Nome file'),
    content: yup.string().nullable().when('$fileValidation', (fileValidation, schema ) => fileValidation ? schema.required('È obbligatorio caricare il documento firmato') : schema).label('Contenuto file')
  })
  .noUnknown()
  .meta({ schemaName: "FlowApproveDataSchema" })
  .required();
