import { SendOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import { Space, Button, Row, Col, Typography, message, Menu } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { DrawerActionsBar } from 'client/ui/actions-bar/DrawerActionsBar';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ModalDialog } from 'client/ui/modal/ModalDialog';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import {
  UpdateUserEmailBodyDto,
  UpdateUserEmailBodySchema
} from 'common/dto/query/UpdateUserEmailBodyDto';
import { Roles } from 'common/permissions/Roles';
import React from 'react';
import { UserApi } from './UserApi';
const { Text } = Typography;

export interface IUserPreferencesModalMenuProps {}

export function UserPreferencesModalMenu(
  props: IUserPreferencesModalMenuProps
) {
  const [visible, setVisible] = React.useState(false);
  const user = useCurrentUser();
  const { response, error, loading: userLoading } = useApiQuery(
    UserApi.getUserEmail,
    {
      data: {},
      skip: !user || user?.getRole() !== Roles.Compiler
    }
  );

  const [updateEmail, { loading }] = useApiMutation(UserApi.updateEmail, {
    invalidates: [UserApi.getUserEmail]
  });

  if (userLoading || error) {
    return null;
  }

  if (user?.getRole() !== Roles.Compiler) {
    // le preferenze di invio email valgono solo per gli utenti redattori
    return null;
  }

  const email = response?.data.email;

  return (
    <>
      <Menu.Item
        key="user-preferences"
        style={email ? undefined : { border: '1px solid #faad14' }}
        icon={
          email ? (
            <UserOutlined />
          ) : (
            <WarningOutlined style={{ color: '#faad14' }} />
          )
        }
        onClick={() => setVisible(true)}
      >
        Preferenze
      </Menu.Item>

      <FormikAugmented<UpdateUserEmailBodyDto>
        initialValues={new UpdateUserEmailBodyDto({ email } as any)}
        enableReinitialize
        validationContext={{ user }}
        validationSchema={UpdateUserEmailBodySchema}
        onSubmit={async rawValues => {
          try {
            const values = await rawValues.validate();
            await updateEmail({ data: { body: values } });
            message.success(`Email aggiornata correttamente`);
            setVisible(false);
          } catch (e) {
            console.error({ error: e });
            message.error(`Errore durante l'inserimento della email. Riprovare.`); // prettier-ignore
          }
        }}
        validateOnBlur={true}
        validateOnChange={false}
      >
        <ModalDialog
          destroyOnClose
          size="medium"
          visible={visible}
          onCancel={() => setVisible(false)}
          title="Preferenze di invio email"
          footer={
            <DrawerActionsBar fixedWidht>
              <Space>
                <Button onClick={() => setVisible(false)} size="large">
                  Annulla
                </Button>
                <FormikSendButton size="large" type="primary" loading={loading}>
                  Aggiorna email <SendOutlined />
                </FormikSendButton>
              </Space>
            </DrawerActionsBar>
          }
        >
          <FormikForm
            showVisualFeedback={true}
            editable={true}
            layout="vertical"
          >
            <Row gutter={40}>
              <Col span={20} offset={2}>
                <VerticalSpace size={5} />
                {!email && (
                  <>
                    <Text type="warning">
                      Nessun indirizzo email indicato. Senza un indirizzo mail
                      valido non sarà possibile ricevere notifiche
                      sull'avanzamento del flusso.
                    </Text>
                    <VerticalSpace size={5} />
                  </>
                )}

                <Text>
                  È possibile specificare l'indirizzo email che sarà utilizzato
                  per l'invio delle notifiche relative ai propri documenti.
                </Text>
                <VerticalSpace size={20} />
                <FormFieldsContainer>
                  <FormFieldItem
                    component={TextInput}
                    type="email"
                    label={null}
                    name="email"
                    placeholder="Inserisci l'email..."
                  />
                </FormFieldsContainer>
              </Col>
            </Row>
          </FormikForm>
        </ModalDialog>
      </FormikAugmented>
    </>
  );
}
