import { SendOutlined } from '@ant-design/icons';
import { Tooltip, Button, ButtonProps } from 'antd';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { RunApiMutation } from 'client/core/network/hooks/useApiMutation';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowLogic } from 'common/schema/flow/FlowLogic';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { FlowState } from 'common/schema/flow/FlowState';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { IFlowActionApiInput } from '../../FlowApi';
import { FlowStateTransitionModal } from './modal/FlowStateTransitionModal';

export interface IFlowStateTransitionButtonProps {
  data: FlowDto;
  modalTitle: string;
  modalContent: string;
  sendButtonText: string;
  sendButtonProps?: ButtonProps;
  initialState: FlowState | FlowState[];
  nextState: FlowState;
  onSend: RunApiMutation<IFlowActionApiInput, any>;
  hideIcon?: boolean;
}

/**
 * Componente generico che gestisce il passaggio di stato di un flusso.
 */
export function FlowStateTransitionButton(
  props: IFlowStateTransitionButtonProps
) {
  const {
    data,
    modalTitle,
    modalContent,
    sendButtonText,
    initialState,
    nextState,
    onSend
  } = props;

  const [visibile, setVisible] = useState(false);
  const user = useCurrentUser();

  const { dirty } = useFormikContext();

  if (!FlowLogic.isState(data, initialState)) return null;

  const { allowed } = FlowMachine.to(data, nextState, user);

  if (!allowed) return null;

  return (
    <>
      <Tooltip
        title={dirty ? 'Salva il documento prima di inviarlo' : undefined}
      >
        <Button
          {...props.sendButtonProps}
          size="large"
          type="primary"
          onClick={() => setVisible(true)}
          disabled={dirty}
        >
          {sendButtonText} {!props.hideIcon && <SendOutlined />}
        </Button>
      </Tooltip>
      <FlowStateTransitionModal
        visible={visibile}
        setVisible={setVisible}
        data={data}
        modalTitle={modalTitle}
        modalContent={modalContent}
        sendButtonText={sendButtonText}
        sendButtonProps={{
          type: 'primary',
          size: 'large',
          ...props.sendButtonProps
        }}
        nextState={nextState}
        onSend={onSend}
        hideIcon={props.hideIcon}
      />
    </>
  );
}
