import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';

/**
 * Rappresentazione DTO della classe DocumentInstruction definita in: src/server/schema/document-typology/document-instruction/DocumentInstruction.entity.ts
 * Hash: 06ac710c8ad8a3cd99ceec0c37bc3248
 */
@ValidationSchema(() => DocumentInstructionSchema)
export class DocumentInstructionDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ enum: ['AnalisiProcesso', 'MappaProcesso', 'IstruzioneOperativa', 'ProceduraOperativa', 'PDTA', 'PianoAziendale', 'DocumentoOrganizzativo', 'ConsensoFoglioInformativo', 'Regolamento'], description: 'Tipo Documento' })
  documentType!: DocumentTypology;
  @ApiProperty({ required: false, type: String, description: 'Percorso documento' })
  filePath?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome file' })
  filename?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<DocumentInstructionDto>) {
    if (values != null) {
      Object.assign(this, values instanceof DocumentInstructionDto ? values : plainToClass(DocumentInstructionDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await DocumentInstructionSchema.validate(classToPlain(this), options);
    return new DocumentInstructionDto(validated);
  }
}

/** Interfaccia simmetrica al DTO DocumentInstructionDto */
export type IDocumentInstructionType = IDto<DocumentInstructionDto>;

/**
 * DTO Paginato della classe DocumentInstruction
 */
export class PaginatedDocumentInstructionDto {
  @ApiProperty({ type: [DocumentInstructionDto] })
  @Type(() => DocumentInstructionDto)
  items!: DocumentInstructionDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const DocumentInstructionSchema = yup
  .object({
    id: yup.number(),
    documentType: yup.string().oneOfEnum(DocumentTypology).required().label('Tipo Documento')
  })
  .noUnknown()
  .meta({ schemaName: "DocumentInstructionSchema" })
  .required();
