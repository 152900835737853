import { IFlowType } from 'common/dto/generated/FlowDto';
import { ActionList, EntityMachine } from 'common/fsm/EntityMachine';
import { Roles } from 'common/permissions/Roles';
import { UserLogic } from '../user/UserLogic';
import { DocumentTypology } from './DocumentTypology';
import { FlowLogic } from './FlowLogic';
import { FlowState } from './FlowState';
import { FlowType } from './FlowType';

export const FlowMachine = EntityMachine.for<IFlowType>()
  .withState(flow => flow?.state ?? FlowState.Draft)
  // Se l'utente ha accesso alla modifica del flusso
  .withAction('edit', {
    allowed: (flow, user) => {
      if (!user) return false;

      const allowedStates = FlowLogic.getEditableStatesByRole(user!);
      const isOwned = flow.ownerId === user.id;
      const isInWorkingGroup = FlowLogic.isInWorkingGroup(flow, user);

      // Se è salvata e se l'utente può solo accedere ai suoi flussi,
      if (flow.id && UserLogic.canOnlyReadOwned(user)) {
        const isAssigned = flow.workingUserId === user.id;
        // deve esserne il proprietario oppure far parte del working group, e deve essere assegnata a lui
        return (
          (isOwned || isInWorkingGroup) &&
          isAssigned &&
          allowedStates.includes(flow.state)
        );
      }

      return allowedStates.includes(flow.state);
    }
  })
  // Se l'utente ha accesso in sola lettura al flusso
  .withAction('view', {
    allowed: (flow, user) => {
      if (!user) return false;

      const allowedStates = FlowLogic.getVisibleStatesByRole(user!);
      const isOwned = flow.ownerId === user.id;
      const isInWorkingGroup = FlowLogic.isInWorkingGroup(flow, user);

      // Se è salvata e se l'utente può solo accedere ai suoi flussi, deve esserne il proprietario
      if (flow.id && UserLogic.canOnlyReadOwned(user)) {
        // deve esserne il proprietario oppure far parte del working group
        return (
          (isOwned || isInWorkingGroup) && allowedStates.includes(flow.state)
        );
      }

      return allowedStates.includes(flow.state);
    }
  })
  // Se l'utente può prendere in carico il flusso
  .withAction('selfAssign', {
    allowed: (flow, user) => {
      if (!user) return false;
      if (!flow.id) return false;

      // Soltanto i redattori possono prendere in carico
      if (user.getRole() !== Roles.Compiler) return false;

      // Se è già in carico a qualcuno, non può essere preso in carico
      if (flow.workingUserId) return false;

      const allowedStates = FlowLogic.getEditableStatesByRole(user!);
      // Soltanto gli utenti proprietari e nel gruppo di lavoro possono prendere in carico
      const isOwned = flow.ownerId === user.id;
      const isInWorkingGroup = FlowLogic.isInWorkingGroup(flow, user);

      return (
        (isOwned || isInWorkingGroup) && allowedStates.includes(flow.state)
      );
    }
  })
  // Se l'utente può rilasciare il flusso
  .withAction('release', {
    allowed: (flow, user) => {
      if (!user) return false;
      if (!flow.id) return false;
      if (!flow.workingUserId) return false;

      const allowedStates = FlowLogic.getEditableStatesByRole(user!);

      // Soltanto l'utente assegnato e il responsabile possono rilasciare
      return (
        (flow.workingUserId === user.id || flow.ownerId === user.id) &&
        allowedStates.includes(flow.state)
      );
    }
  })
  // Se l'utente può creare un main document
  .withAction('uploadFlowDocument', {
    allowed: (flow, user) => {
      if (!user) return false;

      // Soltanto i medici possono uploadare il main document
      if (user.getRole() !== Roles.Compiler) return false;

      const allowedStates = FlowLogic.getEditableStatesByRole(user!);
      const isOwned = flow.ownerId === user.id;
      const isInWorkingGroup = FlowLogic.isInWorkingGroup(flow, user);
      // Se è salvata e se l'utente può solo accedere ai suoi flussi,
      if (flow.id && UserLogic.canOnlyReadOwned(user)) {
        const isAssigned = flow.workingUserId === user.id;
        // deve esserne il proprietario oppure far parte del working group, e deve essere assegnata a lui
        return (
          (isOwned || isInWorkingGroup) &&
          isAssigned &&
          allowedStates.includes(flow.state)
        );
      }

      return allowedStates.includes(flow.state);
    }
  })
  // Se l'utente può creare un main document ma non ha in carico il documento
  .withAction('readOnlyFlowDocument', {
    allowed: (flow, user) => {
      if (!user) return false;

      // Soltanto i medici possono uploadare il main document
      if (user.getRole() !== Roles.Compiler) return false;

      const allowedStates = FlowLogic.getEditableStatesByRole(user!);
      const isOwned = flow.ownerId === user.id;
      const isInWorkingGroup = FlowLogic.isInWorkingGroup(flow, user);
      // Se è salvata e se l'utente può solo accedere ai suoi flussi,
      if (flow.id && UserLogic.canOnlyReadOwned(user)) {
        const isAssigned = flow.workingUserId === user.id;
        // deve esserne il proprietario oppure far parte del working group,
        // e NON deve essere assegnata a lui
        return (
          (isOwned || isInWorkingGroup) &&
          !isAssigned &&
          allowedStates.includes(flow.state)
        );
      }

      return allowedStates.includes(flow.state);
    }
  })
  // Eliminazione flusso
  .withAction('delete', {
    // Soltanto il redattore proprietario può eliminare il flusso se non è ancora stato inviato
    allowed: (flow, user) => {
      if (flow.state !== FlowState.Draft) return false;
      if (user?.getRole() !== Roles.Compiler) {
        return false;
      }
      if (flow.ownerId !== user.id) {
        return false;
      }
      return true;
    }
  })

  /**
   *
   * Draft -> DocumentCheck -> QualityCheck -> Review           -> Approved/Rejected
   * Draft -> DocumentCheck -> QualityCheck -> StrategicReview  -> Approved/Rejected
   * Draft -> DocumentCheck -> RiskCheck    -> Review           -> Approved/Rejected
   */
  // :| DOCUMENTO | REGOLAMENTO | CONSENSI |: Redattore -> Segreteria
  .withTransition(FlowState.Draft, {
    to: FlowState.DocumentCheck,
    allowed: (flow, user) => {
      if (user?.getRole() !== Roles.Compiler) {
        return false;
      }
      if (flow.ownerId !== user.id) {
        return false;
      }
      return true;
    }
  })
  .withTransition(FlowState.Submitted, {
    to: FlowState.DocumentCheck,
    allowed: (flow, user) => {
      if (user?.getRole() !== Roles.Compiler) {
        return false;
      }
      if (flow.ownerId !== user.id) {
        return false;
      }
      return true;
    }
  })

  // :| DOCUMENTO | REGOLAMENTO |: Segreteria -> Responsabile
  .withTransition(FlowState.DocumentCheck, {
    to: FlowState.QualityCheck,
    allowed: (flow, user) => {
      if (![FlowType.Documenti, FlowType.Regolamenti].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.QualitySecretary;
    }
  })

  // :| CONSENSI |: Segreteria -> Responsabile
  .withTransition(FlowState.DocumentCheck, {
    to: FlowState.RiskCheck,
    allowed: (flow, user) => {
      if (![FlowType.ConsensiInformati].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.QualitySecretary;
    }
  })

  // :| DOCUMENTO |: Responsabile -> Direttore
  .withTransition(FlowState.QualityCheck, {
    to: FlowState.Review,
    allowed: (flow, user) => {
      if (![FlowType.Documenti].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.QualityManager;
    }
  })
  // :| REGOLAMENTO |: Responsabile -> Direzione Strategica
  .withTransition(FlowState.QualityCheck, {
    to: FlowState.StrategicReview,
    allowed: (flow, user) => {
      if (![FlowType.Regolamenti].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.QualityManager;
    }
  })
  // :| CONSENSO |: RiskManager -> Direttore
  .withTransition(FlowState.RiskCheck, {
    to: FlowState.Review,
    allowed: (flow, user) => {
      if (![FlowType.ConsensiInformati].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.RiskManager;
    }
  })

  // :| DOCUMENTO | CONSENSI |: Direttore -> Segreteria
  .withTransition(FlowState.Review, {
    to: FlowState.Approved,
    allowed: (flow, user) => {
      if (
        ![FlowType.Documenti, FlowType.ConsensiInformati].includes(
          flow.flowType
        )
      ) {
        return false;
      }
      return user?.getRole() === Roles.Approver;
    }
  })
  // :| REGOLAMENTO |: Direzione Strategica -> Segreteria
  .withTransition(FlowState.StrategicReview, {
    to: FlowState.Approved,
    allowed: (flow, user) => {
      if (![FlowType.Regolamenti].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.StrategicDirector;
    }
  })

  // :| DOCUMENTO | CONSENSI |: Direttore -> Rifiuto
  .withTransition(FlowState.Review, {
    to: FlowState.Rejected,
    allowed: (flow, user) => {
      if (
        ![FlowType.Documenti, FlowType.ConsensiInformati].includes(
          flow.flowType
        )
      ) {
        return false;
      }
      return user?.getRole() === Roles.Approver;
    }
  })
  // :| REGOLAMENTO |: Direttore -> Rigiuto
  .withTransition(FlowState.StrategicReview, {
    to: FlowState.Rejected,
    allowed: (flow, user) => {
      if (![FlowType.Regolamenti].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.StrategicDirector;
    }
  })

  //:| DOCUMENTO | REGOLAMENTO | CONSENSI |:  Segreteria qualità -> Pubblicazione
  .withTransition(FlowState.Approved, {
    to: FlowState.Published,
    allowed: (flow, user) => {
      return user?.getRole() === Roles.QualitySecretary;
    }
  })

  // Richiesta di modifiche
  .withTransition(FlowState.DocumentCheck, {
    to: FlowState.Submitted,
    allowed: (flow, user) => {
      return user?.getRole() === Roles.QualitySecretary;
    }
  })
  .withTransition(FlowState.QualityCheck, {
    to: FlowState.DocumentCheck,
    allowed: (flow, user) => {
      if (![FlowType.Documenti, FlowType.Regolamenti].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.QualityManager;
    }
  })
  .withTransition(FlowState.RiskCheck, {
    to: FlowState.DocumentCheck,
    allowed: (flow, user) => {
      if (![FlowType.ConsensiInformati].includes(flow.flowType)) {
        return false;
      }
      return user?.getRole() === Roles.RiskManager;
    }
  });

export type FlowActionList = ActionList<typeof FlowMachine>;
