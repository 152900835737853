import React, { useState } from 'react';
import clx from 'classnames';
import './DrawerActionsBar.scss';
import { Affix, Button, Space } from 'antd';

export interface DrawerActionsBarProps {
  children: React.ReactNode;
  fixedWidht?: boolean;
  fullWidht?: boolean;
}

export function DrawerActionsBar(props: DrawerActionsBarProps) {
  return (
    <div
      className={clx('drawer-action-bar', {
        'fixed-width': props.fixedWidht
      })}
    >
      {props.fullWidht ? (
        props.children
      ) : (
        <Space className="actions-container">{props.children}</Space>
      )}
    </div>
  );
}
