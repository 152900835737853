import { FlowDto } from 'common/dto/generated/FlowDto';
import { IDto } from 'common/dto/shared/DtoInterfaces';
import moment from 'moment';
import { FlowType } from 'common/schema/flow/FlowType';

export class FlowDocumentLogic {
  /** Ritorna l'ultima versione del documento */
  static getLastVersion(flow: IDto<FlowDto>) {
    if (!flow.documents?.length) return null;
    return flow.documents.find(
      doc => doc.version === Math.max(...flow.documents.map(doc => doc.version))
    );
  }

  static getLastVersionId(flow: IDto<FlowDto>) {
    const lastVersion = FlowDocumentLogic.getLastVersion(flow);
    return lastVersion ? lastVersion.id : null;
  }

  /**
   * Ritorna vero se il documento deve essere firmato per essere approvato
   */
  static needToBeSignedApproved(flow: IDto<FlowDto>) {
    return [FlowType.Documenti, FlowType.ConsensiInformati].includes(
      flow.flowType
    );
  }

  /** Ritorna tutte le revisioni di un documento (eventLogs e reviewDocuments associati) */
  static getReviews(
    flow: IDto<FlowDto>,
    documentId: number | null | undefined
  ) {
    if (!documentId) return [];
    const events = flow.eventLogs
      .filter(
        event =>
          event.mainDocumentId === documentId &&
          event.reviewId !== null &&
          event.notes !== null
      )
      .sort((a, b) =>
        moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1
      );

    return events;
  }
}
