import axios, { AxiosInstance } from 'axios';
import { refreshTokenInterceptor } from './interceptors/refreshTokenInterceptor';
import { errorsInterceptor } from './interceptors/errorsInterceptor';
import { retryInterceptor } from './interceptors/retryInterceptor';
import { appConfig } from '../appConfig';
import { joinPath } from './joinPath';
import { locationHrefInterceptor } from './interceptors/locationHrefInterceptor';
import { dtoSerializerInterceptor } from './interceptors/dtoSerializeInterceptor';
import { ApiClientRequest } from './ApiClientRequest';

declare module 'axios' {
  interface AxiosRequestConfig {
    dto?: Constructor<any>;
  }
}

/**
 * Client delle API che gestisce anche i DTO
 */
export class ApiClient {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: joinPath([appConfig.publicPath, process.env.REST_URL])
    });

    this.applyInterceptors();
  }

  private applyInterceptors() {
    locationHrefInterceptor(this.instance);
    refreshTokenInterceptor(this.instance);
    retryInterceptor(this.instance);
    errorsInterceptor(this.instance);
    dtoSerializerInterceptor(this.instance);
  }

  /**
   * Richiesta che specifica il DTO da utilizzare per effettuare il parsing
   * della risposta
   * @param dtoType La classe del DTO
   */
  requestWithResponseType<T extends Constructor<any>>(dtoType: T) {
    return new ApiClientRequest(this, dtoType);
  }

  /**
   * Semplice richiesta senza tipi specificati in risposta.
   * In realtà ritorna solo l'istanza di Axios sulla quale chiamare `get()`, ecc.
   */
  request() {
    return this.instance;
  }
}

export const apiClient = new ApiClient();
