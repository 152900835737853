import React from 'react';
import { Space } from 'antd';
import { Section } from 'client/ui/section/Section';
import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { UploadInput } from 'client/ui/form/input/UploadInput';
import { SectionButton } from 'client/ui/section/SectionButton';
import { DocumentTypologyTag } from 'client/routes/flows/common/document/typology/DocumentTypologyTag';
import { useFormikContext } from 'formik';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowDocumentLogic } from 'common/schema/flow/flow-document/FlowDocumentLogic';
import { DocumentListItem } from '../file/DocumentListItem';
import { AuthLink } from 'client/core/router/links/AuthLink';
import { FlowApi } from 'client/components/schema/flow/FlowApi';
import {
  flowAcceptedFileFormat,
  flowAcceptedFileFormatString
} from 'client/components/schema/flow/detail/flowAcceptedFileFormat';

export interface DocumentFileSectionProps {}

/**
 * Sezione per la gestione del documento corrente (upload e Download)
 */
export function DocumentFileSection(props: DocumentFileSectionProps) {
  const flowData = useFormikContext<FlowDto>();

  const lastVersionDocument = FlowDocumentLogic.getLastVersion(flowData.values);

  const templateDownloadLink = FlowApi.templates.downloadLink({
    type: flowData.values.documentType
  });
  const instructionDownloadLink = FlowApi.instructions.downloadLink({
    type: flowData.values.documentType
  });

  const templateDisabled = !flowData.values.documentTemplate?.filePath;
  const instructionDisabled = !flowData.values.documentInstructions?.filePath;

  return (
    <Section
      icon={<FileTextOutlined />}
      title={
        <Space>
          Documento{' '}
          <DocumentTypologyTag typology={flowData.values.documentType} />
        </Space>
      }
      hasPadding
      extra={
        <Space>
          <AuthLink href={instructionDownloadLink}>
            <a>
              <SectionButton
                children="Istruzioni"
                icon={<DownloadOutlined />}
                type="default"
                disabled={instructionDisabled}
              />
            </a>
          </AuthLink>

          <AuthLink href={templateDownloadLink}>
            <a>
              <SectionButton
                children="Template"
                icon={<DownloadOutlined />}
                type="default"
                disabled={templateDisabled}
              />
            </a>
          </AuthLink>
        </Space>
      }
    >
      {/* Nuovo componente per la gestione del documento */}
      <DocumentListItem
        document={lastVersionDocument}
        flowData={flowData.values}
        uploadArea={
          <FormFieldsContainer>
            <FormFieldItem
              inline
              component={UploadInput}
              accept={flowAcceptedFileFormatString}
              label={null}
              listType="picture"
              name="file"
              placeholder="Fai click o trascina un file in quest’area per caricare il Documento."
              hint={`Caricare il documento in formato accettato (${flowAcceptedFileFormat.join(
                ', '
              )})`}
              beforeUpload={(file: File) => {
                if (!file) return false;
                flowData.setFieldValue('file', file);
                return false;
              }}
              maxCount={1}
              onRemove={() => {
                flowData.setFieldValue('file', undefined);
              }}
            />
          </FormFieldsContainer>
        }
      />
    </Section>
  );
}
