import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { PaginatedMetaDto } from 'common/dto/shared/PaginatedDto';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';

/**
 * Rappresentazione DTO della classe DocumentTemplate definita in: src/server/schema/document-typology/document-template/DocumentTemplate.entity.ts
 * Hash: e8035039a8977336164d0360774dc8d3
 */
@ValidationSchema(() => DocumentTemplateSchema)
export class DocumentTemplateDto {
  @ApiProperty({ required: false, type: Number })
  id!: number;
  @ApiProperty({ enum: ['AnalisiProcesso', 'MappaProcesso', 'IstruzioneOperativa', 'ProceduraOperativa', 'PDTA', 'PianoAziendale', 'DocumentoOrganizzativo', 'ConsensoFoglioInformativo', 'Regolamento'], description: 'Tipo Documento' })
  documentType!: DocumentTypology;
  @ApiProperty({ required: false, type: String, description: 'Percorso documento' })
  filePath?: string | null | undefined;
  @ApiProperty({ required: false, type: String, description: 'Nome file' })
  filename?: string | null | undefined;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  createdAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  updatedAt!: Date;
  @ApiProperty({ required: false, type: String, format: 'date-time' })
  deletedAt?: Date | undefined;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<DocumentTemplateDto>) {
    if (values != null) {
      Object.assign(this, values instanceof DocumentTemplateDto ? values : plainToClass(DocumentTemplateDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await DocumentTemplateSchema.validate(classToPlain(this), options);
    return new DocumentTemplateDto(validated);
  }
}

/** Interfaccia simmetrica al DTO DocumentTemplateDto */
export type IDocumentTemplateType = IDto<DocumentTemplateDto>;

/**
 * DTO Paginato della classe DocumentTemplate
 */
export class PaginatedDocumentTemplateDto {
  @ApiProperty({ type: [DocumentTemplateDto] })
  @Type(() => DocumentTemplateDto)
  items!: DocumentTemplateDto[];
  @ApiProperty({ type: PaginatedMetaDto })
  meta!: PaginatedMetaDto;
}

export const DocumentTemplateSchema = yup
  .object({
    id: yup.number(),
    documentType: yup.string().oneOfEnum(DocumentTypology).required().label('Tipo Documento')
  })
  .noUnknown()
  .meta({ schemaName: "DocumentTemplateSchema" })
  .required();
