import { TagProps } from 'antd';
import { getFlowTypeColor } from '../../../../../components/schema/flow/flow-type/getFlowTypeColor';
import { FlowType } from '../../../../../../common/schema/flow/FlowType';
import { getDocumentTypologyLabel } from './getDocumentTypologyLabel';
import { DocumentTypology } from 'common/schema/flow/DocumentTypology';
import { FlowLogic } from 'common/schema/flow/FlowLogic';

export interface IDocumentTypology {
  flowType: FlowType;
  documentType: DocumentTypology;
  label: string;
  color: TagProps['color'];
}

/**
 * Elenco delle tipologie di documenti gestite nei flussi di qualità
 * con le relative tipologie di flusso di qualità
 * e le relative etichette ed il colore per i Tag
 */
export const DocumentTypologyList: IDocumentTypology[] = Object.entries(
  DocumentTypology
).map(([key, value]) => ({
  flowType: FlowLogic.getFlowType(value),
  documentType: value,
  label: getDocumentTypologyLabel(value),
  color: getFlowTypeColor(FlowLogic.getFlowType(value))
}));
