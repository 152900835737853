import { useCallback } from 'react';
import { useNetworkContext } from '../context/NetworkContext';
import { ApiGet } from './useApiQuery';

export function useApiHelpers() {
  const network = useNetworkContext();

  const invalidates = useCallback(
    (queries: ApiGet<any, any>[]) => {
      network.invalidator.next({ queries });
    },
    [network]
  );

  return { invalidates };
}
