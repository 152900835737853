import { FlowState } from 'common/schema/flow/FlowState';
import { assertNever } from 'server/utils/typings/assertNever';

/**
 * Ottiene la Label dello stato del documento
 */
export const getFlowStateLabel = (state: FlowState) => {
  switch (state) {
    case FlowState.Draft:
      return 'Bozza';
    case FlowState.Submitted:
      return 'In Revisione';
    case FlowState.DocumentCheck:
      return 'Controllo Segreteria';
    case FlowState.QualityCheck:
      return 'Controllo Qualità';
    case FlowState.RiskCheck:
      return 'Controllo del Rischio';
    case FlowState.Review:
      return 'Approvazione';
    case FlowState.StrategicReview:
      return 'Approvazione';
    case FlowState.Approved:
      return 'Approvato';
    case FlowState.Rejected:
      return 'Respinto';
    case FlowState.Published:
      return 'Pubblicato';
  }
  assertNever(state);
};
