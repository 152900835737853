import {
  DeleteOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  SafetyOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Alert, Button, Col, message, Row, Space } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { DigitalSignatureProvider } from 'client/components/digital-signature/DigitalSignatureProvider';
import { useDemoSignatureEnabledConfigOption } from 'client/components/schema/config/ClientConfigModule';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useApiQuery } from 'client/core/network/hooks/useApiQuery';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { useLoadingScreenContext } from 'client/ui/loading-screen/LoadingScreenContext';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import { FlowApproveDataDto } from 'common/dto/query/FlowApproveDataDto';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { FlowDocumentApi } from '../schema/flow/FlowDocumentApi';

export interface IDigitalSignatureInputProps {
  documentId: number;
  flowId: number;
  /** Nome del campo formik del file in base64 */
  fileContentFieldName: string;
  /** Nome del campo formik del nome stesso del file */
  fileNameFieldName: string;
}

export function DigitalSignatureInput(props: IDigitalSignatureInputProps) {
  const { documentId, flowId, fileContentFieldName, fileNameFieldName } = props;

  const formik = useFormikContext<FlowApproveDataDto>(); // TODO tipizzare
  const loadingContext = useLoadingScreenContext();
  const demoSignatureEnabled = useDemoSignatureEnabledConfigOption();

  const { response, loading, error } = useApiQuery(FlowDocumentApi.getFile, {
    data: { documentId, flowId }
  });
  const documentFile = response?.data;

  const [graphicSignature, setGraphicSignature] = useState(true);

  /**
   * Esecuzione della configurazione della firma
   */
  const handleSetup = useCallback(async () => {
    try {
      loadingContext.setShown(true, 'Attendo configurazione firma...');
      const provider = await DigitalSignatureProvider.open();
      await provider.requestSetup();
    } catch (e: any) {
      console.error(e);
      message.error(`Impossibile completare la configurazione della Firma Digitale. ${e.message ?? e}`); // prettier-ignore
    } finally {
      loadingContext.setShown(false);
    }
  }, [loadingContext]);

  /**
   * Esecuzione della Firma Digitale
   */
  const handleSign = useCallback(async () => {
    if (!documentFile) {
      message.error('Non è stato possibile firmare il documento. Riprovare');
      return;
    }
    try {
      loadingContext.setShown(true, 'Attendo la firma del documento...');
      const provider = await DigitalSignatureProvider.open();
      const signed = await provider.requestPadesSignature(
        documentFile.content,
        documentFile.filename,
        { graphicSignature }
      );
      formik.setFieldValue(fileContentFieldName, signed.fileContent);
      formik.setFieldValue(fileNameFieldName, signed.fileName);
    } catch (e: any) {
      console.error(e);
      message.error(`Non è stato possibile firmare il documento. ${e.message ?? e}`); // prettier-ignore
    } finally {
      loadingContext.setShown(false);
    }
  }, [documentFile, graphicSignature, loadingContext]);

  /**
   * Rimozione file firmato
   */
  const handleUnlink = useCallback(() => {
    formik.setFieldValue(fileContentFieldName, undefined);
    formik.setFieldValue(fileNameFieldName, undefined);
  }, [formik]);

  // Firma "fittizia" per lo sviluppo
  const handleFakeSign = useCallback(() => {
    if (!documentFile) {
      message.error('Non è stato possibile firmare il documento. Riprovare');
      return;
    }
    if (!demoSignatureEnabled) {
      console.warn(`[NotificationSignatureInput] Demo signature disabled in production`); // prettier-ignore
      return;
    }
    formik.setFieldValue(fileContentFieldName, documentFile.content);
    formik.setFieldValue(fileNameFieldName, documentFile.filename);
  }, [documentFile, demoSignatureEnabled]);

  if (!documentFile || loading) {
    return <LoaderSpin>Carico documento di notifica...</LoaderSpin>;
  }

  if (error) {
    return (
      <Alert
        type="error"
        showIcon
        message="Si è verificato un errore durante il caricamento del documento di notifica."
        description={getNetworkErrorMessage(error, 'Errore nel download.')}
      />
    );
  }

  const isSigned = formik.values.filename != null;

  return (
    <div>
      {isSigned ? (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            type="success"
            showIcon
            message="Il file è stato firmato correttamente"
          />
          <div>
            <a
              download={formik.values.filename}
              href={`data:application/octet-stream;base64,${formik.values.content}`}
            >
              <Button block icon={<DownloadOutlined />} type="primary" ghost>
                Download file firmato: <SafetyOutlined />{' '}
                {formik.values.filename}
              </Button>
            </a>
            <VerticalSpace size={5} />
            <Button
              onClick={handleUnlink}
              type="link"
              danger
              icon={<DeleteOutlined />}
            >
              Rimuovi file firmato
            </Button>
          </div>
        </Space>
      ) : (
        <Space direction="vertical" style={{ width: '100%' }}>
          {/* TODO Componente dedicato */}
          <div>
            <a
              download={documentFile.filename}
              href={`data:application/pdf;base64,${documentFile.content}`}
            >
              <Button block icon={<DownloadOutlined />}>
                Download anteprima: <FilePdfOutlined /> {documentFile.filename}
              </Button>
            </a>
          </div>
          <VerticalSpace size={5} />
          <div>
            <Checkbox
              // style={{ paddingBottom: '8px' }}
              onChange={e => setGraphicSignature(e.target.checked)}
              checked={graphicSignature}
              children="Apponi la firma grafica sul documento"
            />
          </div>
          {/* <Space> */}
          <Row gutter={8}>
            <Col span={12}>
              <Button onClick={handleSetup} block icon={<SettingOutlined />}>
                Configura Firma Digitale
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                onClick={handleSign}
                icon={<SafetyOutlined />}
                block
              >
                Firma Documento
              </Button>
            </Col>
          </Row>

          {/* </Space> */}
          {demoSignatureEnabled && (
            <Button
              type="primary"
              onClick={handleFakeSign}
              icon={<SafetyOutlined />}
              block
            >
              Firma Documento (Demo)
            </Button>
          )}
        </Space>
      )}
    </div>
  );
}
