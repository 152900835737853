import { TagProps } from 'antd';
import { assertNever } from 'server/utils/typings/assertNever';
import { FlowType } from '../../../../../common/schema/flow/FlowType';

/**
 * Ottiene il colore per i Tag in base al tipo di flusso
 */
export const getFlowTypeColor = (type: FlowType): TagProps['color'] => {
  switch (type) {
    case FlowType.Documenti:
      return 'cyan';
    case FlowType.ConsensiInformati:
      return 'purple';
    case FlowType.Regolamenti:
      return 'pink';
  }
  assertNever(type);
};
