function getSocketUrl() {
  switch (location.protocol) {
    case 'https:':
      return `wss://localhost:8883/signer`;
    default:
      return `ws://localhost:8882/signer`;
  }
}

type Deferred = {
  resolve: (response: any) => void;
  reject: (reason: any) => void;
};

export class DigitalSignatureProvider {
  private static instance = new DigitalSignatureProvider();

  /**
   * Inizializzazione
   */
  static async open() {
    await this.instance.connectIfNecessary();
    return this.instance;
  }

  /**
   * Socket di connessione WS
   */
  private socket: WebSocket | undefined;

  /**
   * Coda di richieste inviate per essere gestite in sequenza.
   */
  private queue: Deferred[] = [];

  constructor() {}

  async connectIfNecessary() {
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.socket = await this.connect();
      this.socket.addEventListener('message', this.handleMessage);
      this.socket.addEventListener('error', this.handleError);
      this.socket.addEventListener('close', this.handleClose);
    }
  }

  private connect() {
    return new Promise<WebSocket>((resolve, reject) => {
      let socket = new WebSocket(getSocketUrl());
      console.log('[WS] Connetto...');

      socket.onopen = (event: Event) => {
        console.log('[WS] Connesso', event);
        resolve(socket);
      };
      socket.onerror = (event: Event) => {
        console.error('[WS] Errore', event);
        reject("Impossibile connettersi all'applicativo desktop di Firma.");
      };
    });
  }

  handleMessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    console.log('[WS] Ricevuta risposta', data);
    switch (data.result) {
      case 'ok':
      case 'file':
        return this.queue.shift()!.resolve(data);
      case 'ko':
      default:
        return this.queue
          .shift()!
          .reject(
            data.error
              ? this.remapErrorMessage(data.error)
              : `Si è verificato un errore nell'elaborazione della risposta.`
          );
    }
  };

  private remapErrorMessage(message: string) {
    if (message.includes('PKCS11 not found'))
      return `Non è stata trovata la Smart Card / Token USB per la firma digitale.`;
    if (message.includes('Unable to instantiate PKCS11'))
      return `Non è stato possibile attivare la Smart Card / Token USB. Assicurarsi di aver configurato correttamente la firma digitale.`;
    return message;
  }

  handleError = (event: Event) => {
    this.queue.shift()?.reject(event);
  };

  handleClose = (event: Event) => {
    console.warn(`[WS] chiusura socket.. riconnetto`, event);
    this.socket?.removeEventListener('message', this.handleMessage);
    this.socket?.removeEventListener('close', this.handleClose);
    this.socket?.removeEventListener('error', this.handleError);

    setTimeout(() => {
      this.connectIfNecessary();
      console.log('[WS] riconnesso');
    }, 1_000);
  };

  sendRequest(message: any): Promise<any> {
    if (this.queue.length !== 0) {
      throw new Error('Si è verificato un errore nella gestione della firma.');
    }

    if (!this.socket) {
      throw new Error(
        `Non è stato possibile connettersi all'applicativo desktop di Firma.`
      );
    }

    return new Promise((resolve, reject) => {
      this.socket!.send(JSON.stringify(message));
      this.queue.push({ resolve, reject });
    });
  }

  /**
   * Setup
   */
  async requestSetup() {
    return await this.sendRequest({
      action: 'setup'
    });
  }

  /**
   * Richiede la firma PAdES tramite websocket.
   *
   * @param fileContent Contenuto del file codificato in base64
   */
  async requestPadesSignature(
    fileContent: string,
    fileName: string,
    options: { graphicSignature: boolean }
  ) {
    const message = {
      action: 'sign_pades',
      fileContent: [fileContent],
      fileName: [fileName],
      pageNum: 1,
      x: options.graphicSignature ? 50 : 0,
      y: options.graphicSignature ? 4 : 0
    };
    console.log('req pades..', fileName, message);
    const response = await this.sendRequest(message);
    console.log('response', response);
    return {
      fileContent: response.fileContent[0],
      fileName: response.fileName[0]
    };
  }
}
