export enum EventLogType {
  /** Crazione */
  Created = 'created',
  /** Eliminazione (solo da bozza) */
  Deleted = 'deleted',
  /** Avanzamento allo stato successivo */
  Advancement = 'advancement',
  /** Richiesta di revisione */
  RevisionRequest = 'revisionRequest',
  /** Approvazione */
  Approved = 'approved',
  /** Rifiuto */
  Rejected = 'rejected',
  /** Pubblicazione */
  Published = 'published'
}
