import { yup } from 'common/validation/initYup';
import { ValidationSchema } from 'common/validation/ValidationSchema';
import { Type, Transform, plainToClass, classToPlain } from 'class-transformer';
import { TypeTransformDecimal } from 'common/dto/shared/types/TypeTransformDecimal';
import { Decimal } from 'decimal.js-light';
import { ApiProperty } from '@nestjs/swagger';
import type { IDto, IDtoPartial } from 'common/dto/shared/DtoInterfaces';
import { GenerateDto } from "cli/generate/decorators/GenerateDecorators";
import { SchemaLabel, SchemaValidate } from "cli/generate/decorators/SchemaDecorators";
import { StringSchema } from 'yup';



/**
 * Body per la modifica dell'email dell'utente.
 */
@GenerateDto()
class UpdateUserEmailBody {

    @SchemaLabel('Email utente')
    @SchemaValidate((schema: StringSchema) => schema.email())
    email!: string;
}

/**
 * Rappresentazione DTO della classe UpdateUserEmailBody 
 * Hash: 01f6bd5c9c879c46c5072b9d14b2c7f1
 */
@ValidationSchema(() => UpdateUserEmailBodySchema)
export class UpdateUserEmailBodyDto {
  @ApiProperty({ type: String, description: 'Email utente' })
  email!: string;

  /** Proprietà che identifica i DTO */
  readonly __dto!: any;

  /**
   * Crea una nuova istanza con i valori forniti
   */
  constructor(values?: IDtoPartial<UpdateUserEmailBodyDto>) {
    if (values != null) {
      Object.assign(this, values instanceof UpdateUserEmailBodyDto ? values : plainToClass(UpdateUserEmailBodyDto, values));
    }
  }

  async validate(options?: any) {
    const validated = await UpdateUserEmailBodySchema.validate(classToPlain(this), options);
    return new UpdateUserEmailBodyDto(validated);
  }
}

export const UpdateUserEmailBodySchema = yup
  .object({
    email: yup.string().required().email().label('Email utente')
  })
  .noUnknown()
  .meta({ schemaName: "UpdateUserEmailBodySchema" })
  .required();
