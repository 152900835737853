import { SaveOutlined } from '@ant-design/icons';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FlowDto } from 'common/dto/generated/FlowDto';
import { FlowMachine } from 'common/schema/flow/FlowMachine';
import { useFormikContext } from 'formik';
import React from 'react';

export interface IFlowSaveButtonProps {}

export function FlowSaveButton(props: IFlowSaveButtonProps) {
  const {} = props;

  const user = useCurrentUser()!;
  const formik = useFormikContext<FlowDto>();
  const canEdit = FlowMachine.can(formik.values, user, 'edit').allowed;

  if (!canEdit) return null;
  if (!formik.values.documentType) return null;

  return (
    <FormikSendButton size="large" type="primary" ghost disableIfDirty>
      Salva Documento <SaveOutlined />
    </FormikSendButton>
  );
}
