import { yup } from 'common/validation/initYup';
import { yupToFormErrors } from 'formik';
import { ObjectShape } from 'yup/lib/object';
import { ValidateOptions } from 'yup/lib/types';

/**
 * Crea un validatore di uno schema per i form che non abbia solamente
 * `abortEarly`.
 */
export function schemaToValidator<T extends ObjectShape>(
  schema: yup.ObjectSchema<T>,
  options?: ValidateOptions
) {
  return async (values: any) => {
    try {
      const res = await schema.validate(values, {
        ...options,
        abortEarly: false
      });
      return;
    } catch (err: any) {
      console.error('Validation error', err.errors, err.inner, err, values);
      return yupToFormErrors(err);
    }
  };
}
